import React from 'react';
import {Card, Col, Table} from "react-bootstrap";

export default function UserOrderCard(props)
{
    let date = new Date(props.pick.state.timestamp_placed);
    let datePlaced = ((date.getUTCMonth() > 8) ? (date.getUTCMonth() + 1) : ('0' + (date.getUTCMonth() + 1))) + '/' + ((date.getUTCDate() > 9) ? date.getUTCDate() : ('0' + date.getUTCDate())) + '/' + date.getUTCFullYear();

    function picksForDelivery(picks)
    {
        let buffer = [];

        for (const item of Object.keys(picks))
        {
            let num = picks[item];

            if (num > 0)
            {
                buffer.push(
                    <tr key={item}>
                        <td>{item}</td>
                        <td>{num}</td>
                    </tr>
                );
            }
        }

        if (buffer.length === 0)
        {
            return null;
        }
        return (
            <>
                <Table size={"sm"} className={props.dark ? 'text-white': 'text-dark'}>
                    <tbody>
                    {buffer}
                    </tbody>
                </Table>
            </>);
    }

    if (props.giftCards)
    {
        return (
            <Col className="text-dark">
                <Card bg={props.dark ? 'dark' : ''} text={props.dark ? 'white': 'dark'} className="mt-2 mb-2">
                    <Card.Body>
                        <h4 className="text-primary">picked on {datePlaced}</h4>
                        <h5 className="mt-3">gift cards</h5>
                        {picksForDelivery(props.pick.picks)}
                    </Card.Body>
                </Card>
            </Col>
        );
    }
    else {
        return (
            <Col className="text-dark">
                <Card bg={props.dark ? 'dark' : ''} text={props.dark ? 'white' : 'dark'} className="mt-2 mb-2">
                    <Card.Body>
                        <h4 className="text-primary">picked on {datePlaced}</h4>
                        <h5 className="mt-3">first delivery</h5>
                        {picksForDelivery(props.pick.picksFirst)}
                        <h5 className='mt-3'>second delivery</h5>
                        {picksForDelivery(props.pick.picksSecond)}
                    </Card.Body>
                </Card>
            </Col>
        );
    }
}