import React, {Component} from "react";
import {Alert, Button, Modal, Spinner, Form, Col, InputGroup, ListGroup, Row} from "react-bootstrap";

export default class AdminPageMenuSetMealPlanAmount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notifShow: false,
            submitClicked: false,
            mealPlan150Cost: props.mealPlan150Cost || 0,
            mealPlan175Cost: props.mealPlan175Cost || 0,
            salesTax: props.salesTax || 0,
            responseJSON: null,
            currentPricesJSON: null,
            updater: 0
        };
    }

    updateCurrentPricesJSON(){
        let url = encodeURI('/api/getMealPlanCosts')

        fetch(url).then(res => res.json()).then(data => {this.setState({
            currentPricesJSON: data
        })})
    }

    componentDidMount() {
        this.updateCurrentPricesJSON();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.updater !== prevState.updater){
            this.updateCurrentPricesJSON();
        }
    }

    get submitButton(){
        if (this.state.submitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.onSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Submit
                </Button>
            );
        }
    }

    get notification(){
        if (this.state.notifShow){
            if (this.state.responseJSON.success){
                return (
                    <Alert variant={'success'}>
                        Success! The prices have been successfully adjusted.
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.responseJSON.error}
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }

    onChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitClicked: true
        });

        let updater = this.state.updater;
        ++updater;

        let post_body = {
            mealPlan150Cost: parseInt(this.state.mealPlan150Cost),
            mealPlan175Cost: parseInt(this.state.mealPlan175Cost),
            salesTax: parseInt(this.state.salesTax)
        };
        let url = '/api/admin/menu/mealPlanCosts/set';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then((json) => {this.setState({
            submitClicked: false,
            responseJSON: json,
            notifShow: true,
            updater: updater
        })});

        setTimeout(() => {
            this.setState({
                notifShow: false
            });
        }, 2000);
    };

    get currentPricesListGroup(){
        if (this.state.currentPricesJSON == null ){
           return (
               <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                   <Spinner
                       as="span"
                       animation="border"
                       size="lg"
                       role="status"
                       aria-hidden="true"
                   />
                   <span className="sr-only">Loading...</span>
               </div>
           );
        }

        if (!this.state.currentPricesJSON.success){
            return null;
        }

        let mealPlan150Cost = this.state.currentPricesJSON.meal_plan_150_cost;
        let mealPlan175Cost = this.state.currentPricesJSON.meal_plan_175_cost;
        let salesTax = this.state.currentPricesJSON.sales_tax_percentage / 100;

        return (
            <>
                <h5 className={'mt-3'}>Current Prices</h5>
                <ListGroup flush>
                    <ListGroup.Item><Row>
                        <Col>
                            Meal Plan 150 Price
                        </Col>
                        <Col>${mealPlan150Cost.toFixed(2)}</Col>
                        <Col>${(mealPlan150Cost + (mealPlan150Cost * salesTax)).toFixed(2)} w/ tax</Col>
                    </Row></ListGroup.Item>
                    <ListGroup.Item><Row>
                        <Col>
                            Meal Plan 175 Price
                        </Col>
                        <Col>${mealPlan175Cost.toFixed(2)}</Col>
                        <Col>${(mealPlan175Cost + (mealPlan175Cost * salesTax)).toFixed(2)} w/ tax</Col>
                    </Row></ListGroup.Item>
                    <ListGroup.Item><Row>
                        <Col>
                            Sales Tax
                        </Col>
                        <Col>{salesTax * 100}%</Col>
                    </Row></ListGroup.Item>
                </ListGroup>
            </>
        );

    }


    render(){
        return(
            <Modal.Body>
                {this.notification}
                This command will set the global meal plan costs for everything. Please input the price before taxes, and then
                insert the current sales tax percentage that you would like to charge on top of that. Invoices will go out
                to the nearest hundredth of a cent.
                {this.currentPricesListGroup}
                <h5 className={'mt-3'}>Set New Prices</h5>
                <Form className={'mt-4'}>
                    <Form.Row><Form.Group as={Col}>
                        <Form.Label>150 Meal Plan Cost</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>
                                $
                            </InputGroup.Text></InputGroup.Prepend>
                            <Form.Control required
                                          name={'mealPlan150Cost'}
                                          value={this.state.mealPlan150Cost}
                                          placeholder={'Enter meal plan cost'}
                                          type={'number'}
                                          min={0}
                                          onChange={this.onChange}
                            />
                        </InputGroup>
                    </Form.Group></Form.Row>
                    <Form.Row><Form.Group as={Col}>
                        <Form.Label>175 Meal Plan Cost</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend><InputGroup.Text>
                                $
                            </InputGroup.Text></InputGroup.Prepend>
                            <Form.Control required
                                          name={'mealPlan175Cost'}
                                          value={this.state.mealPlan175Cost}
                                          placeholder={'Enter meal plan cost'}
                                          type={'number'}
                                          min={0}
                                          onChange={this.onChange}
                            />
                        </InputGroup>
                    </Form.Group></Form.Row>
                    <Form.Row><Form.Group as={Col}>
                        <Form.Label>Sales Tax %</Form.Label>
                        <InputGroup>
                            <Form.Control required
                                          name={'salesTax'}
                                          value={this.state.salesTax}
                                          placeholder={'Enter sales tax as %'}
                                          type={'number'}
                                          min={0}
                                          onChange={this.onChange}
                            />
                            <InputGroup.Append><InputGroup.Text>
                                %
                            </InputGroup.Text></InputGroup.Append>
                        </InputGroup>
                    </Form.Group></Form.Row>
                    {this.submitButton}
                </Form>
            </Modal.Body>
        );
    }


}