import React from "react";
import "./Account.css";
import UserPage from "../components/UserPage/UserPage";


export default function Login() {
    return (
        <div className="Account">
            <div className="container">
                <h5 className={"bk_header"}> my account </h5>
                <UserPage />
            </div>
        </div>
    );
}