import React, {Component} from "react";
import {
    Card,
    Row,
    Col,
    Spinner,
    Button,
    ListGroup,
    Form,
    Modal,
    ButtonGroup,
    DropdownItem,
    DropdownButton, InputGroup, Alert, Badge
} from "react-bootstrap";
import {
    BsFiles,
    BsFillTrashFill,
    BsFillGearFill,
    BsFillCalendarFill,
    BsArrowLeftRight, BsPlus, BsFillUnlockFill, BsFillLockFill, BsFillEnvelopeFill, BsCommand
} from "react-icons/all";
import AdminPageMenuSetMealPlanAmount from "./AdminPageMenuModals/AdminPageMenuSetMealPlanAmount";

export default class AdminPageMenu extends Component{
    constructor(props) {
        super(props);

        this.state = {
            adminMenuJSON: null,
            changeTracker: 0,
            selectedQuarter: 'q1',
            selectedYear: 0,
            //modal vars
            selectedWeek: null,
            menuEditModalShow: false,

            addItemEveryWeekModalShow: false,
            addItemEveryWeekSubmitClicked: false,
            snacksModalShow: false,
            addSnackSubmitClicked: false,
            itemDescription: '',
            itemPrice: 0,

            // addCard vars
            selectedAddType: null,
            itemName: '',
            ingredients: [],
            ingredientCache: '',
            addCardSubmitClicked: false,
            addCardNotifShow: false,
            // addCard Shows
            addProteinCardShow: false,
            // delete thingy
            selectedDeleteItem: '',
            // add year stuff
            addNewYearModalShow: false,
            addNewYearFormValid: null,
            addNewYearSubmitClicked: false,
            addNewYearNotifShow: false,
            addNewYearResponseJSON: null,
            addYearName: '',
            q1StartDate: null,
            q2StartDate: null,
            q3StartDate: null,
            q1EndDate: null,
            q2EndDate: null,
            q3EndDate: null,

            q1StartValid: null,
            q2StartValid: null,
            q3StartValid: null,
            q1EndValid: null,
            q2EndValid: null,
            q3EndValid: null,

            sendEmailModalShow: false,
            sendEmailSubmitClicked: false,

            setMealPlanCashModalShow: false,
            setMealPlanCashSubmitClicked: false,
            amount: 0,

            setMealPlanCostModalShow: false
        };

    }

    //*** API Calls
    updateAdminMenuJSON(){
        // Load admin information
        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI('api/admin/menu?account_hash=' + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            adminMenuJSON: data
        })});
    }
    componentDidMount(){
        this.updateAdminMenuJSON();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentTab !== 4 && this.props.currentTab === 4){
            this.updateAdminMenuJSON();
        }
        if (prevState.changeTracker !== this.state.changeTracker){
            this.updateAdminMenuJSON();
        }
    }

    get addSnackSubmitButton(){
        if (this.state.addSnackSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.addSnackSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Add Item
                </Button>
            );
        }
    }

    addSnackSubmit = (event) => {
        event.preventDefault();
        this.setState({
            itemName: '',
            addSnackSubmitClicked: true
        });

        let changeTracker = this.state.changeTracker;
        ++changeTracker;

        let post_body = {
            year: this.state.adminMenuJSON.years_info[this.state.selectedYear].year,
            quarter: this.state.selectedQuarter,
            itemName: this.state.itemName,
            itemDescription: this.state.itemDescription,
            itemPrice: this.state.itemPrice
        };
        let url = '/api/admin/menu/snacks/add';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(() => {this.setState({
            changeTracker: changeTracker,
            addSnackSubmitClicked: false
        })});
    };

    deleteSnackSubmit(event, name){
        event.preventDefault();

        let changeTracker = this.state.changeTracker;
        ++changeTracker;

        let post_body = {
            year: this.state.adminMenuJSON.years_info[this.state.selectedYear].year,
            quarter: this.state.selectedQuarter,
            name: name
        };
        let url = '/api/admin/menu/snacks/delete';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(() => {this.setState({
            changeTracker: changeTracker
        })});
    }

    get snacksModal(){

        if (this.state.adminMenuJSON == null){
            return null;
        }

        let snacksInfo = this.state.adminMenuJSON.years_info[this.state.selectedYear].quarters[this.state.selectedQuarter].snacks;
        let listGroupBuffer = [];
        if (snacksInfo != null){
            for (const item of snacksInfo){
                listGroupBuffer.push(
                    <ListGroup.Item key={item.name}>
                        <Row>
                            <Col md={3}>{item.name}: ${item.price}</Col>
                            <Col>{item.description}</Col>
                            <Col md={1}>
                                <Button variant={'danger'} onClick={(event) => {this.deleteSnackSubmit(event, item.name)}}>
                                    <BsFillTrashFill/>
                                </Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>

                );
            }
        }

        let listGroup = (
            <ListGroup className={'mb-3'}>
                {listGroupBuffer}
            </ListGroup>
        );

        return (
            <Modal size={'lg'} show={this.state.snacksModalShow} onHide={() => {this.setState({snacksModalShow: false})}}>
                <Modal.Header closeButton><strong>Fun Cash Item Management</strong></Modal.Header>
                <Modal.Body>
                    <Modal.Title>
                        Items
                    </Modal.Title>
                    {listGroup}
                    <Modal.Title>
                        Add Item
                    </Modal.Title>
                    <Form>
                        <Form.Row><Form.Group as={Col}>
                            <Form.Label>Item Name</Form.Label>
                            <Form.Control required type="text" placeholder="Enter item name"
                                          name="itemName"
                                          onChange={this.handleChange}/>
                        </Form.Group></Form.Row>
                        <Form.Row><Form.Group as={Col}>
                            <Form.Label>Item Description</Form.Label>
                            <Form.Control required as={"textarea"} rows={2} placeholder="Enter item description"
                                          name="itemDescription"
                                          onChange={this.handleChange}/>
                        </Form.Group></Form.Row>
                        <Form.Row><Form.Group as={Col}>
                           <Form.Label>Item Price</Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend><InputGroup.Text id="basic-addon1">$</InputGroup.Text></InputGroup.Prepend>
                                <Form.Control required type="number" placeholder="Enter item price"
                                              name="itemPrice"
                                              onChange={this.handleChange}/>
                           </InputGroup>
                        </Form.Group></Form.Row>
                        {this.addSnackSubmitButton}
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    showMenuEditModal(week){
        this.setState({
            selectedWeek: week,
            menuEditModalShow: true
        });
    }
    showAddCard(cardName, cardType){
        this.setState({
            [cardName]: true,
            ingredients: [],
            selectedAddType: cardType
        });
    }

    //*** Overview Cards @ top
    get currentWeekCard(){

        let date = this.state.adminMenuJSON.current_date;
        let index = date.indexOf(':');
        let shortened_date = date.substring(0, index - 2);

        return (
            <Card className={'mt-2 mb-2'}>
                <Card.Header>Current Week</Card.Header>
                <Card.Body>
                    Current Date: {shortened_date}
                </Card.Body>
            </Card>
        );
    }
    get nextWeekCard(){
        return (
            <Card className={'mt-2 mb-2'}>
                <Card.Header>Next Week</Card.Header>
            </Card>
        );
    }
    get reviewsCard(){
        return (
            <Card className={'mt-2 mb-2'}>
                <Card.Header>Reviews</Card.Header>
            </Card>
        );
    }

    addIngredientCacheToList(){
        let currIngredients = this.state.ingredients;
        currIngredients.push(this.state.ingredientCache);
        this.setState({
            ingredients: currIngredients
        });
    }

    get addCard(){
        if (!this.state.addProteinCardShow){
            return null;
        }

        let ingredientsString = '';
        for (const item of this.state.ingredients){
            ingredientsString += (item + ', ');
        }

        return (
            <Card className={'mt-3 mb-3'}>
                <Card.Body>
                    <Card.Title>
                        <h5><BsPlus/> Add Item</h5>
                    </Card.Title>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Item Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter item name"
                                              name="itemName"
                                              onChange={this.handleChange}/>
                            </Form.Group>
                            <Form.Group as={Col} className={'d-inline-row'}>
                                <Form.Label>Ingredients</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={'text'}
                                        name={'ingredientCache'}
                                        onChange={this.handleChange}
                                        placeholder={'Enter ingredients'}
                                        aria-label="Ingredients"
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary"
                                                onClick={() => this.addIngredientCacheToList()}><BsPlus/></Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Form.Label>Item Type</Form.Label>
                                <Form.Control
                                    required
                                    as="select"
                                    name="selectedAddType"
                                    defaultValue={'protein'}
                                    onChange={this.handleChange}
                                    custom>
                                    <option value={'protein'}>Protein</option>
                                    <option value={'carbs'}>Carbs</option>
                                    <option value={'veggies'}>Veggies</option>
                                    <option value={'breakfasts'}>Breakfast</option>
                                    <option value={'assembled_meals'}>Assembled Meal</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <ListGroup>
                            <ListGroup.Item>
                                <Row>
                                    <Col md={3}>{this.state.itemName}: </Col>
                                    <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                                    <Col md={1}>
                                        <Button variant={'danger'}
                                                onClick={() => this.setState({
                                                    ingredients: [],
                                                    itemName: ''
                                                })}>
                                            <BsFillTrashFill/>
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    </Form>
                    <Button variant={'secondary'}
                            onClick={() => this.setState({
                                addProteinCardShow: false,
                                itemName: '',
                                ingredients: []
                            })}
                            className={'float-left mt-2 mb-2'}>
                        Close
                    </Button>
                    {this.addItemSubmitButton}
                </Card.Body>
            </Card>
        );
    }

    get addItemSubmitButton(){
        if (this.state.addCardSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.addCardSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Add Item
                </Button>
            );
        }
    }

    addCardSubmit = (event) => {
        event.preventDefault();
        this.setState({
            addCardSubmitClicked: true,
            ingredients: [],
            itemName: ''
        });

        let changeTracker = this.state.changeTracker;
        ++changeTracker;

        let post_body = {
            year: this.state.adminMenuJSON.years_info[this.state.selectedYear].year,
            quarter: this.state.selectedQuarter,
            week: this.state.selectedWeek,
            type: this.state.selectedAddType,
            itemName: this.state.itemName,
            ingredients: this.state.ingredients,
        };
        let url = '/api/admin/menu/week/add';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(() => {this.setState({
            addCardNotifShow: true,
            addCardSubmitClicked: false,
            changeTracker: changeTracker
        })});

        setTimeout(() => {
            this.setState({
                addCardNotifShow: false,
            })
        }, 3000);
    };
    deleteItemSubmit(event, name, type){
        event.preventDefault();

        let changeTracker = this.state.changeTracker;
        ++changeTracker;

        let post_body = {
            year: this.state.adminMenuJSON.years_info[this.state.selectedYear].year,
            quarter: this.state.selectedQuarter,
            week: this.state.selectedWeek,
            type: type,
            name: name
        };
        let url = '/api/admin/menu/week/delete';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(() => {this.setState({
            changeTracker: changeTracker
        })});
    }

    getCategoryListGroups(){
        let week_info = this.state.adminMenuJSON.years_info[this.state.selectedYear].quarters[this.state.selectedQuarter].weeks[this.state.selectedWeek];
        let listGroups = [];

        let categories = ['protein', 'carbs', 'veggies', 'breakfasts', 'assembled_meals'];

        for (const category of categories){
            let categoryBuffer = [];
            for (const item of week_info[category]){

                let ingredientsString = '';
                for (const ingredient of item.ingredients){
                    ingredientsString += ingredient + ', ';
                }

                categoryBuffer.push(
                    <ListGroup.Item key={item.name}>
                        <Row>
                            <Col md={3}>{item.name}: </Col>
                            <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                            <Col md={1}>
                                <Button variant={'danger'} onClick={(event) => {this.deleteItemSubmit(event, item.name, category)}}>
                                    <BsFillTrashFill/>
                                </Button>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                );
            }
            let buffer = null;
            if (categoryBuffer.length > 0){
                buffer = (<ListGroup>{categoryBuffer}</ListGroup>);
            }
            else {
                buffer = (
                    <ListGroup>
                        <ListGroup.Item>No items available.</ListGroup.Item>
                    </ListGroup>);
            }
            listGroups.push(buffer);
        }

        return listGroups;
    }

    //*** MenuEdit Modal
    get menuEditModal(){
        if (this.state.adminMenuJSON.years_info.length === 0){
            return null;
        }
        let quarter = '';
        let selectedQuarter = this.state.selectedQuarter;

        switch(selectedQuarter){
            case "q1":
                quarter = 'Fall Quarter';
                break;
            case "q2":
                quarter = 'Winter Quarter';
                break;
            case "q3":
                quarter = 'Spring Quarter';
                break;
            default:
                quarter = 'ERROR';
                break;
        }

        let week_info = this.state.adminMenuJSON.years_info[this.state.selectedYear].quarters[this.state.selectedQuarter].weeks[this.state.selectedWeek];

        if (week_info == null){
            return null;
        }

        let listGroups = this.getCategoryListGroups();

        return (
            <Modal size={'lg'} show={this.state.menuEditModalShow} onHide={() => this.setState({menuEditModalShow: false})}>
                <Modal.Header closeButton><strong>Edit Menu</strong></Modal.Header>
                <Modal.Body>
                    <Row className={'mt-3 mb-3'}>
                        <Col sm><h3>{quarter}</h3> Selected Quarter</Col>
                        <Col sm><h3>{week_info.week}</h3> Selected Week</Col>
                    </Row>
                    <Button variant={'primary'}
                            className={'mt-2 mb-2'}
                            onClick={() => this.showAddCard('addProteinCardShow', 'protein')}><BsPlus/> Create item</Button>
                    {this.addCard}
                    <div className={'mt-3'}>
                        <div className={'text-primary'}><h4>{week_info.protein.length} Protein Items</h4></div>
                        {listGroups[0]}
                    </div>
                    <div className={'mt-3'}>
                        <div className={'text-warning'}><h4>{week_info.carbs.length} Carbs Items</h4></div>
                        {listGroups[1]}
                    </div>
                    <div className={'mt-3'}>
                        <div className={'text-success'}><h4>{week_info.veggies.length} Veggie Items</h4></div>
                        {listGroups[2]}
                    </div>
                    <div className={'mt-3'}>
                        <div className={'text-danger'}><h4>{week_info.breakfasts.length} Breakfast Items</h4></div>
                        {listGroups[3]}
                    </div>
                    <div className={'mt-3 mb-5'}>
                        <div className={'text-secondary'}><h4>{week_info.assembled_meals.length} Assembled Meals</h4></div>
                        {listGroups[4]}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

    //*** addNewYear Modal
    get addNewYearModal(){
        return (
            <Modal show={this.state.addNewYearModalShow} onHide={() => this.setState({addNewYearModalShow: false})}>
                <Modal.Header closeButton><strong><BsFillCalendarFill/> Add New Year</strong></Modal.Header>
                <Modal.Body>
                    {this.addNewYearNotification}
                    You can create a new year using this tool. Enter all of the start and end dates for each quarter and then title the year.
                    <Form className={'mt-3'}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Year Title</Form.Label>
                                <Form.Control required type={'text'}
                                              name={'addYearName'}
                                              onChange={this.handleChange}
                                              placeholder={'Enter year name...'}/>
                                <Form.Text className="text-muted">
                                    Please title the year something like '2020-2021'.
                                </Form.Text>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label>Q1 Service Dates</Form.Label>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control required type={'text'} placeholder={'MM/DD/YYYY'}
                                              name={'q1StartDate'}
                                              onChange={(event) => this.handleChangeDate(event, 'q1StartValid')}
                                              className={this.handleIsValid('q1StartValid')}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control required type={'text'} placeholder={'MM/DD/YYYY'}
                                              name={'q1EndDate'}
                                              onChange={(event) => this.handleChangeDate(event, 'q1EndValid')}
                                              className={this.handleIsValid('q1EndValid')}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label>Q2 Service Dates</Form.Label>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control required type={'text'} placeholder={'MM/DD/YYYY'}
                                              name={'q2StartDate'}
                                              onChange={(event) => this.handleChangeDate(event, 'q2StartValid')}
                                              className={this.handleIsValid('q2StartValid')}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control required type={'text'} placeholder={'MM/DD/YYYY'}
                                              name={'q2EndDate'}
                                              onChange={(event) => this.handleChangeDate(event, 'q2EndValid')}
                                              className={this.handleIsValid('q2EndValid')}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label>Q3 Service Dates</Form.Label>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control required type={'text'} placeholder={'MM/DD/YYYY'}
                                              name={'q3StartDate'}
                                              onChange={(event) => this.handleChangeDate(event, 'q3StartValid')}
                                              className={this.handleIsValid('q3StartValid')}/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>End Date</Form.Label>
                                <Form.Control required type={'text'} placeholder={'MM/DD/YYYY'}
                                              name={'q3EndDate'}
                                              onChange={(event) => this.handleChangeDate(event, 'q3EndValid')}
                                              className={this.handleIsValid('q3EndValid')}/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row><Form.Text className="text-muted">
                            Dates must be 0 padded (MM/DD/YYYY).
                        </Form.Text></Form.Row>
                        {this.addNewYearSubmitButton}
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
    get addNewYearSubmitButton(){
        if (this.state.addNewYearSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.addNewYearSubmit} className="btn btn-primary float-right mt-2 mb-2" type="submit">
                    Create Year
                </Button>
            );
        }
    }
    get addNewYearNotification(){
        if (this.state.addNewYearNotifShow){
            if (this.state.addNewYearResponseJSON.success){
                return (
                    <Alert variant={'success'}>
                        The new year has been successfully created!
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.addNewYearResponseJSON.error}
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }

    validateAddNewYear(){
        let q1StartValid = this.state.q1StartValid == null ? false : this.state.q1StartValid;
        let q2StartValid = this.state.q2StartValid == null ? false : this.state.q2StartValid;
        let q3StartValid = this.state.q3StartValid == null ? false : this.state.q3StartValid;
        let q1EndValid = this.state.q1EndValid == null ? false : this.state.q1EndValid;
        let q2EndValid = this.state.q2EndValid == null ? false : this.state.q2EndValid;
        let q3EndValid = this.state.q3EndValid == null ? false : this.state.q3EndValid;

        let formValid = q1StartValid && q2StartValid && q3StartValid && q1EndValid && q2EndValid && q3EndValid;

        this.setState({
            addNewYearFormValid: formValid,
            q1StartValid: q1StartValid,
            q2StartValid: q2StartValid,
            q3StartValid: q3StartValid,
            q1EndValid: q1EndValid,
            q2EndValid: q2EndValid,
            q3EndValid: q3EndValid
        });
    }

    addNewYearSubmit = (event) => {
        event.preventDefault();
        this.validateAddNewYear();
        this.setState({
            addNewYearSubmitClicked: true
        });

        setTimeout(() => {
            if (this.state.addNewYearFormValid){

                let changeTracker = this.state.changeTracker;
                ++changeTracker;

                let post_body = {
                    yearName: this.state.addYearName,
                    q1StartDate: this.state.q1StartDate,
                    q2StartDate: this.state.q2StartDate,
                    q3StartDate: this.state.q3StartDate,
                    q1EndDate: this.state.q1EndDate,
                    q2EndDate: this.state.q2EndDate,
                    q3EndDate: this.state.q3EndDate,
                };
                let url = '/api/admin/menu/year/create';

                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'account-hash': localStorage.getItem('hashed_login_key'),
                        'user': localStorage.getItem('user')
                    },
                    body: JSON.stringify(post_body)
                }).then(res => res.json()).then(data => {this.setState({
                    addNewYearResponseJSON: data,
                    addNewYearNotifShow: true,
                    addNewYearSubmitClicked: false,
                    changeTracker: changeTracker
                })});

                setTimeout(() => {
                    this.setState({
                        addNewYearNotifShow: false,
                        addNewYearModalShow: false
                    })
                }, 3000);
            }
            else {
                this.setState({
                    addNewYearSubmitClicked: false,
                })
            }
        }, 1000);
    };

    handleIsValid(fieldName){
        return this.state[fieldName] != null ? (this.state[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }
    handleChangeDate(event, validName){
        const {name, value} = event.target;
        let reg = /^[0-9]{2}[\/]{1}[0-9]{2}[\/]{1}[0-9]{4}$/g;
        if (value.length === 0){
            this.setState({
                [validName]: false
            });
        }
        else if (reg.test(value)){
            this.setState({
                [name]: value,
                [validName]: true
            });
        }
        else{
            this.setState({
                [validName]: false
            });
        }
    }

    /**
     * handleChange for all form values
     * @param event
     */
    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    };



    //*** Main Card (quarterly menu)
    get quarterlyMenu(){
        let buffer = [];

        // console.log(this.state.selectedQuarter);
        let selectedQuarter = this.state.adminMenuJSON.years_info[this.state.selectedYear].quarters[this.state.selectedQuarter].weeks;
        // console.log(selectedQuarter);

        for (const week of selectedQuarter){

            let start_date = week.start_date.substring(0, week.start_date.indexOf(':') - 2);
            let end_date = week.end_date.substring(0, week.end_date.indexOf(':') - 2);
            let week_published = week.published != null ? week.published : false;

            let icon = week_published ? (<BsFillUnlockFill/>) : (<BsFillLockFill/>);

            buffer.push(
                <ListGroup.Item key={week.week}>
                    <Card.Title className={'d-inline-row mt-1'}>
                        Week {week.week}
                        <ButtonGroup className={'float-right'}>
                            <Button variant={'outline-secondary'}
                                    onClick={() => this.setState({
                                        sendEmailModalShow: true,
                                        selectedWeek: week.week,
                                    })}
                            >
                                <BsFillEnvelopeFill/>
                            </Button>
                            <Button variant={week_published ? 'outline-success' : 'outline-danger'}
                                    onClick={(event) => this.onPublishSubmit(event, week.week,!week_published)}>
                                {icon}
                            </Button>
                            <Button
                                variant={'outline-primary'}
                                onClick={() => this.showMenuEditModal(week.week)}>
                                <BsFiles/>
                            </Button>
                        </ButtonGroup>

                    </Card.Title>
                    {start_date} - {end_date}<br/><br/>
                    <Row>
                        <Col sm><h3>{week.protein.length}</h3> <div className={'text-primary'}> Protein</div></Col>
                        <Col sm><h3>{week.carbs.length}</h3> <div className={'text-warning'}> Carbs</div></Col>
                        <Col sm><h3>{week.veggies.length}</h3> <div className={'text-success'}> Veggies</div></Col>
                        <Col sm><h3>{week.breakfasts.length}</h3> <div className={'text-danger'}> Breakfasts</div></Col>
                        <Col sm><h3>{week.assembled_meals.length}</h3> <div className={'text-secondary'}> Assembled Meals</div></Col>
                    </Row>
                </ListGroup.Item>
            );
        }

        return (
            <ListGroup variant={'flush'}>
                {buffer}
            </ListGroup>
        );
    }

    onPublishSubmit(event, week, value){
        event.preventDefault();

        let changeTracker = this.state.changeTracker;
        ++changeTracker;

        let post_body = {
            year: this.state.adminMenuJSON.years_info[this.state.selectedYear].year,
            quarter: this.state.selectedQuarter,
            week: week,
            value: value
        };
        let url = '/api/admin/menu/week/publish';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(() => {this.setState({
            changeTracker: changeTracker,
        })});
    }



    get quarterlyMenuCard(){
        if (this.state.adminMenuJSON.years_info.length === 0){
            return (
                <Card className={'mt-2 mb-2'}>
                    <Card.Header className={'d-inline-row'}>
                        Quarterly Menu <Badge variant="danger">deprecated</Badge>
                        <DropdownButton as={ButtonGroup} className={'float-right'} title={(<BsFillGearFill/>)} id="bg-nested-dropdown" variant={'secondary'}>
                            <DropdownItem eventKey="1" onClick={() => this.setState({addNewYearModalShow: true})}><BsFillCalendarFill/> Add New Year</DropdownItem>
                            <DropdownItem eventKey="2"><BsArrowLeftRight/> Change Year</DropdownItem>
                            <DropdownItem eventKey="3"><BsCommand/> Set Meal Plan Cash</DropdownItem>
                        </DropdownButton>
                        <Form.Group as={Col} sm={3} className={'float-right'}>
                            <Form.Control
                                required
                                as="select"
                                name="selectedQuarter"
                                onChange={this.handleChange}
                                defaultValue={'q1'}
                                custom>
                                <option value={'q1'}>Fall Quarter</option>
                                <option value={'q2'}>Winter Quarter</option>
                                <option value={'q3'}>Spring Quarter</option>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">Please select a valid meal plan.</Form.Control.Feedback>
                        </Form.Group>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title>
                            No years found...
                        </Card.Title>
                        <Card.Text>
                            No menus for any years have been found. Please use the settings button above to create a new yearly menu.
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        }

        let title = '';
        switch(this.state.selectedQuarter){
            case "q1":
                title = this.state.adminMenuJSON.years_info[this.state.selectedYear].year + ' Fall Quarter Menu';
                break;
            case "q2":
                title = this.state.adminMenuJSON.years_info[this.state.selectedYear].year + ' Winter Quarter Menu';
                break;
            case "q3":
                title = this.state.adminMenuJSON.years_info[this.state.selectedYear].year + ' Spring Quarter Menu';
                break;
            default:
                title = 'ERROR';
                break;
        }

        let yearBuffer = [];

        let index = 0;

        for (const elem of this.state.adminMenuJSON.years_info)
        {
            yearBuffer.push(
                <option value={index} key={index}>{elem.year}</option>
            );
            ++index;
        }

        return (
            <Card className={'mt-2 mb-2'}>
                <Card.Header className={'d-inline-row'}>
                    Quarterly Menu <Badge variant="danger">deprecated</Badge>
                    <DropdownButton as={ButtonGroup} className={'float-right'} title={(<BsFillGearFill/>)} id="bg-nested-dropdown" variant={'secondary'}>
                        <DropdownItem eventKey="1" onClick={() => this.setState({addNewYearModalShow: true})}><BsFillCalendarFill/> Add New Year</DropdownItem>
                        <DropdownItem eventKey="2"><BsArrowLeftRight/> Change Year</DropdownItem>
                        <DropdownItem eventKey="3" onClick={() => this.setState({setMealPlanCashModalShow: true})}><BsCommand/> Set Meal Plan Cash</DropdownItem>
                        <DropdownItem eventKey="4" onClick={() => this.setState({setMealPlanCostModalShow: true})}><BsCommand/> Set Meal Plan Costs</DropdownItem>
            </DropdownButton>
                    <Form.Group as={Col} sm={3} className={'float-right'}>
                        <Form.Control
                            required
                            as="select"
                            name="selectedQuarter"
                            onChange={this.handleChange}
                            defaultValue={'q1'}
                            custom>
                            <option value={'q1'}>Fall Quarter</option>
                            <option value={'q2'}>Winter Quarter</option>
                            <option value={'q3'}>Spring Quarter</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group as={Col} sm={3} className={'float-right'}>
                        <Form.Control
                            required
                            as="select"
                            name="selectedYear"
                            onChange={this.handleChange}
                            defaultValue={0}
                            custom>
                            {yearBuffer}
                        </Form.Control>
                    </Form.Group>

                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        {title}
                    </Card.Title>
                    <ButtonGroup>
                        <Button variant={'secondary'} className={'mb-3'} onClick={() => {this.setState({addItemEveryWeekModalShow: true})}}>Add Item Every Week</Button>
                        <Button className={'mb-3'} onClick={() => {this.setState({snacksModalShow: true})}}>Snacks/Fun Cash</Button>
                    </ButtonGroup>

                    {this.addItemEveryWeekModal}
                    {this.quarterlyMenu}
                </Card.Body>
            </Card>
        );
    }

    get addItemEveryWeekModal() {
        if (!this.state.addItemEveryWeekModalShow){
            return null;
        }

        let ingredientsString = '';
        for (const item of this.state.ingredients){
            ingredientsString += (item + ', ');
        }

        return (
            <Modal size={'lg'} show={this.state.addItemEveryWeekModalShow} onHide={() => {this.setState({addItemEveryWeekModalShow: false})}}>
                <Modal.Header closeButton><strong>Add Item to Every Week</strong></Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Item Name</Form.Label>
                                <Form.Control required type="text" placeholder="Enter item name"
                                              name="itemName"
                                              onChange={this.handleChange}/>
                            </Form.Group>
                            <Form.Group as={Col} className={'d-inline-row'}>
                                <Form.Label>Ingredients</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type={'text'}
                                        name={'ingredientCache'}
                                        onChange={this.handleChange}
                                        placeholder={'Enter ingredients'}
                                        aria-label="Ingredients"
                                    />
                                    <InputGroup.Append>
                                        <Button variant="outline-secondary"
                                                onClick={() => this.addIngredientCacheToList()}><BsPlus/></Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Form.Label>Item Type</Form.Label>
                                <Form.Control
                                    required
                                    as="select"
                                    name="selectedAddType"
                                    defaultValue={'protein'}
                                    onChange={this.handleChange}
                                    custom>
                                    <option value={'protein'}>Protein</option>
                                    <option value={'carbs'}>Carbs</option>
                                    <option value={'veggies'}>Veggies</option>
                                    <option value={'breakfasts'}>Breakfast</option>
                                    <option value={'assembled_meals'}>Assembled Meal</option>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                        <ListGroup>
                            <ListGroup.Item>
                                <Row>
                                    <Col md={3}>{this.state.itemName}: </Col>
                                    <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                                    <Col md={1}>
                                        <Button variant={'danger'}
                                                onClick={() => this.setState({
                                                    ingredients: [],
                                                    itemName: ''
                                                })}>
                                            <BsFillTrashFill/>
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        </ListGroup>
                    </Form>
                    <Button variant={'secondary'}
                            onClick={() => this.setState({
                                addProteinCardShow: false,
                                itemName: '',
                                ingredients: []
                            })}
                            className={'float-left mt-2 mb-2'}>
                        Close
                    </Button>
                    {this.addItemEveryWeekSubmitButton}
                </Modal.Body>
            </Modal>
        );
    }

    get addItemEveryWeekSubmitButton(){
        if (this.state.addItemEveryWeekSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.onAddItemEveryWeekSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Add Item to Each Week
                </Button>
            );
        }
    }

    onAddItemEveryWeekSubmit = (event) => {
        event.preventDefault();
        this.setState({
            ingredients: [],
            itemName: '',
            addItemEveryWeekSubmitClicked: true
        });

        let changeTracker = this.state.changeTracker;
        ++changeTracker;

        let post_body = {
            year: this.state.adminMenuJSON.years_info[this.state.selectedYear].year,
            quarter: this.state.selectedQuarter,
            type: this.state.selectedAddType,
            itemName: this.state.itemName,
            ingredients: this.state.ingredients,
        };
        let url = '/api/admin/menu/weeks/add';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(() => {this.setState({
            changeTracker: changeTracker,
            addItemEveryWeekSubmitClicked: false
        })});

        setTimeout(() => {
            this.setState({
                addItemEveryWeekModalShow: false,
            })
        }, 3000);
    };

    get sendEmailModal(){

        if (this.state.adminMenuJSON == null || this.state.selectedWeek == null){
            return null;
        }

        let selectedQuarter = this.state.adminMenuJSON.years_info[this.state.selectedYear].quarters[this.state.selectedQuarter].weeks;
        let week = selectedQuarter[this.state.selectedWeek];
        let week_published = week.published != null ? week.published : false;

        return (
            <Modal show={this.state.sendEmailModalShow} onHide={() => {this.setState({sendEmailModalShow: false})}}>
                <Modal.Header closeButton>Send Notification Email</Modal.Header>
                <Modal.Body>
                    Year: {this.state.selectedYear === 0 ? '2020-2021' : ''}<br/>
                    Quarter: {this.state.selectedQuarter}<br/>
                    Week: {this.state.selectedWeek}<br/><br/>
                    Send notification email that this week in this quarter has been published? Week must be published.<br/>

                    {this.sendEmailSubmitButton(week_published)}
                </Modal.Body>
            </Modal>
        );
    }

    sendEmailSubmitButton(week_published){
        if (this.state.sendEmailSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button disabled={!week_published}
                        onClick={this.onSendEmailSubmit}
                        className={'mt-3 float-right'}>
                    Send Email
                </Button>
            );
        }
    }

    onSendEmailSubmit = (event) => {
        event.preventDefault();
        this.setState({
            sendEmailSubmitClicked: true
        });

        let post_body = {
            year: this.state.adminMenuJSON.years_info[this.state.selectedYear].year,
            quarter: this.state.selectedQuarter,
            week: this.state.selectedWeek
        };
        let url = '/api/admin/menu/week/sendEmail';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(() => {this.setState({
            sendEmailSubmitClicked: false
        })});

        setTimeout(() => {
            this.setState({
                sendEmailModalShow: false,
            })
        }, 2000);
    };

    get setMealPlanCashModal(){
        return (
            <Modal show={this.state.setMealPlanCashModalShow} onHide={() => {this.setState({setMealPlanCashModalShow: false})}}>
                <Modal.Header closeButton>Set User Meal Plan Cash</Modal.Header>
                <Modal.Body>
                    Using this tool sets the meal plan cash amount for all users. You can set it to whatever you'd like,
                    however the normal starting point is $150.
                    <Form className={'mt-3'}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control required type="number" placeholder="Enter amount"
                                              name="amount"
                                              onChange={this.handleChange}/>
                            </Form.Group>
                        </Form.Row>
                        {this.setMealPlanCashSubmitButton}
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    get setMealPlanCostModal(){
        return (
            <Modal show={this.state.setMealPlanCostModalShow} onHide={() => {this.setState({setMealPlanCostModalShow: false})}}>
                <Modal.Header closeButton><strong><BsCommand/> Set Global Meal Plan Costs</strong></Modal.Header>
                <AdminPageMenuSetMealPlanAmount />
            </Modal>
        );
    }

    get setMealPlanCashSubmitButton(){
        if (this.state.setMealPlanCashSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.setMealPlanCashSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Set Amount For All Users
                </Button>
            );
        }
    }

    setMealPlanCashSubmit = (event) => {
        event.preventDefault();
        this.setState({
            setMealPlanCashSubmitClicked: true
        });

        let post_body = {
            amount: parseInt(this.state.amount)
        };
        let url = '/api/admin/menu/mealPlanCash/set';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(() => {this.setState({
            setMealPlanCashSubmitClicked: false
        })});
    };

    render(){
        if (this.props.currentTab !== 4){
            return null;
        }

        if (this.state.adminMenuJSON == null){
            return(
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }
        else{
            return(
                <div className={"bk_text ml-0 mr-0"}>
                    {this.menuEditModal}
                    {this.addNewYearModal}
                    {this.snacksModal}
                    {this.sendEmailModal}
                    {this.setMealPlanCashModal}
                    {this.setMealPlanCostModal}
                    <h1 className={"bk_header mt-3"}> menu utilities </h1>
                    <Row>
                        <Col sm>{this.currentWeekCard}</Col>
                    </Row>
                    <Row>
                        <Col sm>{this.quarterlyMenuCard}</Col>
                    </Row>
                </div>
            );
        }
    }

}