import React, {Component} from "react";
import {Card, Row, Col, ListGroup, Button, Spinner} from "react-bootstrap";
import {ReactComponent as Logo} from "../images/bk_logo_full_final.svg";
import card_img_1 from "../images/meal_prep_background.png";
import { FaCalendarDay, FaCar,  FaClock} from "react-icons/fa";
import {IconContext} from "react-icons";
import {Link} from "react-router-dom";

export default class HomePage extends Component{

    constructor(props) {
        super(props);

        this.state = {
            currentPricesJSON: null
        };
    }

    updateCurrentPricesJSON(){
        let url = encodeURI('/api/getMealPlanCosts');

        fetch(url).then(res => res.json()).then(data => {this.setState({
            currentPricesJSON: data
        })})
    }
    componentDidMount() {
        this.updateCurrentPricesJSON();
    }

    get mealPlanCards(){
        if (this.state.currentPricesJSON == null){
            return (
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }

        if (!this.state.currentPricesJSON.success){
            return null;
        }

        let mealPlan150Cost = this.state.currentPricesJSON.meal_plan_150_cost;
        let mealPlan175Cost = this.state.currentPricesJSON.meal_plan_175_cost;
        // let salesTax = this.state.currentPricesJSON.sales_tax_percentage / 100;

        return (
            <Row className={"mb-2"}>
                <Col sm className={"mt-2 mb-2 bk_text"}>
                    <Card>
                        <Card.Body>
                            <Card.Title className={"bk_header"}>150 Block Plan</Card.Title>
                            <ListGroup className={"list-group-flush"}>
                                <ListGroup.Item className={"list-group-item-primary font-weight-bold"}>
                                    ${(mealPlan150Cost).toFixed(2)} per quarter
                                </ListGroup.Item>
                                <ListGroup.Item>~15 meals per week (3 breakfasts)</ListGroup.Item>
                                <ListGroup.Item>~150 Total Meals</ListGroup.Item>
                                <ListGroup.Item>$150 Fun Cash per quarter</ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                <br/>
                                The 150 Block Plan offers approximately 15 meals per week and is designed for students who
                                like flexibility on when they wish to eat and will be on campus most weekends.
                                The $150 in Fun Cash per quarter offers more additional cash to spend at restaurants, convenience stores, and coffee shops surrounding the DU community.
                                <br/><br/>
                                This price includes sales tax.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm className={"mt-2 mb-2 bk_text"}>
                    <Card>
                        <Card.Body>
                            <Card.Title className={"bk_header"}>175 Block Plan</Card.Title>
                            <ListGroup className={"list-group-flush"}>
                                <ListGroup.Item className={"list-group-item-primary font-weight-bold"}>
                                    ${(mealPlan175Cost).toFixed(2)} per quarter
                                </ListGroup.Item>
                                <ListGroup.Item>~17 meals per week (5 breakfasts)</ListGroup.Item>
                                <ListGroup.Item>~175 Total Meals</ListGroup.Item>
                                <ListGroup.Item>$150 Fun Cash per quarter</ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                <br/>
                                The 175 Block Plan offers approximately 17 meals per week and is designed for students who
                                like flexibility on when they wish to eat and will be on campus most, if not all, weekends.
                                The $150 in Fun Cash per quarter offers more additional cash to spend at restaurants, convenience stores, and coffee shops surrounding the DU community.
                                <br/><br/>
                                This price includes sales tax.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }

    get footer() {
        return (<div className="mb-3 text-secondary">
            <Row>
                <Col>
                    <strong>Beta Kitchen by Gourmet Fitness</strong>
                </Col>
                <Col>
                    <Link to="/terms-and-conditions" className="bk_link">
                        User Agreement
                    </Link>
                </Col>
                <Col></Col>
            </Row>
        </div>);
    }

    render(){
        return(
            <div>
                <Logo className={'logo'}/>
                <div className={"main_container"}>
                    <div>
                        <h3 className={"mt-3 mb-3 text_container bk_text d-flex"}>
                            <strong>Meal Plans for Beta</strong>
                        </h3>
                        <p className={"text_container bk_text d-flex  mt-3 mb-4"}>
                            Beta Kitchen by Gourmet Fitness was crafted to meet the unique needs of
                            the Alpha Zeta chapter house. These meal plans are taking the place of the
                            University of Denver's current options by offering fresh, high quality food
                            that is hand delivered to each individual brother twice a week.
                        </p>
                    </div>
                    <Card className={"bk-dark"}>
                        <Card.Img src={card_img_1} />
                    </Card>
                    <h3 className={"mt-5 mb-3 text_container bk_text d-flex"}>
                        <strong>How it Works</strong>
                    </h3>
                    <p className={"text_container bk_text d-flex  mt-3 mb-4"}>
                        Each meal plan comes with a specific number of meals per week as well as $150 worth of Fun Cash per quarter.
                    </p>
                    <h5 className={"mt-2 mb-3 text_container bk_text d-flex"}>
                        <strong>- Meals</strong>
                    </h5>
                    <Row>
                        <Col sm className={"justify-content-center d-flex mt-2"}>
                            <Row><Col>
                                <IconContext.Provider value={{color: 'black', size: '80'}}>
                                    <FaCalendarDay/>
                                </IconContext.Provider>
                            </Col></Row>
                            <Row><Col className={"ml-2 bk_text"}>
                                <h6>Pick your meals</h6>
                                <p>
                                    Pick your protein, carbs, veggies, and breakfast foods the week prior to meals being delivered.
                                </p>
                            </Col></Row>
                        </Col>
                        <Col sm className={"justify-content-center d-flex mt-2"}>
                            <Row><Col>
                                <IconContext.Provider value={{color: 'black', size: '80'}}>
                                    <FaClock/>
                                </IconContext.Provider>
                            </Col></Row>
                            <Row><Col className={"ml-2 bk_text"}>
                                <h6>Meal creation</h6>
                                <p>
                                    Meals are prepared by Gourmet Fitness Chefs within 24 hours of delivery.
                                </p>

                            </Col></Row>
                        </Col>
                        <Col sm className={"justify-content-center d-flex mt-2"}>
                            <Row><Col>
                                <IconContext.Provider value={{color: 'black', size: '80'}}>
                                    <FaCar/>
                                </IconContext.Provider>
                            </Col></Row>
                            <Row><Col className={"ml-2 bk_text"}>
                                <h6>Food delivery</h6>
                                <p>
                                    Meals are delivered to your door on Monday and Thursday of each week.
                                </p>
                            </Col></Row>
                        </Col>
                    </Row>
                    <h5 className={"mt-2 mb-3 text_container bk_text d-flex"}>
                        <strong>- Fun Cash</strong>
                    </h5>
                    <p className={"text_container bk_text d-flex  mt-3 mb-4"}>
                        You will choose your "Fun Cash" gift cards at the beginning of each quarter and it will be delivered on the second delivery of the first week!
                    </p>
                    <h3 className={"mt-5 mb-3 text_container bk_text d-flex"}>
                        <strong>Meal Plan Options</strong>
                    </h3>
                    <p className={"text_container bk_text d-flex  mt-3 mb-4"}>
                        Click below to start your registration for one of the two meal plans!
                    </p>
                    <div className={"justify-content-center mb-3"}>
                        {/*<Button variant='primary' className='bk_button' disabled>*/}
                        {/*    Register*/}
                        {/*</Button>*/}
                        <Link to="/registration">
                            <Button variant={"success"} block>
                                Register now!
                            </Button>
                        </Link>
                    </div>
                    {this.mealPlanCards}
                    <br/>
                    <hr/>
                    {this.footer}
                </div>
            </div>
        );
    }

}