import {Redirect, Route} from "react-router-dom";
import React from "react";

// function checkKey(){
//     let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
//     let account_name = encodeURIComponent(localStorage.getItem('user'));
//     let url = encodeURI("/api/authenticate?account_hash=" + account_hash + "&user=" + account_name);
//
//     fetch(url).then(res => res.json()).then(data => {
//         console.log('checkKey success: ', data.success);
//         return(data.success);
//     });
// }

const AuthenticatedRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            localStorage.getItem('hashed_login_key') !== '0' &&
                localStorage.getItem('hashed_login_key') ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);
export default AuthenticatedRoute;