import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Form, Modal, Spinner} from "react-bootstrap";
import {BsArrowRight} from "react-icons/all";
import {BsExclamationTriangle, BsCheck} from "react-icons/all";
import {getPoints, postSetPointsAdmin} from "../../../../utils/ApiHandler";

export default function AdminAddItemModal(props)
{
   const [points150, setPoints150] = useState(0);
   const [points175, setPoints175] = useState(0);
   const [currentPoints150, setCurrentPoints150] = useState(0);
   const [currentPoints175, setCurrentPoints175] = useState(0);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);
    const [errorNotifBody, setErrorNotifBody] = useState(null);

    function notification()
    {
        if (errorNotifBody != null)
        {
            return (
                <Alert variant="danger">
                    <BsExclamationTriangle/> {errorNotifBody}
                </Alert>
            );
        }
    }

    useEffect(() => {
        getPoints().then((result) => {
            if (result.success)
            {
                console.log(result);
                if (result.curr_points != null)
                {
                    setCurrentPoints150(result.curr_points.points150);
                    setCurrentPoints175(result.curr_points.points175);
                }
            }
        });
    }, [submitButtonClicked]);

    const submitClicked = () => {
        setSubmitButtonClicked(true);
        setErrorNotifBody(null);

        let data = {
            'points150': points150,
            'points175': points175
        };

        postSetPointsAdmin(data).then((response) => {
            if (response.success)
            {
                setSubmitButtonClicked(false);
            }
            else
            {
                setErrorNotifBody(response.error);
                setSubmitButtonClicked(false);
            }
        });
    };

    function submitButton()
    {
        if (submitButtonClicked)
        {
            return (
                <Button
                    block
                    variant="dark"
                >
                    <Spinner animation="border" role="status" variant="light"/>
                </Button>
            );
        }
        else
        {
            return (
                <Button block
                        variant="dark"
                        className="mt-3"
                        onClick={submitClicked}
                >
                    Set Points
                </Button>
            );
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            backdrop="static"
            keyboard={false}
            className="kitchen_modal_body"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Menu Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Adjust the number of points each person gets per week per meal plan.</p>
                <Form className="ml-3 mr-3">
                    {notification()}
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>*150 Block Points</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="Enter item name"
                                name="points150"
                                value={points150}
                                onChange={(event) => setPoints150(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>*175 Block Points</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="Enter item name"
                                name="points175"
                                value={points175}
                                onChange={(event) => setPoints175(event.target.value)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Current 150 Block Points</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="Enter item name"
                                name="currentPoints150"
                                value={currentPoints150}
                                readOnly
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Current 175 Block Points</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                name="currentPoints175"
                                value={currentPoints175}
                                readOnly
                            />
                        </Form.Group>
                    </Form.Row>
                    {submitButton()}
                </Form>
            </Modal.Body>
        </Modal>
    );
}