import React, {Component} from "react";
import {Navbar, Nav, Badge} from "react-bootstrap";
import {
    BsFillHouseFill, BsFillPeopleFill,
    BsFillPieChartFill, BsFillKanbanFill, BsCardList,
    BsFillEnvelopeFill, BsFillGearFill
} from "react-icons/all";
import {Redirect} from "react-router-dom";
import AdminPageUsers from "./AdminPageUsers";
import AdminPageDashboard from "./AdminPageDashboard";
import AdminPageAccounting from "./AdminPageAccounting";
import "./AdminPage.css";
import AdminPageMenu from "./AdminPageMenu";
import AdminPagePicks from "./AdminPagePicks";
import AdminPagePicksV2 from "./AdminPagePicksV2";
import AdminPageMenuV2 from "./MenuV2/AdminPageMenuV2";

export default class AdminPage extends Component{

    constructor(props){
        super(props);

        this.state = {
            currentTab: 1
        };

        this.handleClick = this.handleClick.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    /**
     * handleClick Function
     * @param tab : Number of tab to switch state to
     */
    handleClick(tab){
        this.setState({
            currentTab: tab,
            width: 0,
            height: 0
        });
    }

    //*** render() function

    //*** Window Resizing for Chart Resizing
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        if (window.innerWidth !== 0){
            console.log('updateWindowDimensions called: ', window.innerWidth);
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render(){
        if (localStorage.getItem('rhash') !== '21232f297a57a5a743894a0e4a801fc3'){
            return (<Redirect to='/account'/>);
        }

        console.log('width: ', this.state.width);

        let pages = (
            <div>
                <AdminPageDashboard currentTab={this.state.currentTab} handleClick={this.handleClick}/>
                <AdminPageUsers currentTab={this.state.currentTab}/>
                <AdminPageAccounting currentTab={this.state.currentTab}/>
                <AdminPageMenu currentTab={this.state.currentTab}/>
                <AdminPagePicksV2 currentTab={this.state.currentTab}/>
                <AdminPageMenuV2 currentTab={this.state.currentTab}/>
            </div>
        );

        if (window.innerWidth > 1000){
            return (
                <>
                    <div className={"bk_text text-left sidebar bg-dark"}>
                        <Navbar variant={'dark'} bg={'dark'} className={'flex-column mt-5'} sticky={'top'}>
                            <Nav className={"mr-auto flex-column"}>
                                <Navbar.Brand className={'text-left'}>admin tools</Navbar.Brand>
                                <Nav.Link onClick={() => this.handleClick(1)}
                                          className={this.state.currentTab === 1 ? 'font-weight-bold text-primary' : ''}>
                                    <BsFillHouseFill/> Dashboard
                                </Nav.Link>
                                <Nav.Link onClick={() => this.handleClick(2)}
                                          className={this.state.currentTab === 2 ? 'font-weight-bold text-primary' : ''}>
                                    <BsFillPeopleFill/> Users
                                </Nav.Link>
                                <Nav.Link onClick={() => this.handleClick(3)}
                                          className={this.state.currentTab === 3 ? 'font-weight-bold text-primary' : ''}>
                                    <BsFillPieChartFill/> Accounting
                                </Nav.Link>
                                <Nav.Link onClick={() => this.handleClick(4)}
                                          className={this.state.currentTab === 4 ? 'font-weight-bold text-primary' : ''}>
                                    <BsFillGearFill/> Menu Utils
                                </Nav.Link>
                                <Nav.Link onClick={() => this.handleClick(7)}
                                          className={this.state.currentTab === 7 ? 'font-weight-bold text-primary' : ''}>
                                    <BsCardList/> Picks v2
                                </Nav.Link>
                                <Nav.Link onClick={() => this.handleClick(8)}
                                          className={this.state.currentTab === 8 ? 'font-weight-bold text-primary' : ''}>
                                    <BsFillKanbanFill/> Menu v2
                                </Nav.Link>
                            </Nav>
                        </Navbar>
                    </div>
                    <div className={'main text-left px-5'}>
                        {pages}
                    </div>
                </>
            );
        }
        else {
            return (
                <div className={"bk_text text-left ml-3 mr-3 mt-3 mb-3"}>
                    <h5 className={"bk_header"}> admin tools </h5>
                    <Navbar bg={"light"} variant={'light'} expand={'sm'}>
                        <Nav className={"mr-auto"}>
                            <Nav.Link onClick={() => this.handleClick(1)}
                                      className={this.state.currentTab === 1 ? 'font-weight-bold' : ''}>
                                <BsFillHouseFill/> Dashboard
                            </Nav.Link>
                            <Nav.Link onClick={() => this.handleClick(2)}
                                      className={this.state.currentTab === 2 ? 'font-weight-bold' : ''}>
                                <BsFillPeopleFill/> Users
                            </Nav.Link>
                            <Nav.Link onClick={() => this.handleClick(3)}
                                      className={this.state.currentTab === 3 ? 'font-weight-bold' : ''}>
                                <BsFillPieChartFill/> Accounting
                            </Nav.Link>
                            <Nav.Link onClick={() => this.handleClick(4)}
                                      className={this.state.currentTab === 4 ? 'font-weight-bold' : ''}>
                                <BsFillGearFill/> Menu Utils
                            </Nav.Link>
                            <Nav.Link onClick={() => this.handleClick(7)}
                                      className={this.state.currentTab === 7 ? 'font-weight-bold text-primary' : ''}>
                                <BsCardList/> Picks v2
                            </Nav.Link>
                            <Nav.Link onClick={() => this.handleClick(8)}
                                      className={this.state.currentTab === 8 ? 'font-weight-bold text-primary' : ''}>
                                <BsFillKanbanFill/> Menu v2
                            </Nav.Link>
                        </Nav>
                    </Navbar>
                    {pages}
                </div>
            );
        }


    }

}