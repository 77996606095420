import React, {Component} from "react";
import {
    Card, Col, Button, Table, ButtonGroup, Modal, Alert,
    Spinner, Form, ListGroup, ButtonToolbar, DropdownButton, DropdownItem
} from "react-bootstrap";
import {
    BsFillEnvelopeFill, BsFillPersonLinesFill,
    BsFillPersonDashFill, BsFillPersonPlusFill, BsFillPeopleFill,
    BsArrowRepeat, BsFillTrashFill, BsFillGearFill, BsCheckAll, BsCircle, BsCheckCircle
} from "react-icons/all";
import UserInfoModal from "./AdminPageUsersModals/UserInfoModal/UserInfoModal";

export default class AdminPageUsers extends Component{
    constructor(props) {
        super(props);

        //Only props needed are:
        //  - currentTab

        this.state = {
            usersInfoJSON: null,
            // selected user email to be used in userDelete, userEmail and userInfo
            selectedUserEmail: null,
            selectedUserEmails: [],
            // the different modals available
            userInfoModalShow: false,
            userEmailModalShow: false,
            userDeleteModalShow: false,
            syncTimeModalShow: false,
            addAdminModalShow: false,
            multipleEmailModalShow: false,
            multipleDeleteModalShow: false,
            // addAdmin variables needed
            addAdminJSON: null,
            addAdminNotifShow: false,
            addAdminToEmail: null,
            addAdminSubmitClicked: false,
            emailValid: null,
            // addAdminDeleteInvite variables needed
            deleteAdminInviteJSON: null,
            deleteAdminNotifShow: false,
            deleteAdminInviteSubmitClicked: false,
            deleteAdminInviteCardShow: false,
            // userDelete variables needed
            userDeleteJSON: null,
            userDeleteNotifShow: false,
            userDeleteSubmitClicked: false,
            userDeleteTracker: 0,
            // syncTime variables needed
            syncTimeJSON: null,
            syncTimeSubmitClicked: false,
            syncAddressesSubmitClicked: false,
            syncCurrentYearSubmitClicked: false,
            syncTimeNotifShow: false,

            // other
            resetMealPlanCashClicked: false,
            // userEmail variables needed
            userEmailJSON: null,
            userEmailNotifShow: false,
            userEmailSubmitClicked: false,
            emailBody: '',
            emailSubject: '',
            // multipleEmail variables needed
            multipleEmailJSON: null,
            multipleEmailNotifShow: false,
            multipleEmailSubmitClicked: false,
            // multipleDelete variables needed
            multipleDeleteJSON: null,
            multipleDeleteNotifShow: false,
            multipleDeleteSubmitClicked: false,
            // change password
            changePasswordModalShow: false,
            changePasswordJSON: null,
            changePasswordSubmitClicked: false,
            changeEmail: '',
            changePassword: ''
        };

        // bind for access to (this)
        this.handleIsValid = this.handleIsValid.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
    }

    //*** API Calls
    updateUsersInfoJSON(){
        // Load admin information
        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI('api/admin/users?account_hash=' + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            usersInfoJSON: data
        })});
    }
    componentDidMount() {
        this.updateUsersInfoJSON();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.userDeleteTracker !== this.state.userDeleteTracker){
            //Update if delete tracker shows an update
            this.updateUsersInfoJSON();
        }
        else if (prevProps.currentTab !== 2 && this.props.currentTab === 2){
            //Update if tab switched over
            this.updateUsersInfoJSON();
        }
    }

    //TODO: Create a button that allows authentication of users.

    /**
     * showModal function
     * @param modalTitleShow = name of modalShow variable in state
     * @param email = email to update currently selectedUserEmail
     */
    showModal(modalTitleShow, email){
        this.setState({
            [modalTitleShow]: true,
            selectedUserEmail: email
        });
    }

    //*** addAdminModal Functions
    //  - handleIsValid()
    //  - handleChangeEmail()
    //  - handleInviteSubmit
    //*** addAdminModal Components
    //  - get addAdminSubmitButton()
    //  - get addAdminModalNotification()
    //  - get addAdminModalBody()
    //  - get addAdminModal() <- Full Component

    handleIsValid(fieldName){
        return this.state[fieldName] != null ? (this.state[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }
    handleChangeEmail(event){
        const {value} = event.target;
        let emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length === 0){
            this.setState({
                emailValid: false
            });
        }
        else if (emailPattern.test(value)){
            this.setState({
                emailValid: true,
                addAdminToEmail: value
            });

        }
        else {
            this.setState({
                emailValid: false
            });
        }
    }
    handleInviteSubmit = (event) => {
        event.preventDefault();
        this.setState({
            addAdminSubmitClicked: true,
            addAdminNotifShow: false
        });

        let userDeleteTracker = this.state.userDeleteTracker;
        ++userDeleteTracker;

        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let to_email = encodeURIComponent(this.state.addAdminToEmail);
        let url = encodeURI("/api/admin/invitation?account_hash=" + account_hash + "&user=" + account_name + "&to_email=" + to_email);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            addAdminJSON: data,
            addAdminNotifShow: true,
            userDeleteTracker: userDeleteTracker,
            addAdminSubmitClicked: false
        })});

        setTimeout(() => {
            this.setState({
                addAdminNotifShow: false
            });
        }, 3000);
    };
    handleInviteDeleteSubmit = (event) => {
        event.preventDefault();
        this.setState({
            deleteAdminInviteSubmitClicked: true
        });

        let userDeleteTracker = this.state.userDeleteTracker;
        ++userDeleteTracker;

        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let email = encodeURIComponent(this.state.selectedUserEmail);
        let url = encodeURI("/api/admin/invitation/delete?account_hash=" + account_hash + "&user=" + account_name + "&email=" + email);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            deleteAdminInviteJSON: data,
            deleteAdminNotifShow: true,
            deleteAdminInviteCardShow: false,
            userDeleteTracker: userDeleteTracker,
            deleteAdminInviteSubmitClicked: false,
        })});

        setTimeout(() => {
            this.setState({
                deleteAdminNotifShow: false,
            })
        }, 3000);
    };

    get addAdminSubmitButton(){
        if (this.state.addAdminSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleInviteSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Send
                </Button>
            );
        }
    }
    get addAdminModalNotification(){
        if (this.state.addAdminNotifShow){
            if (this.state.addAdminJSON.success){
                return (
                    <Alert variant={'success'}>
                        Invite has been sent!
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.addAdminJSON.error}
                    </Alert>
                );
            }

        }
        else {
            return null;
        }
    }

    get deleteAdminInviteSubmitButton(){
        if (this.state.deleteAdminInviteSubmitClicked){
            return (
                <Button className="btn btn-danger float-right mt-2 mb-2" variant="danger" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleInviteDeleteSubmit} className="btn btn-danger float-right mt-2 mb-2" type="button">
                    Delete Invite
                </Button>
            );
        }
    }
    get deleteAdminInviteModalNotification(){
        if (this.state.deleteAdminNotifShow){
            if (this.state.deleteAdminInviteJSON.success){
                return (
                    <Alert variant={'success'}>
                        Admin invite has been deleted!
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.deleteAdminInviteJSON.error}
                    </Alert>
                );
            }

        }
        else {
            return null;
        }
    }
    get addAdminInvitationsTableData(){
        let buffer = [];

        for (const element of this.state.usersInfoJSON.admin_invites){
            buffer.push(
                <tr key={element.email}>
                    <td>{element.email}</td>
                    <td>{element.timestamp}</td>
                    <td>{element.used ? 'True': 'False'}</td>
                    <td>
                        <Button className={'float-right'} onClick={() => this.showModal('deleteAdminInviteCardShow', element.email)} variant={'danger'}><BsFillTrashFill/></Button>
                    </td>
                </tr>);
        }

        return (
            <tbody>
            {buffer}
            </tbody>
        );
    }
    get addAdminInvitationsTable(){
        return (
            <Table striped hover responsive className={"mt-3 mb-0"}>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Timestamp</th>
                    <th>Used?</th>
                    <th><div className={"float-right"}>Actions</div></th>
                </tr>
                </thead>
                {this.addAdminInvitationsTableData}
            </Table>
        );
    }
    get deleteAdminInviteCard(){
        if (!this.state.deleteAdminInviteCardShow){
            return null;
        }

        return(
            <Card className={'mt-3'}>
                <Card.Body>
                    {this.userDeleteModalNotification}
                    User: <strong>{this.state.selectedUserEmail}</strong><br/>
                    Are you sure you would like to delete this invitation?
                    <div className={'mt-2'}>
                        <Button onClick={() => this.setState({deleteAdminInviteCardShow: false})}
                                className="btn btn-secondary mt-2 mb-2" type="button">
                            Cancel
                        </Button>
                        {this.deleteAdminInviteSubmitButton}
                    </div>
                </Card.Body>
            </Card>
        );
    }
    get addAdminInvitations(){
        if (this.state.usersInfoJSON.admin_invites == null){
            return null;
        }
        else {
            return (
                <div className={'mt-5'}>
                    <strong><br/>Current Invitations</strong>
                    {this.addAdminInvitationsTable}
                    {this.deleteAdminInviteCard}
                </div>
            );
        }
    }

    get addAdminModalBody(){
        return (
            <Modal.Body>
                {this.addAdminModalNotification}
                {this.deleteAdminInviteModalNotification}
                Enter an email address below to send an invite to join the administration team.
                <Form className={"mt-5"}>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control required type="email" placeholder="Enter email address"
                                          name="email"
                                          className = {this.handleIsValid('emailValid')}
                                          onChange={this.handleChangeEmail}
                            />
                            <Form.Control.Feedback type={'invalid'}>Please enter a valid email address.</Form.Control.Feedback>
                        </Form.Group>
                    </Form.Row>
                    {this.addAdminSubmitButton}
                </Form>
                {this.addAdminInvitations}
            </Modal.Body>
        );
    }
    get addAdminModal(){
        return (
            <Modal size={'lg'} show={this.state.addAdminModalShow} onHide={() => this.setState({addAdminModalShow: false})}>
                <Modal.Header closeButton><strong><BsFillPersonPlusFill/> Invite New Administrator</strong></Modal.Header>
                {this.addAdminModalBody}
            </Modal>
        );
    }

    //*** userDeleteModal Functions
    //  - handleDeleteSubmit
    //*** userDeleteModal Components
    //  - get userDeleteSubmitButton()
    //  - get userDeleteModalNotification()
    //  - get userDeleteModalBody()
    //  - get userDeleteModal() <- Full Component

    handleDeleteSubmit = (event) => {
        event.preventDefault();
        this.setState({
            userDeleteSubmitClicked: true
        });

        let userDeleteTracker = this.state.userDeleteTracker;
        ++userDeleteTracker;

        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let email = encodeURIComponent(this.state.selectedUserEmail);
        let url = encodeURI("/api/admin/users/delete?account_hash=" + account_hash + "&user=" + account_name + "&email=" + email);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            userDeleteJSON: data,
            userDeleteNotifShow: true,
            userDeleteTracker: userDeleteTracker,
            userDeleteSubmitClicked: false,
        })});

        setTimeout(() => {
            this.setState({
                userDeleteNotifShow: false,
                userDeleteModalShow: false,
            })
        }, 3000);
    };

    get userDeleteSubmitButton(){
        if (this.state.userDeleteSubmitClicked){
            return (
                <Button className="btn btn-danger float-right mt-2 mb-2" variant="danger" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleDeleteSubmit} className="btn btn-danger float-right mt-2 mb-2" type="button">
                    Delete User
                </Button>
            );
        }
    }
    get userDeleteModalNotification(){
        if (this.state.userDeleteNotifShow){
            if (this.state.userDeleteJSON.success){
                return (
                    <Alert variant={'success'}>
                        User has been successfully deleted.
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.userDeleteJSON.error}
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }
    get userDeleteModalBody(){
        return (
            <Modal.Body>
                {this.userDeleteModalNotification}
                User: <strong>{this.state.selectedUserEmail}</strong><br/>
                Are you sure you would like to delete this user? This cannot be undone.
                <div className={'mt-2'}>
                    <Button onClick={() => this.setState({userDeleteModalShow: false})}
                            className="btn btn-secondary mt-2 mb-2" type="button">
                        Cancel
                    </Button>
                    {this.userDeleteSubmitButton}
                </div>
            </Modal.Body>
        );
    }
    get userDeleteModal(){
        return (
            <Modal show={this.state.userDeleteModalShow} onHide={() => this.setState({userDeleteModalShow: false})}>
                <Modal.Header closeButton><strong><BsFillPersonDashFill/> Delete User</strong></Modal.Header>
                {this.userDeleteModalBody}
            </Modal>
        );
    }

    //*** userEmailModal Functions
    //  - handleEmailSubmit
    //*** userEmailModal Components
    //  - get userEmailSubmitButton()
    //  - get userEmailModalNotification()
    //  - get userEmailModalBody()
    //  - get userEmailModal() <- Full Component

    handleEmailSubmit = (event) => {
        event.preventDefault();
        this.setState({
            userEmailSubmitClicked: true
        });

        let post_body = {
            subject: this.state.emailSubject,
            body: this.state.emailBody,
            to_addr: this.state.selectedUserEmail
        };
        let url = '/api/admin/email/single';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(data => {this.setState({
            userEmailJSON: data,
            userEmailNotifShow: true,
            userEmailSubmitClicked: false
        })});

        // Show the notif for 3 seconds
        setTimeout(() => {
            this.setState({
                userEmailNotifShow: false,
            })
        }, 3000);
    };
    handleEmailChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    };

    get userEmailSubmitButton(){
        if (this.state.userEmailSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleEmailSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Send Email
                </Button>
            );
        }
    }
    get userEmailModalNotification(){
        if (this.state.userEmailNotifShow){
            if (this.state.userEmailJSON.success){
                return (
                    <Alert variant={'success'}>
                        The email has been successfully sent!
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.userEmailJSON.error}
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }
    get userEmailModalBody(){
        return (
            <Modal.Body>
                {this.userEmailModalNotification}
                You can type line-breaks by typing {'<br/>'}.<br/>
                User: <strong>{this.state.selectedUserEmail}</strong><br/>
                <Form className={'mt-3'}>
                    <Form.Group>
                        <Form.Label>Email Subject</Form.Label>
                        <Form.Control onChange={this.handleEmailChange}
                                      type="text" placeholder="Enter email subject line" name={'emailSubject'} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email Body</Form.Label>
                        <Form.Control onChange={this.handleEmailChange}
                                      as="textarea" placeholder={'Enter email body'} name={'emailBody'} rows="3" />
                    </Form.Group>
                    {this.userEmailSubmitButton}
                </Form>
            </Modal.Body>
        );
    }
    get userEmailModal(){
        return(
            <Modal show={this.state.userEmailModalShow} onHide={() => this.setState({userEmailModalShow: false})}>
                <Modal.Header closeButton><strong><BsFillEnvelopeFill/> Email User</strong></Modal.Header>
                {this.userEmailModalBody}
            </Modal>
        );
    }

    //*** multipleEmailModal Functions
    //  - handleMultipleEmailSubmit
    //*** multipleEmailModal Components
    //  - get multipleEmailSubmitButton()
    //  - get multipleEmailModalNotification()
    //  - get multipleEmailModalBody()
    //  - get multipleEmailModal() <- Full Component

    handleMultipleEmailSubmit = (event) => {
        event.preventDefault();
        this.setState({
            multipleEmailSubmitClicked: true
        });

        let post_body = {
            subject: this.state.emailSubject,
            body: this.state.emailBody,
            to_addresses: this.state.selectedUserEmails
        };
        let url = '/api/admin/email/multiple';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(data => {this.setState({
            multipleEmailJSON: data,
            multipleEmailNotifShow: true,
            multipleEmailSubmitClicked: false
        })});

        // Show the notif for 3 seconds
        setTimeout(() => {
            this.setState({
                multipleEmailNotifShow: false,
            })
        }, 3000);
    };

    get multipleEmailSubmitButton(){
        if (this.state.multipleEmailSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleMultipleEmailSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Send Emails
                </Button>
            );
        }
    }
    get multipleEmailModalNotification(){
        if (this.state.multipleEmailNotifShow){
            if (this.state.multipleEmailJSON.success){
                return (
                    <Alert variant={'success'}>
                        The email has been successfully sent!
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.multipleEmailJSON.error}
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }
    get multipleEmailModalBody(){
        let emails = '';
        for (const email of this.state.selectedUserEmails){
            emails = emails + email + ", ";
        }

        return (
            <Modal.Body>
                {this.multipleEmailModalNotification}
                You can type line-breaks by typing {'<br/>'}.<br/>
                Users: <strong>{emails}</strong><br/>
                <Form className={'mt-3'}>
                    <Form.Group>
                        <Form.Label>Email Subject</Form.Label>
                        <Form.Control onChange={this.handleEmailChange}
                                      type="text" placeholder="Enter email subject line" name={'emailSubject'} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email Body</Form.Label>
                        <Form.Control onChange={this.handleEmailChange}
                                      as="textarea" placeholder={'Enter email body'} name={'emailBody'} rows="3" />
                    </Form.Group>
                    {this.multipleEmailSubmitButton}
                </Form>
            </Modal.Body>
        );
    }
    get multipleEmailModal(){
        return(
            <Modal show={this.state.multipleEmailModalShow} onHide={() => this.setState({multipleEmailModalShow: false})}>
                <Modal.Header closeButton><strong><BsFillEnvelopeFill/> Email Users</strong></Modal.Header>
                {this.multipleEmailModalBody}
            </Modal>
        );
    }

    //*** userDeleteModal Functions
    //  - handleDeleteSubmit
    //*** userDeleteModal Components
    //  - get userDeleteSubmitButton()
    //  - get userDeleteModalNotification()
    //  - get userDeleteModalBody()
    //  - get userDeleteModal() <- Full Component

    handleMultipleDeleteSubmit = (event) => {
        event.preventDefault();
        this.setState({
            multipleDeleteSubmitClicked: true
        });

        let post_body = {
            users: this.state.selectedUserEmails
        };
        let url = '/api/admin/users/delete/multiple';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(data => {this.setState({
            multipleDeleteJSON: data,
            multipleDeleteNotifShow: true,
            multipleDeleteSubmitClicked: false
        })});

        // Show the notif for 3 seconds
        setTimeout(() => {
            this.setState({
                multipleDeleteNotifShow: false,
            })
        }, 3000);
    };

    get multipleDeleteSubmitButton(){
        if (this.state.userDeleteSubmitClicked){
            return (
                <Button className="btn btn-danger float-right mt-2 mb-2" variant="danger" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleMultipleDeleteSubmit} className="btn btn-danger float-right mt-2 mb-2" type="button">
                    Delete User
                </Button>
            );
        }
    }
    get multipleDeleteModalNotification(){
        if (this.state.multipleDeleteNotifShow){
            if (this.state.multipleDeleteJSON.success){
                return (
                    <Alert variant={'success'}>
                        User has been successfully deleted.
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.multipleDeleteJSON.error}
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }
    get multipleDeleteModalBody(){
        let emails = '';
        for (const email of this.state.selectedUserEmails){
            emails = emails + email + ", ";
        }

        return (
            <Modal.Body>
                {this.multipleDeleteModalNotification}
                Users: <strong>{emails}</strong><br/>
                Are you sure you would like to delete these users? This cannot be undone.
                <div className={'mt-2'}>
                    <Button onClick={() => this.setState({multipleDeleteModalShow: false})}
                            className="btn btn-secondary mt-2 mb-2" type="button">
                        Cancel
                    </Button>
                    {this.multipleDeleteSubmitButton}
                </div>
            </Modal.Body>
        );
    }
    get multipleDeleteModal(){
        return (
            <Modal show={this.state.multipleDeleteModalShow} onHide={() => this.setState({multipleDeleteModalShow: false})}>
                <Modal.Header closeButton><strong><BsFillPersonDashFill/> Delete Users</strong></Modal.Header>
                {this.multipleDeleteModalBody}
            </Modal>
        );
    }

    //*** userInfoModal Functions
    //  - userInfoModalAllergiesParse(full_user_info)
    //  - userInfoModalPreferencesParse(full_user_info)
    //*** UserInfoModal Components
    //  - get userInfoModalBody()
    //  - get userInfoModal() <- Full Component
    get userInfoModalView()
    {
        if (this.state.userInfoModalShow)
        {
            let user = this.state.usersInfoJSON.user_login.find(element => element.email === this.state.selectedUserEmail);
            let full_user_info = null;

            if (user.role !== 'admin')
            {
                full_user_info = this.state.usersInfoJSON.users.find(element => element.user_info.email === this.state.selectedUserEmail);
            }

            return (
                <UserInfoModal
                    user={user}
                    full_user_info={full_user_info}
                />
            );
        }
    }
    get userInfoModal(){
        return (
            <Modal show={this.state.userInfoModalShow} onHide={() => this.setState({userInfoModalShow: false})}>
                <Modal.Header closeButton><strong><BsFillPersonLinesFill/> User Information</strong></Modal.Header>
                {this.userInfoModalView}
            </Modal>
        );
    }

    //*** syncTimeModal Functions
    //  - handleSyncSubmit;
    //*** syncTimeModal Components
    //  - get syncTimeModalNotification()
    //  - get syncTimeSubmitButton()
    //  - get syncTimeModalBody()
    //  - get syncTimeModal() <- Full Component

    handleSyncSubmit = (event) => {
        event.preventDefault();
        this.setState({
            syncTimeSubmitClicked: true
        });

        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI("/api/admin/sync/timestamps?account_hash=" + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            syncTimeJSON: data,
            syncTimeNotifShow: true,
            syncTimeSubmitClicked: false
        })});

        // Show the notif for 3 seconds
        setTimeout(() => {
            this.setState({
                syncTimeNotifShow: false,
            })
        }, 3000);
    };
    handleSyncCurrentYearSubmit = (event) => {
        event.preventDefault();
        this.setState({
            syncCurrentYearSubmitClicked: true
        });

        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI("/api/admin/sync/years?account_hash=" + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            syncTimeJSON: data,
            syncTimeNotifShow: true,
            syncCurrentYearSubmitClicked: false
        })});

        // Show the notif for 3 seconds
        setTimeout(() => {
            this.setState({
                syncTimeNotifShow: false,
            })
        }, 3000);
    };
    handleSyncAddressesSubmit = (event) => {
        event.preventDefault();
        this.setState({
            syncAddressesSubmitClicked: true
        });

        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI("/api/admin/sync/addresses?account_hash=" + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            syncTimeJSON: data,
            syncTimeNotifShow: true,
            syncAddressesSubmitClicked: false
        })});

        // Show the notif for 3 seconds
        setTimeout(() => {
            this.setState({
                syncTimeNotifShow: false,
            })
        }, 3000);
    };

    handleResetMealPlanCashSubmit = (event) => {
        event.preventDefault();
        this.setState({
            resetMealPlanCashClicked: true
        });

        let userDeleteTracker = this.state.userDeleteTracker;
        ++userDeleteTracker;

        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI("/api/admin/sync/mealPlanCash?account_hash=" + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            syncTimeJSON: data,
            syncTimeNotifShow: true,
            userDeleteTracker: userDeleteTracker,
            resetMealPlanCashClicked: false
        })});

        // Show the notif for 3 seconds
        setTimeout(() => {
            this.setState({
                syncTimeNotifShow: false,
            })
        }, 3000);
    };

    get syncTimeModalNotification(){
        if (this.state.syncTimeNotifShow){
            if (this.state.syncTimeJSON.success){
                return (
                    <Alert variant={'success'}>
                        The collections have been successfully synchronized.
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.syncTimeJSON.error}
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }
    get syncTimeSubmitButton(){
        if (this.state.syncTimeSubmitClicked){
            return (
                <Button className="btn btn-primary mt-2 mb-2" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleSyncSubmit} className="btn btn-primary mt-2 mb-2" type="button">
                    Synchronize collections
                </Button>
            );
        }
    }
    get syncCurrentYearButton(){
        if (this.state.syncCurrentYearSubmitClicked){
            return (
                <Button className="btn btn-primary mt-2 mb-2" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleSyncCurrentYearSubmit} className="btn btn-primary mt-2 mb-2" type="button">
                    Synchronize years collection
                </Button>
            );
        }
    }
    get syncAddressesButton(){
        if (this.state.syncAddressesSubmitClicked){
            return (
                <Button className="btn btn-primary mt-2 mb-2" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleSyncAddressesSubmit} className="btn btn-primary mt-2 mb-2" type="button">
                    Synchronize addresses collection
                </Button>
            );
        }
    }

    /**
     * Reset Meal Plan Cash Button
     * @returns {*} reset meal plan cash Butoon
     */
    get resetMealPlanCashButton()
    {
        if (this.state.resetMealPlanCashClicked){
            return (
                <Button className="btn btn-primary mt-2 mb-2" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.handleResetMealPlanCashSubmit} className="btn btn-primary mt-2 mb-2" type="button">
                    Reset all meal plan cash
                </Button>
            );
        }
    }

    get syncTimeModalBody(){
        return (
            <Modal.Body>
                {this.syncTimeModalNotification}
                The below button synchronizes all of the creation timestamps between the 'users' and 'user_login' collections. If they are already synchronized, nothing will happen.
                <div className={'mt-2 mb-2'}>
                    {this.syncTimeSubmitButton}
                </div>
                The below button synchronizes all of the members in the database to be a part of the current year. Only use this once or to clean up something. If used when people
                belong to different years, this will break that.
                <div className={'mt-2 mb-2'}>
                    {this.syncCurrentYearButton}
                </div>
                The below button synchronizes addresses to the new type, adding a boolean for in house, in which case the address will only show as house
                (room #). This will synchronize all of them to that format. A function to change single addresses will be coming at a later date.
                <div className="mt-2 mb-2">
                    {this.syncAddressesButton}
                </div>
                The below button resets all user's meal plan cash amount to the correct amount for their meal plan. The current amounts are $80 and $100 for the respective meal plans.
                <div className="mt-2 mb-2">
                    {this.resetMealPlanCashButton}
                </div>
            </Modal.Body>
        );
    }
    get syncTimeModal(){
        return(
            <Modal show={this.state.syncTimeModalShow} onHide={() => this.setState({syncTimeModalShow: false})}>
                <Modal.Header closeButton><strong><BsArrowRepeat/> Database Utilities</strong></Modal.Header>
                {this.syncTimeModalBody}
            </Modal>
        );
    }

    //*** userPage Components
    //  - get userPageTableData()
    //  - get userPageTable()
    //  - get userInteractionModals()

    pushPopEmailToSelected(email){
        let selectedUserEmails = this.state.selectedUserEmails;

        if (selectedUserEmails.includes(email)){
            selectedUserEmails = selectedUserEmails.filter(curr_email => curr_email !== email);
        }
        else {
            selectedUserEmails.push(email);
        }

        this.setState({
            selectedUserEmails: selectedUserEmails
        });
    }

    selectAll(){
        let selectedUserEmails = [];

        for (const element of this.state.usersInfoJSON.user_login){
            selectedUserEmails.push(element.email);
        }

        this.setState({
            selectedUserEmails: selectedUserEmails
        });
    }

    //ButtonStyle
    buttonStyle(){
        return {cursor: 'pointer'};
    }

    get userPageTableData(){
        let buffer = [];

        for (const element of this.state.usersInfoJSON.user_login){
            let elementSelected = this.state.selectedUserEmails.includes(element.email);

            let user_meal_plan_cash = this.state.usersInfoJSON.user_meal_plan_cash.find(curr => curr.email === element.email);

            buffer.push(
                <tr key={element.email} className={elementSelected ? 'table-primary' : ''}>
                    <td onClick={() => this.pushPopEmailToSelected(element.email)}
                        style={this.buttonStyle()}>{elementSelected ? (<BsCheckCircle/>) : (<BsCircle/>)}</td>
                    <td>{element.email}</td>
                    <td>{element.role}</td>
                    <td>{element.creation_datetime}</td>
                    <td>{user_meal_plan_cash != null ? '$' + user_meal_plan_cash.amount : 'N/A'}</td>
                    <td>
                        <ButtonGroup className={"mr-2 float-right"}>
                            <Button onClick={() => this.showModal('userInfoModalShow', element.email)} variant={'primary'}><BsFillPersonLinesFill/></Button>
                            <Button onClick={() => this.showModal('userEmailModalShow', element.email)} variant={'secondary'}><BsFillEnvelopeFill/></Button>
                            <Button onClick={() => this.showModal('userDeleteModalShow', element.email)} variant={'danger'}><BsFillPersonDashFill/></Button>
                        </ButtonGroup>
                    </td>
                </tr>);
        }

        return (
            <tbody>
            {buffer}
            </tbody>
        );
    }
    get userPageTable(){
        return (
            <Table striped hover responsive className={"pt-0 mt-0 mb-0"}>
                <thead>
                <tr>
                    <th> </th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Creation Datetime</th>
                    <th>Meal Plan Cash</th>
                    <th><div className={"float-right"}>Actions</div></th>
                </tr>
                </thead>
                {this.userPageTableData}
            </Table>
        );
    }
    get userInteractionModals(){
        return(
            <div>
                {this.addAdminModal}
                {this.userDeleteModal}
                {this.userEmailModal}
                {this.userInfoModal}
                {this.syncTimeModal}
                {this.multipleEmailModal}
                {this.multipleDeleteModal}
                {this.changePasswordModal}
            </div>
        );

    }

    get changePasswordModal(){
        return (
            <Modal show={this.state.changePasswordModalShow} onHide={() => {this.setState({changePasswordModalShow: false})}}>
                <Modal.Header closeButton><strong>Change User Password</strong></Modal.Header>
                <Modal.Body>
                    Please don't touch this. Can change anybody's password without validation and without checking if the email exists.
                    <Form className={'mt-3'}>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>User</Form.Label>
                                <Form.Control required type="email" placeholder="Enter email address"
                                              name="changeEmail"
                                              onChange={this.handleChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>New Password</Form.Label>
                                <Form.Control required type="password" placeholder="Enter password"
                                              name="changePassword"
                                              onChange={this.handleChange}
                                />
                            </Form.Group>
                        </Form.Row>
                        {this.changePasswordSubmitButton}
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    get changePasswordSubmitButton(){
        if (this.state.changePasswordSubmitButton){
            return (
                <Button className="btn btn-danger float-right mt-2 mb-2" variant="danger" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.onChangePasswordSubmit} className="btn btn-danger float-right mt-2 mb-2" type="button">
                    Change Password
                </Button>
            );
        }
    }

    onChangePasswordSubmit = (event) => {
        event.preventDefault();
        this.setState({
            changePasswordSubmitButton: true
        });

        let post_body = {
            user: this.state.changeEmail,
            password: this.state.changePassword
        };
        let url = '/api/admin/users/password/change';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then((data) => {this.setState({
            changePasswordSubmitButton: false,
        })});

        setTimeout(() => {
            this.setState({
                changePasswordModalShow: false
            });
        }, 3000);
    };

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    };

    get bulkActionsButtons(){
        let selected_title = (
            <div className={'d-inline-block'}>
                {this.state.selectedUserEmails.length} <BsFillPeopleFill/> selected
            </div>
        );

        let buttonsDisabled = this.state.selectedUserEmails.length === 0;

        return(
            <div className={'float-right'}>
                <ButtonToolbar aria-label="Toolbar with button groups">
                    <ButtonGroup className="mr-2" aria-label="First group">
                        <DropdownButton as={ButtonGroup} title={selected_title} id="bg-nested-dropdown" variant={'primary'}>
                            <DropdownItem eventKey="1" onClick={() => this.selectAll()}><BsCheckAll/> Select All</DropdownItem>
                            <DropdownItem eventKey="2" onClick={() => this.setState({selectedUserEmails: []})}><BsCircle/> Deselect All</DropdownItem>
                        </DropdownButton>
                        {/*<Button>{this.state.selectedUserEmails.length} <BsFillPeopleFill/> selected</Button>*/}
                    </ButtonGroup>
                    <ButtonGroup className="mr-2" aria-label="Second group">
                        <Button className={buttonsDisabled ? 'disabled' : ''} onClick={() => {if (!buttonsDisabled){this.setState({multipleEmailModalShow: true});}}} variant={'secondary'}><BsFillEnvelopeFill/></Button>
                        <Button className={buttonsDisabled ? 'disabled' : ''} onClick={() => {if (!buttonsDisabled){this.setState({multipleDeleteModalShow: true});}}} variant={'danger'}><BsFillPersonDashFill/></Button>
                    </ButtonGroup>
                    <ButtonGroup aria-label="Third group" className={'float-right'}>
                        <DropdownButton as={ButtonGroup} title={(<BsFillGearFill/>)} id="bg-nested-dropdown" variant={'secondary'}>
                            <DropdownItem eventKey="1" onClick={() => this.setState({addAdminModalShow: true})}><BsFillPersonPlusFill/> Manage Admin</DropdownItem>
                            <DropdownItem eventKey="2" onClick={() => this.setState({syncTimeModalShow: true})}><BsArrowRepeat/> Database Utilities</DropdownItem>
                            <DropdownItem eventKey="2" onClick={() => this.setState({changePasswordModalShow: true})}><BsFillPersonPlusFill/> Change User Password</DropdownItem>
                        </DropdownButton>
                    </ButtonGroup>
                </ButtonToolbar>
            </div>
        );
    }

    //*** render function
    render(){
        if (this.props.currentTab !== 2){
            return null;
        }

        if (this.state.usersInfoJSON == null){
            //Return a spinner until it does not.
            return(
                <div className={"bk_text"}>
                    <Card className={'mt-3 mb-3'}>
                        <Card.Header><BsFillPeopleFill/> User Management</Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
        else {
            return (
                <div className={"bk_text"}>
                    <h1 className={"bk_header mt-3"}> Users </h1>
                    {this.userInteractionModals}
                    <Card className={'mt-3 mb-3'}>
                        <Card.Header>
                            <div className={'my-auto'}>
                                <BsFillPeopleFill/> Users
                                {this.bulkActionsButtons}
                            </div>
                        </Card.Header>
                        {this.userPageTable}
                    </Card>
                </div>
            );
        }
    }

}
