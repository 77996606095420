import React, {Component} from "react";
import {Alert, Card, Form, Col} from "react-bootstrap";
import {legalAgreement} from "../../utils/Consts";

export default class RegistrationStep4 extends Component {

    constructor(props) {
        super(props);

        this.handleIsValid = this.handleIsValid.bind(this);
        this.handleChangeSignature = this.handleChangeSignature.bind(this);
        this.handleChangeParentSignature = this.handleChangeParentSignature.bind(this)
    }

    handleIsValid(fieldName){
        return this.props[fieldName] != null ? (this.props[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }

    handleChangeParentSignature(event){
        const {value} = event.target;
        let signaturePattern = /^[a-zA-z\s,.]{2,}$/;
        if (value.length === 0){
            this.props.handlePropsChange('parentSignatureValid', false);
        }
        else if (signaturePattern.test(value)){
            this.props.handlePropsChange('parentSignatureValid', true);
            this.props.handleChange(event);
        }
        else{
            this.props.handlePropsChange('parentSignatureValid', false);
        }
    }

    handleChangeSignature(event){
        const {value} = event.target;
        let signaturePattern = /^[a-zA-z\s,.]{2,}$/;
        if (value.length === 0){
            this.props.handlePropsChange('signatureValid', false);
        }
        else if (signaturePattern.test(value)){
            this.props.handlePropsChange('signatureValid', true);
            this.props.handleChange(event);
        }
        else{
            this.props.handlePropsChange('signatureValid', false);
        }
    }

    get unvalidatedAlert(){
        if (this.props.form3Validated != null){
            if (this.props.form3Validated === false){
                return (
                    <Alert variant='danger'>
                        Please fill out your electronic signature below.
                    </Alert>
                );
            }
        }
        return null;
    }

    render() {
        if (this.props.currentStep !== 4){
            return null;
        }

        return (
            <div>
                {this.unvalidatedAlert}
                <Card className="bk_text">
                    <Card.Header className="bk_header">
                        <strong>Account Registration (4/5)</strong>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title className="bk_header">
                            Terms & Conditions
                        </Card.Title>
                        <Card.Text>
                            <div className="overflow-auto">
                                {legalAgreement()}
                            </div>
                            <Form align>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>*Brother Signature</Form.Label>
                                        <Form.Control required type="text" placeholder="Type full legal name"
                                                      name = "signature"
                                                      className = {this.handleIsValid('signatureValid')}
                                                      onChange = {this.handleChangeSignature}/>
                                        <Form.Control.Feedback type="invalid">Please enter your electronic signature.</Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>*Parent/Guardian Signature</Form.Label>
                                        <Form.Control required type="text" placeholder="Type parent/guardian's full legal name"
                                                      name = "parentSignature"
                                                      className = {this.handleIsValid('parentSignatureValid')}
                                                      onChange = {this.handleChangeParentSignature}/>
                                        <Form.Control.Feedback type="invalid">Parent/Guardian: Please enter your electronic signature.</Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                            Today's date will be added automatically to this form.<br/><br/>
                            Fields marked with an * are required.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        We commit and promise to not share any of your personal information with any other third parties.
                    </Card.Footer>
                </Card>
            </div>
        );
    }

}