import React, {Component} from "react";
import {Card, Spinner} from "react-bootstrap";
import {BsFillPersonPlusFill} from "react-icons/all";

export default class AdminRegistrationStep1 extends Component{

    render(){
        if (this.props.currentTab !== 1){
            return null;
        }

        return(
            <Card className={"bk_text"}>
                <Card.Header>
                    <BsFillPersonPlusFill/> Admin Registration
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                        Validating admin registration code...
                    </Card.Title>
                    <Card.Text>
                        Stand by while we validate your admin registration code!
                    </Card.Text>
                    <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </div>
                </Card.Body>
            </Card>
        );
    }

}