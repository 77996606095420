import React, {Component} from 'react';
import {Alert, Card, Spinner} from "react-bootstrap";
import {FaGavel} from "react-icons/all";
import {legalAgreement} from "../../utils/Consts";

export default class UserPageLegal extends Component {

    constructor(props){
        super(props);

        this.state = {
            userInfoJSON: null
        };
    }

    updateUserInfoJSON(){
        // Load admin information
        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI("/api/account?account_hash=" + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            userInfoJSON: data
        })});
    }
    componentDidMount(){
        this.updateUserInfoJSON();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentTab !== 3 && this.props.currentTab === 3){
            this.updateUserInfoJSON();
        }
    }

    get legalStatement(){
            return (
                <Card.Text>
                    {legalAgreement()}
                    Signed by: <strong>{this.state.userInfoJSON.user_info.signature}</strong> & <strong>{this.state.userInfoJSON.parent_info.parentSignature}</strong>
                    <br/>
                    Signed on: <strong>{this.state.userInfoJSON.creation_datetime} UTC</strong>
                </Card.Text>
            );
    }


    render(){
        if (this.props.currentTab !==3){
            return null;
        }

        if (this.state.userInfoJSON == null){
            return(
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }
        else {
            return (
                <div>
                    <Card className={"mt-3 mb-3"}>
                        <Card.Header><FaGavel/> Legal & Payment</Card.Header>
                        <Card.Body>
                            <Card.Title>Payment Information</Card.Title>
                            <Card.Text className={"mb-4"}>
                                Payment will be billed through Billhighway with your rent payment.
                                <br/>
                            </Card.Text>
                            <Card.Title>Full Legal Agreement</Card.Title>
                            {this.legalStatement}
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }

}