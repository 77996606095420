import React, {useEffect, useState} from "react";
import {ListGroup, Modal, Row, Col, Button, Spinner, Alert, Card} from "react-bootstrap";
import {BsCheck, BsDashCircle, BsPlusCircle} from "react-icons/all";
import {postUserMenuPick} from "../../utils/ApiHandler";

export default function UserPageMenuPickingV2(props)
{
    const [picksFirst, setPicksFirst] = useState({});
    const [picksSecond, setPicksSecond] = useState({});

    const [submitClicked, setSubmitClicked] = useState(false);

    const [numAssembledMealsPicked, setNumAssembledMealsPicked] = useState(0);
    const [numProteinPicked, setNumProteinPicked] = useState(0);
    const [numCarbsPicked, setNumCarbsPicked] = useState(0);
    const [numVeggiesPicked, setNumVeggiesPicked] = useState(0);
    const [numBreakfastsPicked, setNumBreakfastsPicked] = useState(0);

    const [notifShow, setNotifShow] = useState(false);
    const [notifBody, setNotifBody] = useState(null);

    const [assembledMeals, setAssembledMeals] = useState([]);
    const [proteins, setProteins] = useState([]);
    const [carbs, setCarbs] = useState([]);
    const [veggies, setVeggies] = useState([]);
    const [breakfasts, setBreakfasts] = useState([]);

    const [numPoints, setNumPoints] = useState(0);

    useEffect(() => {
        if (props.menu != null && props.menu.items != null)
        {
            let currPicks = {};

            let newAssembledMeals = [];
            let newProteins = [];
            let newCarbs = [];
            let newVeggies = [];
            let newBreakfasts = [];

            for (const item of props.menu.items)
            {
                currPicks[item.name] = 0;
                switch (item.category)
                {
                    case "Protein":
                        newProteins.push(item);
                        break;
                    case "Carbs":
                        newCarbs.push(item);
                        break;
                    case "Veggie":
                        newVeggies.push(item);
                        break;
                    case "Breakfast":
                        newBreakfasts.push(item);
                        break;
                    case "Assembled Meal":
                        newAssembledMeals.push(item);
                        break;
                }
            }

            setAssembledMeals(newAssembledMeals);
            setProteins(newProteins);
            setCarbs(newCarbs);
            setVeggies(newVeggies);
            setBreakfasts(newBreakfasts);

            setPicksFirst(currPicks);
            setPicksSecond(currPicks);

        }
    }, [props.menu]);

    function addToFirst(name, add, numAllowed, points)
    {
        let currPicks = {};

        let currNumPicks = numPoints;
        let setter = setNumPoints;

        console.log(currNumPicks);

        // switch(category)
        // {
        //     case "assembledMeal":
        //         currNumPicks = numAssembledMealsPicked;
        //         setter = setNumAssembledMealsPicked;
        //         break;
        //     case "protein":
        //         currNumPicks = numProteinPicked;
        //         setter = setNumProteinPicked;
        //         break;
        //     case "carb":
        //         currNumPicks = numCarbsPicked;
        //         setter = setNumCarbsPicked;
        //         break;
        //     case "veggies":
        //         currNumPicks = numVeggiesPicked;
        //         setter = setNumVeggiesPicked;
        //         break;
        //     case "breakfast":
        //         currNumPicks = numBreakfastsPicked;
        //         setter = setNumBreakfastsPicked;
        //         break;
        // }

        for (const property in picksFirst)
        {
            if (property === name)
            {
                if (add)
                {
                    if (currNumPicks + points <= numAllowed)
                    {
                        currPicks[property] = picksFirst[property] + 1;
                        currNumPicks += points;
                    }
                    else
                    {
                        currPicks[property] = picksFirst[property];
                    }
                }
                else
                {
                    if (picksFirst[property] - 1 >= 0)
                    {
                        currPicks[property] = picksFirst[property] - 1;
                        currNumPicks -= points;
                    }
                    else
                    {
                        currPicks[property] = 0;
                    }
                }
            }
            else
            {
                currPicks[property] = picksFirst[property];
            }
        }

        setter(currNumPicks);
        setPicksFirst(currPicks);
    }

    function addToSecond(name, add, numAllowed, points)
    {
        let currPicks = {};

        let currNumPicks = numPoints;
        let setter = setNumPoints;

        // switch(category)
        // {
        //     case "assembledMeal":
        //         currNumPicks = numAssembledMealsPicked;
        //         setter = setNumAssembledMealsPicked;
        //         break;
        //     case "protein":
        //         currNumPicks = numProteinPicked;
        //         setter = setNumProteinPicked;
        //         break;
        //     case "carb":
        //         currNumPicks = numCarbsPicked;
        //         setter = setNumCarbsPicked;
        //         break;
        //     case "veggies":
        //         currNumPicks = numVeggiesPicked;
        //         setter = setNumVeggiesPicked;
        //         break;
        //     case "breakfast":
        //         currNumPicks = numBreakfastsPicked;
        //         setter = setNumBreakfastsPicked;
        //         break;
        // }

        for (const property in picksSecond)
        {
            if (property === name)
            {
                if (add)
                {
                    if (currNumPicks + points <= numAllowed)
                    {
                        currPicks[property] = picksSecond[property] + 1;
                        currNumPicks += points;
                    }
                    else
                    {
                        currPicks[property] = picksSecond[property];
                    }
                }
                else
                {
                    if (picksSecond[property] - 1 >= 0)
                    {
                        currPicks[property] = picksSecond[property] - 1;
                        currNumPicks -= points;
                    }
                    else
                    {
                        currPicks[property] = 0;
                    }
                }
            }
            else
            {
                currPicks[property] = picksSecond[property];
            }
        }

        setter(currNumPicks);
        setPicksSecond(currPicks);
    }

    function foodList(list, title, titleClassName, numAllowed, category)
    {
        let buffer = [];

        for (const item of list)
        {
            let ingredientsString = item.ingredients;

            let points = parseInt(item.points);

            buffer.push(
                <ListGroup.Item variant='' className='text-dark' key={item.name}>
                    <Row xs={1} sm={3} md={3} lg={3} className="mt-2 mb-2">
                        <Col>
                            <h3>{item.name}</h3>
                            <p>{ingredientsString}</p>
                            <h4>{item.points} points</h4>
                        </Col>
                        <Col className="text-center">
                            <h4>First Delivery</h4>
                            <p>(Monday)</p>
                            <h2 className={picksFirst[item.name] > 0 ? 'text-success' : 'text-dark'}>
                                <Button variant='' onClick={() => addToFirst(item.name, false, numAllowed, points)}><BsDashCircle size={23}/></Button>
                                {' '} {picksFirst[item.name]} {' '}
                                <Button variant='' onClick={() => addToFirst(item.name, true, numAllowed, points)}><BsPlusCircle size={23}/></Button>
                            </h2>
                        </Col>
                        <Col className="text-center">
                            <h4>Second Delivery</h4>
                            <p>(Thursday)</p>
                            <h2 className={picksSecond[item.name] > 0 ? 'text-success' : 'text-dark'}>
                                <Button variant='' onClick={() => addToSecond(item.name, false, numAllowed, points)}><BsDashCircle size={23}/></Button>
                                {' '} {picksSecond[item.name]} {' '}
                                <Button variant='' onClick={() => addToSecond(item.name, true, numAllowed, points)}><BsPlusCircle size={23}/></Button>
                            </h2>
                        </Col>
                    </Row>
                </ListGroup.Item>
            );
        }

        let currNumPicks = 0;

        switch(category)
        {
            case "assembledMeal":
                currNumPicks = numAssembledMealsPicked;
                break;
            case "protein":
                currNumPicks = numProteinPicked;
                break;
            case "carb":
                currNumPicks = numCarbsPicked;
                break;
            case "veggies":
                currNumPicks = numVeggiesPicked;
                break;
            case "breakfast":
                currNumPicks = numBreakfastsPicked;
                break;
        }

        let check = currNumPicks === numAllowed ? (<BsCheck/>) : (<></>);

        let adder = null;

        return (
            <div className="mt-3">
                <div className={titleClassName}><h3>{title}</h3></div>
                {adder}
                {buffer}
            </div>
        );
    }

    function validate()
    {
        let valid = true;
        console.log('numPoints: ' + numPoints + ', numPointsAllowed: ' + props.numPointsAllowed);
        valid &= (props.numPointsAllowed == numPoints);

        console.log('valid: ' + valid);
        return valid;
    }

    const onSubmit = () => {
        setSubmitClicked(true);

        if (validate())
        {
            let body = {
                picksFirst: picksFirst,
                picksSecond: picksSecond,
                menuName: props.menu.name
            };

            postUserMenuPick(body).then(data => {
                setSubmitClicked(false);
                setNotifShow(true);
                setNotifBody(data.error || 'Success!');
            });

            setTimeout(() => {
                setNotifShow(false);
                props.updateChangeTracker();
                props.closeModal();
            }, 3000);
        }
        else
        {
            setSubmitClicked(false);
            setNotifShow(true);
            setNotifBody("Make sure to use all of your points!");
        }
    };

    function points()
    {
        return (
            <Card bg="primary" className="mt-2">
                <Card.Body>
                    <h3 className="text-white text-center">{numPoints} / {props.numPointsAllowed} points used</h3>
                </Card.Body>
            </Card>
        );
    }

    function notification()
    {
        if (notifShow)
        {
            if (notifBody !== 'Success!'){
                return (
                    <Alert variant={'danger'} className="mt-2">
                        ERROR: {notifBody}
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'success'} className="mt-2">
                        {notifBody} : Your picks have been added.
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }

    function submitButton(){
    if (submitClicked){
        return (
            <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                <span className="sr-only">Loading...</span>
            </Button>
        );
    }
    else {
        return (
            <Button onClick={onSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                Submit My Picks
            </Button>
        );
    }
}

    if (props.menu == null)
    {
        return (
            <Modal.Body className="text-center">
                <h4>Uh oh!</h4>
                <p>
                    Something went wrong. Please try again later.
                </p>
            </Modal.Body>
        );
    }



    return (
        <Modal.Body>
            {notification()}
            All measurements are in servings except for assembled meals. Assembled meals are 2 servings per meal, one protein and one carb.
            {points()}
            {foodList(assembledMeals, "Assembled Meals", "text-secondary",props.numPointsAllowed, "assembledMeal")}
            {foodList(proteins, "Protein Items", "text-primary",props.numPointsAllowed, "protein")}
            {foodList(carbs.concat(veggies), "Carbs & Veggie Items", "text-warning", props.numPointsAllowed, "carb")}
            {/*{foodList(props.menu.veggies, "Veggies", "text-success", 1, "veggies")}*/}
            {foodList(breakfasts, "Breakfast Items", "text-danger", props.numPointsAllowed, "breakfast")}
            {notification()}
            {points()}
            {submitButton()}
        </Modal.Body>
    );
}