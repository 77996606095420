import React, {Component} from "react";
import {Alert, Button, Col, Form, ListGroup, Modal, Row, Spinner} from "react-bootstrap";

export default class UserPageMenuSnackPicking extends Component {

    constructor(props){
        super(props);

        this.state = {
            snackPicks: [],

            totalAmount: 0,
            mealPlanCash: this.props.mealPlanCash,

            submitClicked: false,
            notifShow: false,
            notifBody: ''
        };
    }

    componentDidMount(){
        let snackBuffer = [];
        for (let i = 0; i < this.props.snacks.length; ++i){
            snackBuffer.push(0);
        }

        this.setState({
            snackPicks: snackBuffer
        })
    }

    onChange(category, index, value){
        let categoryPicks = this.state[category];
        categoryPicks[index] = parseInt(value);

        this.setState({
            [category]: categoryPicks
        });
    }

    get snackPicking(){
        let formBuffer = [];

        for (let i = 0; i < this.props.snacks.length; ++i){

            formBuffer.push(
                <Form.Row className={'mt-3'} key={'protein' + i}>
                    <Col xs={2}>
                        <Form.Control required min={0} type="number" placeholder="0"
                                      name={'snack' + i}
                                      onChange={(event) => {
                                          this.onChange('snackPicks', i, event.target.value);
                                      }}
                        />
                    </Col>
                    <Col>
                        <ListGroup.Item key={this.props.snacks[i].name}>
                            <Row>
                                <Col sm={2}>${parseInt(this.props.snacks[i].price).toFixed(2)}</Col>
                                <Col><strong>{this.props.snacks[i].name}:</strong> {this.props.snacks[i].description}</Col>
                            </Row>
                        </ListGroup.Item>
                    </Col>
                </Form.Row>
            );
        }

        return (
            <div className={'mt-3 mb-3'}>
                <div className={'text-primary'}><h5>Snacks</h5></div>
                {formBuffer}
            </div>
        );
    }

    total(){
        let total = 0;
        for (let i = 0; i < this.state.snackPicks.length; ++i){
            total += this.state.snackPicks[i] * parseInt(this.props.snacks[i].price)
        }
        return total;
    }

    get notification(){
        if (this.state.notifShow){
            if (this.state.notifBody !== 'Success!'){
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.notifBody}
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'success'}>
                        {this.state.notifBody} : Your picks have been added.
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }

    get submitButton(){
        if (this.state.submitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.onSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Submit My Picks
                </Button>
            );
        }
    }

    validatePicks(){
        let snacksNum = this.state.snackPicks.reduce((acc, curr) => acc + curr, 0);
        let total = this.total();

        return snacksNum > 0 && total <= this.props.mealPlanCash && total > 0;
    }


    onSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitClicked: true
        });
        if (this.validatePicks()){
            let post_body = {
                user: localStorage.getItem('user'),
                quarter: this.props.quarter,
                week: this.props.week,
                snackPicks: this.state.snackPicks
            };
            let url = '/api/user/menu/snacks/pick';

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'account-hash': localStorage.getItem('hashed_login_key'),
                    'user': localStorage.getItem('user')
                },
                body: JSON.stringify(post_body)
            }).then(res => res.json()).then((data) => {this.setState({
                submitClicked: false,
                notifShow: true,
                notifBody: data.error || 'Success!'
            })});

            setTimeout(() => {
                this.setState({
                    notifShow: false
                });
                this.props.updateChangeTracker();
                this.props.hideSnackPickingModal();
            }, 2000);
        }
        else {
            this.setState({
                submitClicked: false,
                notifShow: true,
                notifBody: 'Form not validated. Check all of your inputs and try again. You cannot submit if you did not pick any snacks.'
            });
        }

    };

    render(){
        return (
            <Modal.Body>
                {this.notification}
                Meal plan cash can be used to buy gift cards for the current quarter. Gift cards can only
                be picked during the first week of the quarter, so make sure to use it all!<br/><br/>
                Be certain before you hit submit! Editing of snacks is not currently allowed.
                <Row className={'mt-4'}>
                    <Col className={'text-center'}>
                        <h4 className={'text-danger'}><strong>${this.total().toFixed(2)}</strong></h4>
                    </Col>
                    <Col>
                        Current Picks Add To
                    </Col>
                </Row>
                <Row className={'mt-2 mb-2'}>
                    <Col className={'text-center'}>
                        <h4 className={'text-success'}><strong>${(this.props.mealPlanCash - this.total()).toFixed(2)}</strong></h4>
                    </Col>
                    <Col>
                        Your Meal Plan Cash Will Be At
                    </Col>
                </Row>
                <Form>
                    {this.snackPicking}
                    {this.submitButton}
                </Form>
            </Modal.Body>
        );
    }
}