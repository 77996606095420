import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row, Spinner, Table} from "react-bootstrap";
import {BsArrowRight} from "react-icons/all";
import {
    getAdminGiftCardMenuNames,
    getMenuNamesAdmin,
    postAdminGetPicks,
    postAdminGiftCardPicks
} from "../../utils/ApiHandler";

export default function AdminPagePicksV2(props)
{
    const [picksData, setPicksData] = useState(null);
    const [totalsFirst, setTotalsFirst] = useState(null);
    const [totalsSecond, setTotalsSecond] = useState(null);
    const [usersNotPicked, setUsersNotPicked] = useState(null);
    const [userInfo, setUserInfo] = useState(null);

    const [menuNames, setMenuNames] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);

    const [giftCardMenuNames, setGiftCardMenuNames] = useState(null);
    const [selectedGiftCardMenu, setSelectedGiftCardMenu] = useState(null);

    const [giftCardPicksData, setGiftCardPicksData] = useState(null);
    const [totalsGiftCards, setTotalsGiftCards] = useState(null);
    const [usersNotPickedGiftCards, setUsersNotPickedGiftCards] = useState(null);
    const [userInfoGiftCards, setUserInfoGiftCards] = useState(null);

    useEffect(() => {
        getMenuNamesAdmin().then(result => {
            if (result.success)
            {
                console.log(result);
                setMenuNames(result.menu_names);
            }
        });
        getAdminGiftCardMenuNames().then(result => {
            if (result.success)
            {
                console.log(result);
                setGiftCardMenuNames(result.menu_names);
            }
        });
    }, []);

    if (props.currentTab !== 7)
    {
        return (
            <></>
        );
    }

    const onGo = () => {
        console.log(selectedMenu);

        let data = {'menu_name': selectedMenu};
        postAdminGetPicks(data).then(result => {
            if (result.success)
            {
                console.log(result);

                setPicksData(result.picks);
                setTotalsFirst(result.totals_first);
                setTotalsSecond(result.totals_second);
                setUsersNotPicked(result.not_picked);
                setUserInfo(result.user_info);
            }
        });
    };

    const onGoGiftCard = () => {
        console.log(selectedGiftCardMenu);

        let data  = {'menu_name': selectedGiftCardMenu};
        postAdminGiftCardPicks(data).then(result => {
            if (result.success)
            {
                console.log(result);

                setGiftCardPicksData(result.picks);
                setTotalsGiftCards(result['totals']);
                setUsersNotPickedGiftCards(result.not_picked);
                setUserInfoGiftCards(result.user_info);
            }
        });
    };

    function tables()
    {
        if (picksData == null || usersNotPicked == null || userInfo == null)
        {
            return (
                <div className="mt-3">
                    Picks null.
                </div>
            );
        }

        return (
            <div className="mt-3">
                {totalPicksTables()}
                {picksTables()}
            </div>
        );
    }

    function totalPicksTables()
    {
        let headerBuffer = [];
        let numBufferFirst = [];
        let numBufferSecond = [];

        headerBuffer.push(<th key={'delivery'}>Delivery</th>);
        numBufferFirst.push(<td key={'first'}><b>First</b></td>);
        numBufferSecond.push(<td key={'second'}><b>Second</b></td>);

        let i = 0;
        for (const key in totalsFirst)
        {
            headerBuffer.push(<th key={key + (++i)}>{key}</th>);
            numBufferFirst.push(<td key={key + (++i)}>{totalsFirst[key]}</td>);
            numBufferSecond.push(<td key={key + (++i)}>{totalsSecond[key]}</td>);
        }

        return (
            <>
                <h4>Totals</h4>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        {headerBuffer}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {numBufferFirst}
                    </tr>
                    <tr>
                        {numBufferSecond}
                    </tr>
                    </tbody>
                </Table>
            </>
        );
    }

    function picksTables()
    {
        let headerBuffer = [];
        headerBuffer.push(<th key={0}>Email</th>);
        headerBuffer.push(<th key={1}>User</th>);
        headerBuffer.push(<th key={2}>Address</th>);

        let i = 0;
        for (const key in totalsFirst)
        {
            headerBuffer.push(<th key={key + (++i)}>{key}</th>);
        }

        let picksFirstBuffer = [];
        let picksSecondBuffer = [];

        for (const email in userInfo)
        {
            let rowFirst = [];
            let rowSecond = [];

            let picks = picksData.find(element => element.email === email);

            // picksFirst
            rowFirst.push(<td key={0}>{email}</td>);
            rowFirst.push(<td key={1}>{userInfo[email]['fullName']}</td>);
            rowFirst.push(<td key={2}>{userInfo[email]['addressLine']}</td>);

            rowSecond.push(<td key={0}>{email}</td>);
            rowSecond.push(<td key={1}>{userInfo[email]['fullName']}</td>);
            rowSecond.push(<td key={2}>{userInfo[email]['addressLine']}</td>);

            if (picks !== undefined)
            {
                for (const key in picks.picksFirst)
                {
                    rowFirst.push(<td key={key + (++i)}>{picks.picksFirst[key]}</td>);
                    rowSecond.push(<td key={key + (++i)}>{picks.picksSecond[key]}</td>);
                }
            }

            picksFirstBuffer.push(<tr key={i}>{rowFirst}</tr>);
            picksSecondBuffer.push(<tr key={i}>{rowSecond}</tr>);
        }

        return (
            <>
                <h4>Individuals First Delivery</h4>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        {headerBuffer}
                    </tr>
                    </thead>
                    <tbody>
                    {picksFirstBuffer}
                    </tbody>
                </Table>
                <h4 className='mt-3'>Individuals Second Delivery</h4>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        {headerBuffer}
                    </tr>
                    </thead>
                    <tbody>
                    {picksSecondBuffer}
                    </tbody>
                </Table>
            </>
        );
    }

    function menuPicker()
    {
        if (menuNames == null)
        {
            return (
                <Spinner animation="border" role="status" variant="dark"/>
            );
        }
        else if (menuNames.length === 0)
        {
            return (
                <p>No menus found to query for.</p>
            );
        }
        else
        {
            let buffer = [];

            for (const elem of menuNames)
            {
                buffer.push(<option value={elem} key={elem}>{elem}</option>)
            }

            return (
                <div className="mt-3">
                    <Form>
                        <Row>
                            <Col>
                                <Form.Control
                                    as="select"
                                    onChange={(event) => setSelectedMenu(event.target.value)}
                                >
                                    <option>Select a menu</option>
                                    {buffer}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Button block variant="dark" onClick={onGo}>find <BsArrowRight/></Button>
                            </Col>
                        </Row>

                    </Form>
                </div>
            );
        }
    }

    function giftCardTables()
    {
        if (giftCardPicksData == null || usersNotPickedGiftCards == null || userInfoGiftCards == null)
        {
            return (
                <div className="mt-3">
                    Picks null.
                </div>
            );
        }

        return (
            <div className="mt-3">
                {giftCardTotalsTable()}
                {giftCardIndividualsTable()}
            </div>
        );
    }

    function giftCardTotalsTable()
    {
        let headerBuffer = [];
        let numBuffer = [];

        let i = 0;
        for (const key in totalsGiftCards)
        {
            headerBuffer.push(<th key={key + (++i)}>{key}</th>);
            numBuffer.push(<td key={key + (++i)}>{totalsGiftCards[key]}</td>);
        }

        return (
            <>
                <h4>Totals</h4>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        {headerBuffer}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        {numBuffer}
                    </tr>
                    </tbody>
                </Table>
            </>
        );
    }

    function giftCardIndividualsTable()
    {
        let headerBuffer = [];
        headerBuffer.push(<th key={0}>Email</th>);
        headerBuffer.push(<th key={1}>User</th>);
        headerBuffer.push(<th key={2}>Address</th>);

        let i = 0;
        for (const key in totalsGiftCards)
        {
            headerBuffer.push(<th key={key + (++i)}>{key}</th>);
        }

        let picksBuffer = [];

        for (const email in userInfoGiftCards)
        {
            let row = [];

            let picks = giftCardPicksData.find(element => element.email === email);

            // picksFirst
            row.push(<td key={0}>{email}</td>);
            row.push(<td key={1}>{userInfoGiftCards[email]['fullName']}</td>);
            row.push(<td key={2}>{userInfoGiftCards[email]['addressLine']}</td>);

            if (picks !== undefined)
            {
                for (const key in picks.picks)
                {
                    row.push(<td key={key + (++i)}>{picks.picks[key]}</td>);
                }
            }

            picksBuffer.push(<tr key={i}>{row}</tr>);
        }

        return (
            <>
                <h4>Individuals</h4>
                <Table striped bordered hover responsive size="sm">
                    <thead>
                    <tr>
                        {headerBuffer}
                    </tr>
                    </thead>
                    <tbody>
                    {picksBuffer}
                    </tbody>
                </Table>
            </>
        );
    }

    function giftCardMenuPicker()
    {
        if (giftCardMenuNames == null)
        {
            return (
                <Spinner animation="border" role="status" variant="dark"/>
            );
        }
        else if (giftCardMenuNames.length === 0)
        {
            return (
                <p>No menus found to query for.</p>
            );
        }
        else
        {
            let buffer = [];

            for (const elem of giftCardMenuNames)
            {
                buffer.push(<option value={elem} key={elem}>{elem}</option>)
            }

            return (
                <div className="mt-3">
                    <Form>
                        <Row>
                            <Col>
                                <Form.Control
                                    as="select"
                                    onChange={(event) => setSelectedGiftCardMenu(event.target.value)}
                                >
                                    <option>Select a menu</option>
                                    {buffer}
                                </Form.Control>
                            </Col>
                            <Col>
                                <Button block variant="dark" onClick={onGoGiftCard}>find <BsArrowRight/></Button>
                            </Col>
                        </Row>

                    </Form>
                </div>
            );
        }
    }

    return(
        <div className="bk_text">
            <h1 className="bk_header mt-3">user picks</h1>
            <Card className="mt-3 mb-3">
                <Card.Header>Menu Picks</Card.Header>
                <Card.Body>
                    {menuPicker()}
                    {tables()}
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Header>Gift Card Picks</Card.Header>
                <Card.Body>
                    {giftCardMenuPicker()}
                    {giftCardTables()}
                </Card.Body>
            </Card>
        </div>
    );
}