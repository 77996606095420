import React, {Component} from "react";
import {Col, Form, ListGroup, Row} from "react-bootstrap";

export default class UserInfoModalEdit extends Component
{
    constructor(props) {
        super(props);

        if (props.user.role === "user")
        {
            this.state = {
                mealPlan: props.full_user_info.mealPlan,
                year: props.full_user_info.year,
                firstName: props.full_user_info.user_info.firstName,
                lastName: props.full_user_info.user_info.lastName,
                phoneNumber: props.full_user_info.user_info.phoneNumber,
                userAddress1: props.full_user_info.address_info.address1,
                userAddress2: props.full_user_info.address_info.address2,
                userCity: props.full_user_info.address_info.city,
                userState: props.full_user_info.address_info.state,
                userZIP: props.full_user_info.address_info.ZIP,
                parentFirstName: props.full_user_info.parent_info.parentFirstName,
                parentLastName: props.full_user_info.parent_info.parentLastName,
                parentAddress1: props.full_user_info.parent_info.parentAddress1,
                parentAddress2: props.full_user_info.parent_info.parentAddress2,
                parentCity: props.full_user_info.parent_info.parentCity,
                parentState: props.full_user_info.parent_info.parentState,
                parentZIP: props.full_user_info.parent_info.parentZIP
            };
        }
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    };

    get similarInfo()
    {
        return (
            <>
                Login Information
                <ListGroup className={"list-group-flush mb-3"}>
                    <ListGroup.Item><strong>Email: </strong>{this.props.user.email}</ListGroup.Item>
                    <ListGroup.Item><strong>Role: </strong>{this.props.user.role}</ListGroup.Item>
                    <ListGroup.Item><strong>Creation Datetime: </strong>{this.props.user.creation_datetime != null ? this.props.user.creation_datetime : 'unknown'}</ListGroup.Item>
                </ListGroup>
            </>
        );
    }

    get mealPlanEdit()
    {
        return (
            <>
                Meal Plan
                <Form>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            <strong>Meal Plan: </strong>
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                as="select"
                                name="mealPlan"
                                onChange={this.handleChange}
                                value={this.state.mealPlan}
                                custom>
                                <option value={-100}>Choose...</option>
                                <option value={150}>150 Block Meal Plan</option>
                                <option value={175}>175 Block Meal Plan</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            <strong>Year: </strong>
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                name="year"
                                onChange={this.handleChange}
                                value={this.state.year}>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
            </>
        );
    }

    get userInfoEdit()
    {
        return (
            <>
                User Info
                <Form>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            <strong>First Name: </strong>
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                name="firstName"
                                onChange={this.handleChange}
                                value={this.state.firstName}>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            <strong>Last Name: </strong>
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                name="lastName"
                                onChange={this.handleChange}
                                value={this.state.lastName}>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            <strong>Phone #: </strong>
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                name="phoneNumber"
                                onChange={this.handleChange}
                                value={this.state.phoneNumber}>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
            </>
        );
    }

    get userAddressInfo()
    {
        return (
            <>
                Address Info
                <Form>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            <strong>Address 1: </strong>
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                name="userAddress1"
                                onChange={this.handleChange}
                                value={this.state.userAddress1}>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            <strong>Address 2: </strong>
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                name="userAddress2"
                                onChange={this.handleChange}
                                value={this.state.userAddress2}>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                        <Form.Label column sm={3}>
                            <strong>City: </strong>
                        </Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                name="userCity"
                                onChange={this.handleChange}
                                value={this.state.userCity}>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
            </>
        );
    }

    get otherForm()
    {
        return (
            <>
                {this.mealPlanEdit}
                {this.userInfoEdit}
                {this.userAddressInfo}
            </>
        );
    }

    render()
    {
        let userInfo = "";

        if (this.props.user.role === "user")
        {
            userInfo = this.otherForm;
        }

        return (
            <>
                {this.similarInfo}
                {userInfo}
            </>
        );
    }
}