import React, {Component} from 'react';
import {Card, ListGroup, Spinner} from "react-bootstrap";
import {FaUser, GiMeal} from "react-icons/all";

export default class UserPageAccount extends Component{
    constructor(props){
        super(props);

        this.state = {
            userInfoJSON: null,
            currentPricesJSON: null
        };

    }

    updateUserInfoJSON(){
        // Load admin information
        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI("/api/account?account_hash=" + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            userInfoJSON: data
        })});

        url = encodeURI('/api/getMealPlanCosts');
        fetch(url).then(res => res.json()).then(data => {this.setState({
            currentPricesJSON: data
        })});
    }
    componentDidMount(){
        this.updateUserInfoJSON();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentTab !== 2 && this.props.currentTab === 2){
            this.updateUserInfoJSON();
        }
    }

    allergiesInfo(){
        if (this.state.userInfoJSON == null ){
            return null;
        }
        if (this.state.userInfoJSON.allergies == null){
            return null;
        }

        let allergy_data = this.state.userInfoJSON.allergies;
        let allergies_available = ["glutenAllergy", "peanutAllergy", "dairyAllergy", "treenutAllergy"];

        let allergies = "";

        for (const allergy of allergies_available){
            if (allergy_data[allergy]){
                let end_of_string = allergy.length;
                let end_of_allergy = end_of_string - 7;
                let sliced = allergy.substring(0, end_of_allergy);
                sliced = sliced.charAt(0).toUpperCase() + sliced.slice(1);
                console.log(sliced);

                allergies += (sliced + ', ');
            }
        }

        if (allergy_data['otherAllergy'] !== ''){
            allergies += (allergy_data['otherAllergy'] + ', ');
        }

        if (allergies.length === 0){
            return "N/A";
        }

        return allergies.slice(0, allergies.length - 2);
    }
    preferencesInfo(){
        //will return a string of all liked foods
        if (this.state.userInfoJSON == null){
            return null;
        }
        if (this.state.userInfoJSON.preferences == null){
            return null;
        }

        let preferences = this.state.userInfoJSON.preferences;
        let prefs_available = ["likesChicken", "likesTurkey", "likesBeef", "likesPork", "likesSteak", "likesShrimp",
            "likesSalmon", "likesTilapia"];

        let likes = "", dislikes = "";

        for (const pref of prefs_available){
            let sliced = pref.substring(5, pref.length);
            if (preferences[pref]){
                likes += (sliced + ', ');
            }
            else {
                dislikes += (sliced + ', ');
            }
        }

        likes = likes.slice(0, likes.length - 2);
        dislikes = dislikes.slice(0, dislikes.length - 2);

        return [likes, dislikes];
    }
    get mealPlanCard(){

        if (!this.state.currentPricesJSON.success)
        {
            return null;
        }

        let mealPlan150Cost = this.state.currentPricesJSON.meal_plan_150_cost;
        let mealPlan175Cost = this.state.currentPricesJSON.meal_plan_175_cost;

        if (this.state.userInfoJSON.mealPlan === "150"){
            return (
                <Card>
                    <Card.Body>
                        <Card.Title>150 Block Plan</Card.Title>
                        <ListGroup className={"list-group-flush"}>
                            <ListGroup.Item className={"list-group-item-primary font-weight-bold"}>
                                ${(mealPlan150Cost).toFixed(2)} per quarter
                            </ListGroup.Item>
                            <ListGroup.Item>~15 meals per week (3 breakfasts)</ListGroup.Item>
                            <ListGroup.Item>~150 Total Meals</ListGroup.Item>
                            <ListGroup.Item>$150 Fun Cash per quarter</ListGroup.Item>
                        </ListGroup>
                        <Card.Text>
                            <br/>
                            The 150 Block Plan offers approximately 15 meals per week and is designed for students who
                            like flexibility on when they wish to eat and will be on campus most weekends.
                            The $150 in Fun Cash per quarter offers more additional cash to spend on snacks and desserts.
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        }
        else {
            return (
                <Card>
                    <Card.Body>
                        <Card.Title>175 Block Plan</Card.Title>
                        <ListGroup className={"list-group-flush"}>
                            <ListGroup.Item className={"list-group-item-primary font-weight-bold"}>
                                ${(mealPlan175Cost).toFixed(2)} per quarter
                            </ListGroup.Item>
                            <ListGroup.Item>~17 meals per week (5 breakfasts)</ListGroup.Item>
                            <ListGroup.Item>~175 Total Meals</ListGroup.Item>
                            <ListGroup.Item>$150 Fun Cash per quarter</ListGroup.Item>
                        </ListGroup>
                        <Card.Text>
                            <br/>
                            The 175 Block Plan offers approximately 17 meals per week and is designed for students who
                            like flexibility on when they wish to eat and will be on campus most, if not all, weekends.
                            The $150 in Fun Cash per quarter offers more additional cash to spend on snacks and desserts.
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        }
    }


    render(){
        if (this.props.currentTab !== 2){
            return null;
        }

        if (this.state.userInfoJSON == null){
            return(
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }
        else {
            let likes_and_dislikes = this.preferencesInfo();

            return (
                <div>
                    <Card className={"mt-3 mb-3"}>
                        <Card.Header><GiMeal/> Meal Plan Information</Card.Header>
                        <Card.Body>
                            <Card.Title>Year</Card.Title>
                            <Card.Text>{this.state.userInfoJSON.year}</Card.Text>
                            <Card.Title>Selected Meal Plan</Card.Title>
                            {this.mealPlanCard}
                            <Card.Title className={'mt-3'}>Food Service Dates</Card.Title>
                            <ListGroup className={"list-group-flush mb-4"}>
                                <ListGroup.Item>
                                    <strong>Autumn Quarter: </strong>September 8th 2024 - November 18th 2024
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <strong>Winter Quarter: </strong>January 5th 2025 - March 20th 2025
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <strong>Spring Quarter: </strong>March 30th 2025 - June 9th 2025
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                    <Card className={"mt-3 mb-3"}>
                        <Card.Header><FaUser/> Account Information</Card.Header>
                        <Card.Body>
                            <Card.Title>User Info</Card.Title>
                            <ListGroup className={"list-group-flush mb-4"}>
                                <ListGroup.Item><strong>First Name: </strong>{this.state.userInfoJSON.user_info.firstName}</ListGroup.Item>
                                <ListGroup.Item><strong>Last Name: </strong>{this.state.userInfoJSON.user_info.lastName}</ListGroup.Item>
                                <ListGroup.Item><strong>Email: </strong>{this.state.userInfoJSON.user_info.email}</ListGroup.Item>
                                <ListGroup.Item><strong>Phone #: </strong>{this.state.userInfoJSON.user_info.phoneNumber}</ListGroup.Item>
                            </ListGroup>
                            <Card.Title>Parent/Guardian Info</Card.Title>
                            <ListGroup className={"list-group-flush mb-4"}>
                                <ListGroup.Item><strong>First Name: </strong>{this.state.userInfoJSON.parent_info.parentFirstName}</ListGroup.Item>
                                <ListGroup.Item><strong>Last Name: </strong>{this.state.userInfoJSON.parent_info.parentLastName}</ListGroup.Item>
                                <ListGroup.Item><strong>Email: </strong>{this.state.userInfoJSON.parent_info.parentEmail}</ListGroup.Item>
                                <ListGroup.Item><strong>Address: </strong>{this.state.userInfoJSON.parent_info.parentAddress1}
                                    {this.state.userInfoJSON.parent_info.parentAddress2}, {this.state.userInfoJSON.parent_info.parentCity},
                                    {this.state.userInfoJSON.parent_info.parentState} {this.state.userInfoJSON.parent_info.parentZIP}</ListGroup.Item>
                            </ListGroup>
                            <Card.Title>Preferences Info</Card.Title>
                            <ListGroup className={"list-group-flush mb-4"}>
                                <ListGroup.Item><strong>Likes: </strong>{likes_and_dislikes[0]}</ListGroup.Item>
                                <ListGroup.Item><strong>Dislikes: </strong>{likes_and_dislikes[1]}</ListGroup.Item>
                            </ListGroup>
                            <Card.Title>Allergies Info</Card.Title>
                            <ListGroup className={"list-group-flush mb-4"}>
                                <ListGroup.Item><strong>Allergies: </strong>{this.allergiesInfo()}</ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }
}