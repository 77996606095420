import React, {Component} from "react";
import {Card, Alert, Spinner} from "react-bootstrap";
import {BsFillPersonPlusFill, BsFillExclamationCircleFill} from "react-icons/all";
import {LinkContainer} from "react-router-bootstrap";

export default class AdminRegistrationStep3 extends Component {

    get body(){
        if (this.props.success == null){
            return (
                <Card.Body>
                    <Card.Title>
                        Creating account...
                    </Card.Title>
                    <Card.Text>
                        Stand by while we create your account!
                    </Card.Text>
                    <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </div>
                </Card.Body>
            );
        }
        else {
            let title = this.props.success ? 'Success!' : 'Something went wrong...';
            let text = this.props.success ? 'Your account has been created successfully. Click the button below to login to your account!' :
                'Your account could not be created. Click the button below to go home.';
            let alert = this.props.success ? '' : (
                <Alert variant={'danger'}>
                    <BsFillExclamationCircleFill/> ERROR: {this.props.data.error}
                </Alert>
            );
            let button = (
                <LinkContainer to={this.props.success ? '/login' : '/'}>
                    <button className={"btn btn-primary float-right mt-2 mb-2"}>
                        Continue
                    </button>
                </LinkContainer>
            );
            return (
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{text}</Card.Text>
                    {alert}
                    {button}
                </Card.Body>
            );
        }
    }

    render(){
        if (this.props.currentTab !== 3){
            return null;
        }

        return(
            <Card className={"bk_text"}>
                <Card.Header>
                    <BsFillPersonPlusFill/> Admin Registration
                </Card.Header>
                {this.body}
            </Card>
        );
    }

}