import React from "react";
import "./Registration.css";
import {Redirect} from "react-router-dom";


export default function Login(props) {

    props.logOut();

    return (
        <Redirect to='/login' />
    );
}