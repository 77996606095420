import React, {useState} from "react";
import {postAdminAddGiftCardMenu} from "../../../../utils/ApiHandler";
import {Alert, Button, Col, Form, Modal, Spinner, Table} from "react-bootstrap";
import {BsExclamationTriangle, GrAddCircle, GrSubtractCircle} from "react-icons/all";

export default function AdminAddGiftCardMenuModal(props)
{
    const [pickingStartDate, setPickingStartDate] = useState("");
    const [pickingEndsDate, setPickingEndsDate] = useState("");
    const [menuName, setMenuName] = useState("");

    const [startsValid, setStartsValid] = useState(null);
    const [endsValid, setEndsValid] = useState(null);
    const [menuNameValid, setMenuNameValid] = useState(null);

    const [itemsAdded, setItemsAdded] = useState([]);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

    const [errorNotifBody, setErrorNotifBody] = useState(null);

    const onSubmit = () => {
        setSubmitButtonClicked(true);
        setErrorNotifBody(null);
        if (startsValid == null || endsValid == null || menuNameValid == null)
        {
            setStartsValid(false);
            setEndsValid(false);
            setMenuNameValid(false);
        }
        else
        {
            if (startsValid && endsValid && itemsAdded.length > 0)
            {
                let data = {
                    'startPicking': pickingStartDate,
                    'endPicking': pickingEndsDate,
                    'name': menuName,
                    'menu_items': itemsAdded
                };

                postAdminAddGiftCardMenu(data).then((response) => {
                    if (response.success)
                    {
                        props.setShowModal(false);
                        props.updateViewCounter();
                        setSubmitButtonClicked(false);
                    }
                    else
                    {
                        setErrorNotifBody(response.error);
                        setSubmitButtonClicked(false);
                    }
                });
            }
            else
            {
                setSubmitButtonClicked(false);
            }
        }
    };

    function addItem(name)
    {
        let newItemsAdded = [];
        for (const element of itemsAdded)
        {
            newItemsAdded.push(element);
        }
        newItemsAdded.push(name);

        setItemsAdded(newItemsAdded);
    }

    function removeItem(name)
    {
        let newItemsAdded = [];
        for (const element of itemsAdded)
        {
            if (element !== name)
            {
                newItemsAdded.push(element);
            }
        }

        setItemsAdded(newItemsAdded);
    }

    function itemAdder()
    {
        let addedBuffer = [];
        let notAddedBuffer = [];

        for (const element of props.itemData)
        {
            let button = (<></>);
            if (itemsAdded.includes(element.name))
            {
                button = (
                    <Button variant="outline-danger float-right" onClick={() => removeItem(element.name)}>
                        <GrSubtractCircle/>
                    </Button>
                );
            }
            else
            {
                button = (
                    <Button variant="outline-success float-right" onClick={() => addItem(element.name)}>
                        <GrAddCircle/>
                    </Button>
                );
            }

            let htmlElement = (
                <tr key={element.name}>
                    <td>{element.name}</td>
                    <td>{element.price}</td>
                    <td>
                        {button}
                    </td>
                </tr>
            );

            if (itemsAdded.includes(element.name))
            {
                addedBuffer.push(htmlElement);
            }
            else
            {
                notAddedBuffer.push(htmlElement);
            }
        }

        return (
            <div>
                <h4 className="mt-3">items added</h4>
                <Table striped>
                    <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Item Price</th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    {addedBuffer}
                    </tbody>
                </Table>
                <h4 className="mt-3">items not added</h4>
                <Table striped>
                    <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Item Price</th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    {notAddedBuffer}
                    </tbody>
                </Table>
            </div>
        );
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        console.log(name + ":" + value);
        switch (name)
        {
            case "pickingEndsDate":
                setPickingEndsDate(value);
                setEndsValid(value.length > 0);
                break;
            case "pickingStartDate":
                setPickingStartDate(value);
                setStartsValid(value.length > 0);
                break;
            case "menuName":
                setMenuName(value);
                setMenuNameValid(value.length > 0);
        }
    };

    function isValidState(fieldValid)
    {
        return fieldValid != null ? (fieldValid ? 'is-valid' : 'is-invalid') : '';
    }

    if (props.itemData == null)
    {
        return (
            <div><Spinner/></div>
        );
    }

    function submitButton()
    {
        if (submitButtonClicked)
        {
            return (
                <Button variant='dark' className="float-right" onClick={onSubmit}>
                    <Spinner variant='light'/>
                </Button>
            );
        }
        return (
            <Button variant='dark' className="float-right" onClick={onSubmit}>
                Add Menu
            </Button>
        );
    }

    function notification()
    {
        if (errorNotifBody != null)
        {
            return (
                <Alert variant="danger">
                    <BsExclamationTriangle/> {errorNotifBody}
                </Alert>
            );
        }
    }

    return (
        <Modal
            show={props.showModal}
            onHide={() => props.setShowModal(false)}
            backdrop="static"
            keyboard={false}
            size="xl"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Gift Card Menu</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="ml-3 mr-3">
                    {notification()}
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>*Picking Starts Date</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                name="pickingStartDate"
                                value={pickingStartDate}
                                onChange={onChange}
                                className={isValidState(startsValid)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>*Picking Ends Date</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                name="pickingEndsDate"
                                value={pickingEndsDate}
                                onChange={onChange}
                                className={isValidState(endsValid)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>*Menu Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="menuName"
                                placeholder="Enter menu name..."
                                value={menuName}
                                onChange={onChange}
                                className={isValidState(menuNameValid)}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
                {itemAdder()}
                {submitButton()}
            </Modal.Body>
        </Modal>
    );
}