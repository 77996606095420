import React from "react";
import "./NotFound.css";

export default function NotFound() {
    return (
        <div className="NotFound">
            <h1 className="bk_header">404 error!</h1>
            <p className="bk_text">Sorry, page not found...</p>
        </div>
    );
}