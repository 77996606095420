import React from "react";

export function legalAgreement() {
    return (
        <>
            This agreement is between Gourmet Fitness (hereinafter "Agent") and respective tenant and Alpha Zeta member (collectively hereinafter "Resident"). Agent will provide meal plans in accordance with the selected meal plan made by the resident and will be responsible for the timely delivery and preparation of selected meal plan. Agent will make every effort to accommodate all requests, but Resident understands that this is <strong>within reason</strong>. This agreement between Resident and Agent will automatically renew every quarter for the 2024-2025 academic year. Resident agrees to pay the quarterly meal plan fee at the same time rent is due, see section four of the residential lease agreement for due dates. Resident shall make all payments due to Gourmet Fitness. Resident will give the Agent 90 day notice if Resident decides to cancel his meal plan, if Resident does not cancel prior to the cut off date of September 1st, 2024, Resident will be billed for each quarter and will be required to pay. Resident’s notice of intent to cancel shall only be effective on the date the notice is actually received by or receipted for by Agent.
            <br /><br />
            Assumption of Risk and Waiver of Liability: In consideration, I/we, assume all risks associated with the preparation of food products supplied under this Agreement, including matters of health and safety associated thereof. I/we do hereby release, waiver, discharge, and covenant not to sue The Denver Chapter, The Alpha Zeta of Beta Theta Pi or Gourmet Fitness their respective Trustees, officers, employees, students and agents from liability from any and all claims including the negligence of The Denver Chapter, The Alpha Zeta of Beta Theta Pi or Gourmet Fitness their respective Trustees, officers, employees and agents, resulting in personal injury, accident, or illness, including death and property loss arising from any and all food products provided under this Agreement.
            <br /><br />
            Indemnification and Hold Harmless: I agree to INDEMNIFY and HOLD The Denver Chapter, The Alpha Zeta of Beta Theta Pi or Gourmet Fitness, and their respective Trustees, officers, employees and agents HARMLESS from any and all claims, actions, suits, procedures, costs, expenses, damages, and liabilities, including attorney’s fees brought as a result of negligence in the preparation or delivery of food products served under this Agreement and to reimburse them for any such expenses incurred.
            <br /><br />
            Severability: Resident further expressly agrees that the foregoing waiver and assumption of risks agreement is intended to be as broad and inclusive as permitted by the law of the State of Colorado and that if any portion thereof is held invalid, it is agreed that the balance shall, notwithstanding, continue in full legal force and effect.
            <br /><br />
            Acknowledgement of Understanding: I have read this waiver of liability, assumption of risk, and indemnity agreement, fully understand its terms, and understand that I am giving up substantial rights, including my right to sue. I am signing the agreement freely and voluntarily, and intend by my signature to be a complete and unconditional release of all liability as relates to this Agreement to the greatest extent allowed by law.
            <br /><br /><br />
            By signing this application electronically, I certify under penalty of perjury and false swearing that my answers are correct and complete to the best of my knowledge.
            <br /><br />
        </>
    );
}