import React, {Component} from "react";
import {Card, Form, Col, Alert, Button, Spinner} from "react-bootstrap";
import {Redirect} from "react-router-dom";

export default class LoginPage extends Component {

    constructor(props){
        super(props);

        this.state = {
            email: '',
            password: '',
            emailValid: null,
            passwordValid: null,
            loginSuccessful: null,
            submitClicked: false
        };

        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleIsValid = this.handleIsValid.bind(this);
    }

    //*** handleData Functions
    //  - handleIsValid()
    //  - handleChangePassword()
    //  - handleChangeEmail()

    handleIsValid(fieldName){
        return this.state[fieldName] != null ? (this.state[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }
    handleChangePassword(event){
        const {value} = event.target;
        if (value.length > 0){
            this.setState({
                passwordValid: true,
                password: value
            });
        }
        else {
            this.setState({
                passwordValid: false
            });
        }
    }
    handleChangeEmail(event){
        const {value} = event.target;
        let emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length === 0){
            this.setState({
                emailValid: false
            });
        }
        else if (emailPattern.test(value)){
            this.setState({
                emailValid: true,
                email: value
            });

        }
        else {
            this.setState({
                emailValid: false
            });
        }
    }

    //*** submitButton Functions
    //  - handleSubmit;
    //  - postData (API CALL)
    //*** submitButton Components
    //  - get submitButton()

    handleSubmit = (event) => {
        event.preventDefault();

        this.setState({
           submitClicked: true
        });

        console.log('handle submit!!!!');
        this.postData('/api/login', this.state).then(data => data).then(data => {
            if (data.hashed_login_key !== undefined) {
                localStorage.setItem('hashed_login_key', data.hashed_login_key);
                localStorage.setItem('rhash', data.rhash);
            }
        });
        // this.postData('/api/login', this.state).then(data => data.success ? localStorage.setItem('hashed_login_key', data.hashed_login_key) : null);

        localStorage.setItem('user', this.state.email);

        setTimeout(() => {
            // console.log('is hash key there??', localStorage.getItem('hashed_login_key'));
            if (localStorage.getItem('hashed_login_key')){
                this.setState({
                    loginSuccessful: true
                });
                this.props.logIn();
            }
            else {
                this.setState({
                    loginSuccessful: false
                });
            }

            this.setState({
                submitClicked: false
            });

        },2000);



    };
    async postData(url, data){
        const response = await fetch(url, {
            method: 'POST',
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(data)
        });
        return response.json();
    }
    get submitButton(){
        if (this.state.submitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" type="submit">
                    Submit
                </Button>
            );
        }
    }

    /**
     * unvalidatedAlert Component
     * @returns {null|*} <Alert/> Component formatted with unvalidated text
     */
    get unvalidatedAlert(){
        if (this.state.loginSuccessful != null){
            if (this.state.loginSuccessful === false){
                return (
                    <Alert variant='danger'>
                        Something went wrong. Either your password or your email is incorrect.
                    </Alert>
                );
            }
        }
        return null;
    }

    //*** render() function

    render() {
        if (this.state.loginSuccessful === true){
            if (localStorage.getItem('rhash') === '21232f297a57a5a743894a0e4a801fc3'){
                return (<Redirect to='admin'/>);
            }
            else {
                return (<Redirect to='/account' />);
            }
        }

        return (
            <div>
                {this.unvalidatedAlert}
                <Card>
                    <Card.Header className="bk_header">
                        <strong>Account Login</strong>
                    </Card.Header>
                    <Card.Body className="bk_text">
                        <Card.Title className="bk_header">
                            Login
                        </Card.Title>
                        <Form className="m-0 align" onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>DU Email Address</Form.Label>
                                    <Form.Control required type="email" placeholder="Enter email address"
                                                  name="email"
                                                  className={this.handleIsValid('emailValid')}
                                                  onChange={this.handleChangeEmail}
                                    />
                                    <Form.Control.Feedback type="invalid">Please enter a valid email address.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control required type="password" placeholder="Enter password"
                                                  name="password"
                                                  className={this.handleIsValid('passwordValid')}
                                                  onChange={this.handleChangePassword}
                                    />
                                    <Form.Control.Feedback type="invalid">Please enter a valid password.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            {this.submitButton}
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        );
    }

}