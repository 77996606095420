import React from "react";
import {ListGroup, Modal} from "react-bootstrap";

// return a string of all liked foods
function parseInfoPreferences(full_user_info)
{
    let preferences = full_user_info.preferences;
    let prefs_available = ["likesChicken", "likesTurkey", "likesBeef", "likesPork", "likesSteak", "likesShrimp",
        "likesSalmon", "likesTilapia"];

    let likes = "", dislikes = "";

    for (const pref of prefs_available){
        let sliced = pref.substring(5, pref.length);
        if (preferences[pref]){
            likes += (sliced + ', ');
        }
        else {
            dislikes += (sliced + ', ');
        }
    }

    likes = likes.slice(0, likes.length - 2);
    dislikes = dislikes.slice(0, dislikes.length - 2);

    return [likes, dislikes];
}

// parse allergies info
// return a string
function parseInfoAllergies(full_user_info){
    let allergy_data = full_user_info.allergies;
    let allergies_available = ["glutenAllergy", "peanutAllergy", "dairyAllergy", "treenutAllergy"];

    let allergies = "";

    for (const allergy of allergies_available){
        if (allergy_data[allergy]){
            let end_of_string = allergy.length;
            let end_of_allergy = end_of_string - 7;
            let sliced = allergy.substring(0, end_of_allergy);
            sliced = sliced.charAt(0).toUpperCase() + sliced.slice(1);

            allergies += (sliced + ', ');
        }
    }

    if (allergy_data['otherAllergy'] !== ''){
        allergies += (allergy_data['otherAllergy'] + ', ');
    }

    if (allergies.length === 0){
        return "N/A";
    }

    return allergies.slice(0, allergies.length - 2);
}

// parses the address to see if it is a beta house address
// or a regular address
function getAddress(full_user_info)
{
    let address_info = "";

    if (full_user_info.address_info.city === '')
    {
        address_info = full_user_info.address_info.address1 + ' ' + full_user_info.address_info.address2;
    }
    else
    {
        address_info = full_user_info.address_info.address1 + ' ' + full_user_info.address_info.address2 + ', ' +
            full_user_info.address_info.city + ', ' + full_user_info.address_info.state + ' ' +
            full_user_info.address_info.ZIP;
    }

    return address_info;
}

function UserInfoModalView(props)
{
    if (props.user == null)
    {
        return (<p>ERROR: User not passed.</p>);
    }

    let similar_info = (
        <>
            Login Information
            <ListGroup className={"list-group-flush mb-3"}>
                <ListGroup.Item><strong>Email: </strong>{props.user.email}</ListGroup.Item>
                <ListGroup.Item><strong>Role: </strong>{props.user.role}</ListGroup.Item>
                <ListGroup.Item><strong>Creation Datetime: </strong>{props.user.creation_datetime != null ? props.user.creation_datetime : 'unknown'}</ListGroup.Item>
            </ListGroup>
        </>
    );

    let specific_info;

    if (props.user.role === 'admin')
    {
        specific_info = (
            <>
                This user is an administrator, no further information is available.
            </>
        );
    }
    else
    {
        if (props.full_user_info == null)
        {
            specific_info = (
                <>
                    The full user info was not passed for the current user.
                </>
            );
        }
        else
        {
            let full_user_info = props.full_user_info;

            let likes_and_dislikes = parseInfoPreferences(full_user_info);
            let allergies = parseInfoAllergies(full_user_info);
            let address_info = getAddress(full_user_info);

            specific_info = (
                <>
                    Meal Plan
                    <ListGroup className={"list-group-flush mb-4"}>
                        <ListGroup.Item><strong>Meal Plan: </strong>{full_user_info.mealPlan}</ListGroup.Item>
                        <ListGroup.Item><strong>Year: </strong>{full_user_info.year}</ListGroup.Item>
                    </ListGroup>
                    User Info
                    <ListGroup className={"list-group-flush mb-4"}>
                        <ListGroup.Item><strong>First Name: </strong>{full_user_info.user_info.firstName}</ListGroup.Item>
                        <ListGroup.Item><strong>Last Name: </strong>{full_user_info.user_info.lastName}</ListGroup.Item>
                        <ListGroup.Item><strong>Phone #: </strong>{full_user_info.user_info.phoneNumber}</ListGroup.Item>
                    </ListGroup>
                    Address Info
                    <ListGroup className='list-group-flush mb-4'>
                        <ListGroup.Item><strong>Address: </strong>{address_info}</ListGroup.Item>
                    </ListGroup>
                    Parent/Guardian Info
                    <ListGroup className={"list-group-flush mb-4"}>
                        <ListGroup.Item><strong>First Name: </strong>{full_user_info.parent_info.parentFirstName}</ListGroup.Item>
                        <ListGroup.Item><strong>Last Name: </strong>{full_user_info.parent_info.parentLastName}</ListGroup.Item>
                        <ListGroup.Item><strong>Email: </strong>{full_user_info.parent_info.parentEmail}</ListGroup.Item>
                        <ListGroup.Item><strong>Address: </strong>{full_user_info.parent_info.parentAddress1}
                            {full_user_info.parent_info.parentAddress2}, {full_user_info.parent_info.parentCity},
                            {full_user_info.parent_info.parentState} {full_user_info.parent_info.parentZIP}</ListGroup.Item>
                    </ListGroup>
                    Preferences Info
                    <ListGroup className={"list-group-flush mb-4"}>
                        <ListGroup.Item><strong>Likes: </strong>{likes_and_dislikes[0]}</ListGroup.Item>
                        <ListGroup.Item><strong>Dislikes: </strong>{likes_and_dislikes[1]}</ListGroup.Item>
                    </ListGroup>
                    Allergies Info
                    <ListGroup className={"list-group-flush mb-4"}>
                        <ListGroup.Item><strong>Allergies: </strong>{allergies}</ListGroup.Item>
                    </ListGroup>
                </>
            );
        }
    }

    return (
        <>
            {similar_info}
            {specific_info}
        </>
    );
}

export default UserInfoModalView;