import React, {Component} from "react";
import {Card, Alert} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export default class RegistrationStep6 extends Component{

    get validationAlert(){
        console.log('this.props.success = ', this.props.success);
        if (this.props.success === true){
            return (
                <Alert variant='success'>
                    Account registered successfully!
                </Alert>
            );
        }
        else if (this.props.success === false && this.props.error !== ''){
            return (
                <Alert variant='danger'>
                    Error occurred: {this.props.error}
                </Alert>
            );
        }
        else {
            return (
                <Alert variant='danger'>
                    Something went wrong on our side. Please wait a little while and try again or report an issue.
                </Alert>
            );
        }
    }

    render() {
        if (this.props.currentStep !== 6){
            return null;
        }

        return (
            <div>
                {this.validationAlert}
                <Card className="bk_text">
                    <Card.Header className="bk_header">
                        <strong>Account Registration</strong>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title className={"bk_header"}>
                            Beta Kitchen by Gourmet Fitness Brother Registration
                        </Card.Title>
                        <Card.Text>
                            Thank you for filling out the registration form. <br/> <br/>
                            {this.props.success ? 'Please click the button below to login. You will receive a confirmation email shortly.' : "Sorry that didn't work correctly, click the button below to go back home."}
                        </Card.Text>
                        <LinkContainer to={this.props.success ? '/login' : '/'}>
                            <button className={"btn btn-primary float-right mt-2 mb-2"}>
                                Continue
                            </button>
                        </LinkContainer>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

