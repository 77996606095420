import React from "react";
import "./HomePage.css"
import {legalAgreement} from "../utils/Consts";
import {Alert} from "react-bootstrap";
import {BsExclamationTriangle} from "react-icons/all";

export default function TermsAndConditions() {
    return (
        <div className="Account">
            <div className="container">
                <h5 className="bk_header mb-5"> user agreement </h5>
                <Alert variant="info">
                    <BsExclamationTriangle/> This agreement must be signed upon registration.
                </Alert>
                <div className="bk_text">

                </div>
                {legalAgreement()}
            </div>
        </div>
    );
}