import React from "react";
import "./HomePage.css";
import HomePage from "../components/HomePage";

export default function Home() {
    return (
        <div className="Home">
            <div className={"main_container"}>
                <HomePage />
            </div>
        </div>
    );
}