import React, {Component} from "react";
import {Alert, Card} from "react-bootstrap";
import {FaLock, BsXCircleFill} from "react-icons/all";

export default class RegistrationStep0 extends Component {

    render() {
        if (this.props.currentStep !== 0) {
            return null;
        }

        if (this.props.regOpen) {
            return (
                <Card className={"bk_text"}>
                    <Card.Header className="bk_header">
                        <strong>Account Registration</strong>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title className="bk_header">
                            Beta Kitchen by Gourmet Fitness Brother Registration
                        </Card.Title>
                        <Card.Text>
                            Thank you for registering for the Beta Kitchen by Gourmet Fitness meal plan for the
                            2024-2025 academic year.
                            Before going further, please realize this registration will take approximately 10 minutes
                            and will bind you to paying for your chosen meal plan for this upcoming year.
                            <br/><br/>
                            Please do not use the back button in your browser at any point during this registration
                            process. Check all fields for correctness prior to hitting the next button as only some are
                            changeable after registration.
                            <br/><br/>
                            We look forward to serving you this year!
                            <br/><br/>
                            <FaLock/> All important information is kept encrypted on our servers.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        We commit and promise to not share any of your personal information with any other third
                        parties.
                    </Card.Footer>
                </Card>
            );
        } else {
            return (
                <Card className={"bk_text"}>
                    <Card.Header className="bk_header">
                        <strong>Account Registration</strong>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title className="bk_header">
                            Beta Kitchen by Gourmet Fitness Brother Registration
                        </Card.Title>
                        <div className={'justify-content-center'}>
                            <Alert variant={'danger'}>
                                Registration is currently closed.
                            </Alert>
                        </div>
                        <div className={'d-flex justify-content-center align-items-center mt-5 mb-5'}>
                            <BsXCircleFill className={'text-danger'} size={120}/>
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        We commit and promise to not share any of your personal information with any other third
                        parties.
                    </Card.Footer>
                </Card>
            );
        }
    }
}