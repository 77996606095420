import React, {Component} from "react";
import {
    Card,
    Spinner,
    Row,
    Col,
    Button,
    Modal,
    Tabs,
    Tab,
    Table,
    ButtonGroup,
    DropdownButton, DropdownItem, ButtonToolbar, Form, Alert
} from "react-bootstrap";
import {
    BsFillPeopleFill,
    BsFillPieChartFill,
    BsListUl,
    BsFillPlusSquareFill,
    BsCheckCircle, BsCircle, BsCheckAll,
    BsFilePlus
} from "react-icons/all";
import Chart from "react-google-charts";

export default class AdminPageAccounting extends Component{

    constructor(props) {
        super(props);

        this.state ={
            adminAccountingJSON: null,
            width: 0,
            height: 0,
            // modals
            invoiceModalShow: false,
            invoiceSendCardShow: false,
            selectedUserEmails: [],
            // invoiceSending variables
            invoiceSendIncludeBagDeposit: false,
            invoiceSendQuarterSelect: null,
            invoiceSendSubmitClicked: false,
            invoiceSendNotifShow: false,
            invoiceSendJSON: null,
            // incrementer
            changeStateIncrementer: 0
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    //ButtonStyle
    buttonStyle(){
        return {cursor: 'pointer'};
    }

    //*** Window Resizing for Chart Resizing
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    //*** API Calls
    updateAdminAccountingJSON(){
        // Load admin information
        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI('api/admin/accounting?account_hash=' + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            adminAccountingJSON: data
        })});
    }
    componentDidMount() {
        this.updateAdminAccountingJSON();
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentTab !== 1 && this.props.currentTab === 1){
            this.updateAdminAccountingJSON();
        }

        if (prevState.changeStateIncrementer !== this.state.changeStateIncrementer){
            this.updateAdminAccountingJSON();
        }
    }

    //*** incomeCard Components
    get incomeBarChart(){
        return(
            <div className={'d-flex justify-content-center align-items-center mt-5 mb-5'}>
                <Chart
                    width={this.state.width < 800 ? '275px' : '800px'}
                    height={'300px'}
                    chartType="Bar"
                    loader={<div>Loading Chart</div>}
                    data={[
                        ['Type', '$', {role: 'style'}],
                        ['Income', (((this.state.adminAccountingJSON.meal_150_count * 1660.0) + (this.state.adminAccountingJSON.meal_175_count * 1750.0))), 'color: green'],
                        ['Expenses', 0, 'color: red'],
                        ['Profit', (((this.state.adminAccountingJSON.meal_150_count * 1660.0) + (this.state.adminAccountingJSON.meal_175_count * 1750.0))), 'color: blue'],
                    ]}
                    options={{
                        chartArea: { width: '100%' },
                        hAxis: {
                            minValue: 0,
                        },
                        vAxis: {
                        },
                        legend: {
                            position: 'none'
                        }
                    }}
                    // For tests
                    rootProps={{ 'data-testid': '2' }}
                />
            </div>
        );
    }
    get incomeCard(){
        return(
            <Card className={'mt-3 mb-3'}>
                <Card.Header>
                    <div className={'my-auto'}>
                        <BsFillPieChartFill/> Accounting | Income Overview
                    </div>
                </Card.Header>
                {this.incomeBarChart}
            </Card>
        );
    }

    //*** invoiceModal Components
    pushPopEmailToSelected(email){
        let selectedUserEmails = this.state.selectedUserEmails;

        if (selectedUserEmails.includes(email)){
            selectedUserEmails = selectedUserEmails.filter(curr_email => curr_email !== email);
        }
        else {
            selectedUserEmails.push(email);
        }

        this.setState({
            selectedUserEmails: selectedUserEmails
        });
    }
    selectAll(){
        let selectedUserEmails = [];

        for (const element of this.state.adminAccountingJSON.users){
            selectedUserEmails.push(element.user_info.email);
        }

        this.setState({
            selectedUserEmails: selectedUserEmails
        });
    }

    handleChange(event){
        const {name, value, checked} = event.target;
        if (value === 'on'){
            // console.log('changing ' + name + ' to ' + checked);
            this.setState({
                [name]: checked
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }
    }

    invoiceSendSubmit = (event) => {
        event.preventDefault();
        this.setState({
            invoiceSendSubmitClicked: true
        });
        let changeStateIncrementer = this.state.changeStateIncrementer;
        ++changeStateIncrementer;

        let post_body = {
            users: this.state.selectedUserEmails,
            invoiceSendIncludeBagDeposit: this.state.invoiceSendIncludeBagDeposit,
            invoiceSendQuarterSelect: this.state.invoiceSendQuarterSelect
        };
        let url = '/api/admin/accounting/invoices/send';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(data => {this.setState({
            invoiceSendJSON: data,
            invoiceSendNotifShow: true,
            invoiceSendSubmitClicked: false,
            changeStateIncrementer: changeStateIncrementer
        })});

        // Show the notif for 3 seconds
        setTimeout(() => {
            this.setState({
                invoiceSendNotifShow: false,
                invoiceSendCardShow: false
            })
        }, 3000);
    };
    get invoiceSendSubmitButton(){
        if (this.state.invoiceSendSubmitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.invoiceSendSubmit} className="btn btn-primary float-right mt-2 mb-2" type="submit">
                    Send Invoices
                </Button>
            );
        }
    }
    get invoiceSendCardNotification(){
        if (this.state.invoiceSendNotifShow){
            if (this.state.invoiceSendJSON.success){
                return (
                    <Alert variant={'success'}>
                        Invoices have been successfully sent!
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.invoiceSendJSON.error}
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }
    get invoiceSendCard(){
        if (!this.state.invoiceSendCardShow){
            return null;
        }

        let emails = '';
        for (const email of this.state.selectedUserEmails){
            emails = emails + email + ", ";
        }

        return(
            <Card className={'mt-3 mb-3'}>
                <Card.Body>
                    {/*{this.userDeleteModalNotification}*/}
                    Users: <strong>{emails}</strong><br/><br/>
                    Please select which quarterly invoice to send out and if you would like to include the bag deposit in the invoice.
                    <Form className={'mt-2 ml-3 mr-3'}>
                        <Form.Group as={Row}>
                            <Form.Label>*Quarter</Form.Label>
                            <Form.Control
                                required
                                as="select"
                                name="invoiceSendQuarterSelect"
                                onChange={this.handleChange}
                                custom>
                                <option value={-100} selected>Choose...</option>
                                <option value={1}>Q1 : Fall Quarter</option>
                                <option value={2}>Q2 : Winter Quarter</option>
                                <option value={3}>Q3 : Spring Quarter</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Check type='checkbox' name='invoiceSendIncludeBagDeposit'
                                        label='Include $25 bag deposit?'
                                        onChange={this.handleChange}/>
                        </Form.Group>

                        <div className={'mt-2 mb-2'}>
                            <strong>Invoice Totals</strong>
                        </div>
                        <Table striped hover responsive className={"mt-2 mb-2"}>
                            <thead>
                            <tr>
                                <th>Meal Plan</th>
                                <th>Base Price</th>
                                <th>Bag Fee</th>
                                <th>Total</th>
                            </tr>
                            </thead>
                            <tr key={1}>
                                <td>150 Block</td>
                                <td>$1660.00</td>
                                <td>{this.state.invoiceSendIncludeBagDeposit ? '$25.00' : '$0.00'}</td>
                                <td>{this.state.invoiceSendIncludeBagDeposit ? '$1685.00' : '$1660.00'}</td>
                            </tr>
                            <tr key={2}>
                                <td>175 Block</td>
                                <td>$1750.00</td>
                                <td>{this.state.invoiceSendIncludeBagDeposit ? '$25.00' : '$0.00'}</td>
                                <td>{this.state.invoiceSendIncludeBagDeposit ? '$1775.00' : '$1750.00'}</td>
                            </tr>
                        </Table>
                        <div className={'mt-2'}>
                            <Button onClick={() => this.setState({invoiceSendCardShow: false})}
                                    className="btn btn-secondary mt-2 mb-2" type="button">
                                Cancel
                            </Button>
                            {this.invoiceSendSubmitButton}
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
    get invoiceCreateNewTab(){
        let selected_title = (
            <div className={'d-inline-block'}>
                {this.state.selectedUserEmails.length} <BsFillPeopleFill/> selected
            </div>
        );
        let buttonsDisabled = this.state.selectedUserEmails.length === 0;

        return(
            <div>
                <div className={'mt-3 mb-3'}>
                    <h4 className={'bk_header'}>Create New Invoices</h4>
                    {this.invoiceSendCardNotification}
                    {this.invoiceSendCard}
                    <div className={'float-right mb-2'}>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup className="mr-2" aria-label="First group">
                                <DropdownButton as={ButtonGroup} title={selected_title} id="bg-nested-dropdown" variant={'primary'}>
                                    <DropdownItem eventKey="1" onClick={() => this.selectAll()}><BsCheckAll/> Select All</DropdownItem>
                                    <DropdownItem eventKey="2" onClick={() => this.setState({selectedUserEmails: []})}><BsCircle/> Deselect All</DropdownItem>
                                </DropdownButton>
                            </ButtonGroup>
                            <ButtonGroup className="mr-2" aria-label="Second group">
                                <Button className={buttonsDisabled ? 'disabled' : ''} onClick={() => {if (!buttonsDisabled){this.setState({invoiceSendCardShow: true});}}} variant={'success'}><BsFilePlus/></Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </div>
                </div>
                {this.invoiceCreateNewTabUserTable}
            </div>
        );
    }
    get invoiceCreateNewTabUserTableData(){
        let buffer = [];

        for (const element of this.state.adminAccountingJSON.users){
            let elementSelected = this.state.selectedUserEmails.includes(element.user_info.email);

            buffer.push(
                <tr key={element.user_info.email} className={elementSelected ? 'table-primary' : ''}>
                    <td onClick={() => this.pushPopEmailToSelected(element.user_info.email)}
                        style={this.buttonStyle()}>{elementSelected ? (<BsCheckCircle/>) : (<BsCircle/>)}</td>
                    <td>{element.user_info.email}</td>
                    <td>{element.user_info.firstName}</td>
                    <td>{element.user_info.lastName}</td>
                    <td>{element.mealPlan}</td>
                    <td>{element.q1_sent != null ? (element.q1_sent ? (<BsCheckCircle/>) :(<BsCircle/>) ) : (<BsCircle/>)}</td>
                    <td>{element.q2_sent != null ? (element.q2_sent ? (<BsCheckCircle/>) :(<BsCircle/>) ) : (<BsCircle/>)}</td>
                    <td>{element.q3_sent != null ? (element.q3_sent ? (<BsCheckCircle/>) :(<BsCircle/>) ) : (<BsCircle/>)}</td>
                </tr>);
        }

        return (
            <tbody>
            {buffer}
            </tbody>
        );
    }
    get invoiceCreateNewTabUserTable(){
        return (
            <Table striped hover responsive className={"pt-0 mt-0 mb-0"}>
                <thead>
                <tr>
                    <th> </th>
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Meal Plan</th>
                    <th>Q1 Sent?</th>
                    <th>Q2 Sent?</th>
                    <th>Q3 Sent?</th>
                </tr>
                </thead>
                {this.invoiceCreateNewTabUserTableData}
            </Table>
        );
    }


    get invoiceOutstandingTabTableData(){
        let buffer = [];

        for (const element of this.state.adminAccountingJSON.outstanding_invoices){
            // let elementSelected = this.state.selectedUserEmails.includes(element.user_info.email);
            let elementSelected = false;

            buffer.push(
                <tr key={element.invoice_number} className={elementSelected ? 'table-primary' : ''}>
                    {/*<td onClick={() => this.pushPopEmailToSelected(element.user_info.email)}*/}
                    {/*    style={this.buttonStyle()}>{elementSelected ? (<BsCheckCircle/>) : (<BsCircle/>)}</td>*/}
                    <td><BsCircle/></td>
                    <td>{element.invoice_number}</td>
                    <td>{element.user_full_name}</td>
                    <td>${element.total}</td>
                    <td>{element.creation_date}</td>
                    <td>{element.due_date}</td>
                </tr>);
        }

        return (
            <tbody>
            {buffer}
            </tbody>
        );
    }
    get invoiceOutstandingTabTable(){
        return (
            <Table striped hover responsive className={"pt-0 mt-0 mb-0"}>
                <thead>
                <tr>
                    <th> </th>
                    <th>#</th>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Sent Date</th>
                    <th>Due Date</th>
                </tr>
                </thead>
                {this.invoiceOutstandingTabTableData}
            </Table>
        );
    }
    get invoiceOutstandingTab(){
        return (
            <div className={'mt-3'}>
                <Alert variant={'danger'}>Invoice interactions will be coming soon!</Alert>
                <h4 className={'bk_header'}>Outstanding Invoices</h4>
                Outstanding invoices are invoices that have been sent but have not yet marked as paid.
                <div className={'mt-5'}>
                    {this.invoiceOutstandingTabTable}
                </div>
            </div>
        );
    }

    get invoiceModalBody(){
        return (
            <Modal.Body className={'bk_text'}>
                <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                    <Tab eventKey="home" title="Overview">
                        <div className={'mt-3'}>
                            <h4 className={'bk_header'}>Invoice Overview</h4>
                            {this.invoiceTable}
                            <div className={'mt-3 mb-3'}>
                                The above invoice overview table shows all of the invoices that have been entered into the system.
                                They will automatically switch to the different tables when you have marked them as such.
                                Invoices should never been deleted unless you have sent them in error.
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="outstanding" title="Outstanding">
                        {this.invoiceOutstandingTab}
                    </Tab>
                    <Tab eventKey="overdue" title="Overdue">
                        <div className={'mt-3'}>
                            <h4 className={'bk_header'}>Overdue Invoices</h4>
                            Overdue invoices are invoices that have been sent, not been marked as paid, and have passed their due date.
                        </div>
                    </Tab>
                    <Tab eventKey="Paid" title="Paid">
                        <div className={'mt-3'}>
                            <h4 className={'bk_header'}>Paid Invoices</h4>
                            Paid invoices are invoices that have been sent and marked as paid.
                        </div>
                    </Tab>
                    <Tab eventKey="new" title={(<BsFillPlusSquareFill/>)}>
                        {this.invoiceCreateNewTab}
                    </Tab>
                </Tabs>
            </Modal.Body>
        );
    }
    get invoiceModal(){
        return(
            <Modal size={'lg'} show={this.state.invoiceModalShow} onHide={() => this.setState({invoiceModalShow: false})}>
                <Modal.Header closeButton><strong><BsListUl/> Invoice Management</strong></Modal.Header>
                {this.invoiceModalBody}
            </Modal>
        );
    }

    //*** invoiceCard Components
    get invoiceDonutChart(){
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Chart
                    width={'180px'}
                    height={'180px'}
                    chartType="PieChart"
                    loader={<div className="d-flex justify-content-center align-items-center mt-5">
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </div>}
                    data={[['Invoice Type', 'Number'],
                        ['Outstanding', this.state.adminAccountingJSON.total_outstanding_money],
                        ['Overdue', this.state.adminAccountingJSON.total_overdue_money],
                        ['Paid', this.state.adminAccountingJSON.total_paid_money]]}
                    options={{
                        legend: 'none',
                        pieSliceText: 'none',
                        pieStartAngle: 0,
                        pieHole: 0.7,
                        slices: {
                            0: { color: '#f0ad4e' },
                            1: { color: '#d9534f' },
                            2: { color: '#5cb85c'},
                        },
                        chartArea: {
                            width: '100%',
                            height: '95%'
                        },
                    }}
                    rootProps={{ 'data-testid': '6' }}
                />
            </div>
        );
    }
    get invoiceTable(){
        return(
            <Card.Body>
                <Row>
                    <Col sm>
                        {this.invoiceDonutChart}
                    </Col>
                    <Col sm>
                        <Row>
                            <Col sm className={'mt-2 mb-2'}>
                                <Row><Col className={"h2"}>${this.state.adminAccountingJSON.total_invoices_money}</Col></Row>
                                <Row><Col className={'text-primary'}>{this.state.adminAccountingJSON.total_invoices_num} Total Invoices</Col></Row>
                            </Col>
                            <Col sm className={'mt-2 mb-2'}>
                                <Row><Col className={"h2"}>${this.state.adminAccountingJSON.total_outstanding_money}</Col></Row>
                                <Row><Col className={'text-warning'}>{this.state.adminAccountingJSON.total_outstanding_num} Outstanding</Col></Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm className={'mt-2 mb-2'}>
                                <Row><Col className={"h2"}>${this.state.adminAccountingJSON.total_overdue_money}</Col></Row>
                                <Row><Col className={'text-danger'}>{this.state.adminAccountingJSON.total_overdue_num} Overdue</Col></Row>
                            </Col>
                            <Col sm className={'mt-2 mb-2'}>
                                <Row><Col className={"h2"}>${this.state.adminAccountingJSON.total_paid_money}</Col></Row>
                                <Row><Col className={'text-success'}>{this.state.adminAccountingJSON.total_paid_num} Paid</Col></Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        );
    }
    get invoiceCard(){
        return(
            <Card className={'mt-3 mb-3'}>
                <Card.Header>
                    <div>
                        <BsFillPieChartFill/> Accounting | Invoice Overview
                        <Button onClick={() => {this.setState({invoiceModalShow: true})}}
                                className={'float-right'}
                                variant={'secondary'}>
                            <BsListUl/>
                        </Button>
                    </div>
                </Card.Header>
                {this.invoiceTable}
            </Card>
        );
    }

    get accountingInteractionModals(){
        return(
            <div>
                {this.invoiceModal}
            </div>
        );
    }

    render(){
        if (this.props.currentTab !== 3){
            return null;
        }

        if (this.state.adminAccountingJSON == null){
            //Return a spinner until it does not.
            return(
                <div className={"bk_text"}>
                    <Card className={'mt-3 mb-3'}>
                        <Card.Header><BsFillPieChartFill/> Accounting</Card.Header>
                        <Card.Body>
                            <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="lg"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            );
        }
        else {
            return (
                <div className={"bk_text"}>
                    <h1 className={"bk_header mt-3"}> Accounting </h1>
                    {this.accountingInteractionModals}
                    {this.incomeCard}
                    {this.invoiceCard}
                </div>
            );
        }
    }

}