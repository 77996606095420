import React, {useEffect, useState} from 'react';
import {Button, Card} from "react-bootstrap";
import AdminMenuItemsList from "./AdminMenuItemsList";
import {getAdminGiftCardMenus, getAdminGiftCards, getAllItemsAdmin, getAllMenusAdmin} from "../../../utils/ApiHandler";
import AdminAddItemModal from "./Modals/AdminAddItemModal";
import AdminAddWeeklyMenuModal from "./Modals/AdminAddWeeklyMenuModal";
import AdminMenusList from "./AdminMenusList";
import AdminSetPointsModal from "./Modals/AdminSetPointsModal";
import AdminAddGiftCardModal from "./Modals/AdminAddGiftCardModal";
import AdminGiftCardsList from "./AdminGiftCardsList";
import AdminAddGiftCardMenuModal from "./Modals/AdminAddGiftCardMenuModal";
import AdminGiftCardMenusList from "./AdminGiftCardMenusList";

export default function AdminPageMenuV2(props)
{
    const [itemData, setItemData] = useState(null);
    const [menusData, setMenusData] = useState(null);
    const [giftCardsData, setGiftCardsData] = useState(null);
    const [giftCardMenusData, setGiftCardMenusData] = useState(null);

    const [showAddItemModal, setShowAddItemModal] = useState(false);
    const [showAddWeeklyMenuModal, setShowAddWeeklyMenuModal] = useState(false);
    const [showSetPointsModal, setShowSetPointsModal] = useState(false);

    const [showAddGiftCardModal, setShowAddGiftCardModal] = useState(false);
    const [showAddGiftCardMenuModal, setShowAddGiftCardMenuModal] = useState(false);

    const [updateCounter, setUpdateCounter] = useState(0);

    const [errorNotifBody, setErrorNotifBody] = useState(null);

    useEffect(() => {
        console.log("loading data...");
        getAllItemsAdmin().then((response) => {
            if (response.success)
            {
                console.log(response);
                setItemData(response.items);
            }
            else
            {
                setErrorNotifBody(response.error);
            }
        });
        getAllMenusAdmin().then((response) => {
            if (response.success)
            {
                console.log(response);
                setMenusData(response.menus);
            }
            else
            {
                setErrorNotifBody(response.error);
            }
        });
        getAdminGiftCards().then((response) => {
           if (response.success)
           {
               console.log(response);
               setGiftCardsData(response.gift_cards);
           }
           else
           {
               setErrorNotifBody(response.error);
           }
        });
        getAdminGiftCardMenus().then((response) => {
            if (response.success)
            {
                console.log(response);
                setGiftCardMenusData(response.menus);
            }
            else
            {
                setErrorNotifBody(response.error);
            }
        });
    },[updateCounter]);

    if (props.currentTab !== 8)
    {
        return null;
    }

    function updateViewCounter()
    {
        let newUpdateViewCounter = updateCounter + 1;
        setUpdateCounter(newUpdateViewCounter);
    }

    function items()
    {
        return (
            <AdminMenuItemsList
                updateCounter={updateCounter}
                updateViewCounter={updateViewCounter}
                errorNotifBody={errorNotifBody}
                itemData={itemData}
            />
        );
    }

    function menus()
    {
        return (
            <AdminMenusList
                updateCounter={updateCounter}
                updateViewCounter={updateViewCounter}
                menusData={menusData}
                errorNotifBody={errorNotifBody}
            />
        );
    }

    function giftCards()
    {
        return (
            <AdminGiftCardsList
                updateCounter={updateCounter}
                updateViewCounter={updateViewCounter}
                errorNotifBody={errorNotifBody}
                itemData={giftCardsData}
            />
        );
    }

    function giftCardMenus()
    {
        return (
            <AdminGiftCardMenusList
                updateCounter={updateCounter}
                updateViewCounter={updateViewCounter}
                menusData={giftCardMenusData}
                errorNotifBody={errorNotifBody}
            />
        );
    }

    function modals()
    {
        return (
            <>
                <AdminAddItemModal
                    show={showAddItemModal}
                    setShow={setShowAddItemModal}
                    updateViewCounter={updateViewCounter}
                />
                <AdminAddWeeklyMenuModal
                    showModal={showAddWeeklyMenuModal}
                    setShowModal={setShowAddWeeklyMenuModal}
                    updateViewCounter={updateViewCounter}
                    itemData={itemData}
                />
                <AdminSetPointsModal
                    show={showSetPointsModal}
                    setShow={setShowSetPointsModal}
                />
                <AdminAddGiftCardModal
                    show={showAddGiftCardModal}
                    setShow={setShowAddGiftCardModal}
                    updateViewCounter={updateViewCounter}
                />
                <AdminAddGiftCardMenuModal
                    showModal={showAddGiftCardMenuModal}
                    setShowModal={setShowAddGiftCardMenuModal}
                    updateViewCounter={updateViewCounter}
                    itemData={giftCardsData}
                />
            </>
        );
    }

    return (
        <div className="bk_text">
            {modals()}
            <h1 className="bk_header mt-3">menu management</h1>
            <Card>
                <Card.Body>
                    <div className="m-3">
                        <Button variant="outline-dark"
                                onClick={() => setShowSetPointsModal(true)}
                        >set points</Button>
                    </div>
                    <h4>items</h4>
                    <div className="m-3">
                        <Button variant="outline-dark"
                                onClick={() => setShowAddItemModal(true)}
                        >add item</Button>
                    </div>
                    {items()}
                    <h4 className="mt-3">menus</h4>
                    <div className="m-3">
                        <Button variant="outline-dark"
                                onClick={() => setShowAddWeeklyMenuModal(true)}
                        >add weekly menu</Button>
                    </div>
                    {menus()}
                </Card.Body>
            </Card>
            <h1 className="bk_header mt-3">gift card management</h1>
            <Card className="mt-3">
                <Card.Body>
                    <h4>gift cards</h4>
                    <div className="m-3">
                        <Button variant="outline-dark"
                                onClick={() => setShowAddGiftCardModal(true)}
                        >add gift card</Button>
                    </div>
                    {giftCards()}
                    <h4>gift card menus</h4>
                    <div className="m-3">
                        <Button variant="outline-dark"
                                onClick={() => setShowAddGiftCardMenuModal(true)}
                        >add gift card menu</Button>
                    </div>
                    {giftCardMenus()}
                </Card.Body>
            </Card>
        </div>
    );
}