import React, {useState} from "react";
import {Alert, Button, Col, Form, Modal, Spinner} from "react-bootstrap";
import {BsArrowRight} from "react-icons/all";
import {BsExclamationTriangle, BsCheck} from "react-icons/all";
import {postAdminAddGiftCard} from "../../../../utils/ApiHandler";

export default function AdminAddGiftCardModal(props)
{
    const [name, setName] = useState("");
    const [price, setPrice] = useState(0.0);

    const [nameValid, setNameValid] = useState(null);
    const [priceValid, setPriceValid] = useState(null);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

    const [errorNotifBody, setErrorNotifBody] = useState(null);

    function notification()
    {
        if (errorNotifBody != null)
        {
            return (
                <Alert variant="danger">
                    <BsExclamationTriangle/> {errorNotifBody}
                </Alert>
            );
        }
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        if (name === "name")
        {
            setName(value);
            setNameValid(value.length > 0);
        }
        else if (name === "price")
        {
            setPrice(value);
            setPriceValid(value > 0);
        }
    };

    function isValidState(fieldValid)
    {
        return fieldValid != null ? (fieldValid ? 'is-valid' : 'is-invalid') : '';
    }

    const submitClicked = () => {
        setSubmitButtonClicked(true);
        setErrorNotifBody(null);
        if (nameValid == null || priceValid == null)
        {
            setNameValid(false);
            setPriceValid(false);
            setSubmitButtonClicked(false);
        }
        else
        {
            if (nameValid && priceValid)
            {
                let data = {
                    'name': name,
                    'price': price
                };

                postAdminAddGiftCard(data).then((response) => {
                    if (response.success)
                    {
                        setSubmitButtonClicked(false);
                        props.updateViewCounter();
                    }
                    else
                    {
                        setErrorNotifBody(response.error);
                        setSubmitButtonClicked(false);
                    }
                });
            }
            else
            {
                setSubmitButtonClicked(false);
            }
        }
    };

    function submitButton()
    {
        if (submitButtonClicked)
        {
            return (
                <Button
                    block
                    variant="dark"
                >
                    <Spinner animation="border" role="status" variant="light"/>
                </Button>
            );
        }
        else
        {
            return (
                <Button block
                        variant="dark"
                        className="mt-3"
                        onClick={submitClicked}
                >
                    Add Item
                </Button>
            );
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="kitchen_modal_body"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Gift Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="ml-3 mr-3">
                    {notification()}
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>*Gift Card Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter gift card name"
                                name="name"
                                value={name}
                                onChange={onChange}
                                className={isValidState(nameValid)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>*Gift Card Price ($)</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="Enter gift card price"
                                name="price"
                                value={price}
                                onChange={onChange}
                                className={isValidState(priceValid)}
                            />
                        </Form.Group>
                    </Form.Row>
                    {submitButton()}
                </Form>
            </Modal.Body>
        </Modal>
    );
}