import React, {Component} from "react";
import UserInfoModalView from "./UserInfoModalView";
import {Button, ListGroup, Modal} from "react-bootstrap";
import {BsArrowLeft} from "react-icons/all";
import UserInfoModalEdit from "./UserInfoModalEdit";

export default class UserInfoModal extends Component {
    constructor(props)
    {
        super(props);

        this.state = {
            editMode: false
        };
    }

    changeToEdit()
    {
        this.setState({
            editMode: true
        });
    }

    changeToView()
    {
        this.setState({
            editMode: false
        });
    }

    get editButton()
    {
        if (this.state.editMode)
        {
            // back button
            return (
                <Button onClick={() => (this.setState({editMode: false}))}
                        variant={"secondary"}
                        className="float-right">
                    <BsArrowLeft/> Back
                </Button>
            );
        }
        else
        {
            // edit button
            return (
                <Button onClick={() => (this.setState({editMode: true}))}
                        variant={"primary"}
                        className="float-right">
                    Edit
                </Button>
            );
        }
    }

    render()
    {
        if (this.state.editMode)
        {
            // In edit mode, show the edit mode dialog
            return (
                <Modal.Body>
                    {this.editButton}
                    <div className="mt-3">
                        <UserInfoModalEdit
                            user={this.props.user}
                            full_user_info={this.props.full_user_info}
                        />
                    </div>
                </Modal.Body>
            );
        }
        else
        {
            // Not in edit mode, do not show the edit mode dialog
            return (
                <Modal.Body>
                    {this.editButton}
                    <div className="mt-3">
                        <UserInfoModalView
                            user={this.props.user}
                            full_user_info={this.props.full_user_info}
                        />
                    </div>
                </Modal.Body>
            );
        }
    }

}