import React, {useEffect, useState} from "react";
import {Button, Col, Row, ListGroup, Modal, Card, Alert, Spinner} from "react-bootstrap";
import {BsDashCircle, BsPlusCircle} from "react-icons/all";
import {postUserGiftCardMenuPick} from "../../utils/ApiHandler";

export default function UserPageGiftCardMenuPicking(props)
{
    const [picks, setPicks] = useState({});

    const [submitClicked, setSubmitClicked] = useState(false);

    const [moneyUsed, setMoneyUsed] = useState(0);

    const [notifShow, setNotifShow] = useState(false);
    const [notifBody, setNotifBody] = useState(null);

    useEffect(() => {
        if (props.menu != null && props.menu.items != null)
        {
            let currPicks = {};

            for (const item of props.menu.items)
            {
                currPicks[item.name] = 0;

                setPicks(currPicks);
            }
        }
    }, [props.menu]);

    function addToPicks(name, add, price)
    {
        let currPicks = {};
        let currMoneyUsed = moneyUsed;

        for (const itemName in picks)
        {
            if (itemName === name)
            {
                if (add)
                {
                    if (moneyUsed + price <= props.money)
                    {
                        currPicks[itemName] = picks[itemName] + 1;
                        currMoneyUsed += price;
                    }
                    else
                    {
                        currPicks[itemName] = picks[itemName];
                    }
                }
                else
                {
                    if (picks[itemName] - 1 >= 0)
                    {
                        currPicks[itemName] = picks[itemName] - 1;
                        currMoneyUsed -= price;
                    }
                    else
                    {
                        currPicks[itemName] = 0;
                    }
                }
            }
            else
            {
                currPicks[itemName] = picks[itemName];
            }
        }

        setMoneyUsed(currMoneyUsed);
        setPicks(currPicks);
    }

    function wallet()
    {
        return (
            <Card className="mt-2">
                <Card.Body>
                    <Row>
                        <Col>
                            <Row><Col>Used Meal Plan Cash:</Col></Row>
                            <Row><Col><h3>${moneyUsed}</h3></Col></Row>
                        </Col>

                        <Col>
                            <Row><Col>Available Meal Plan Cash:</Col></Row>
                            <Row><Col><h3>${props.money - moneyUsed}</h3></Col></Row>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }

    function giftCardList(list)
    {
        let buffer = [];

        for (const item of list)
        {
            let price = parseInt(item.price);

            buffer.push(
                <ListGroup.Item variant='' className='text-dark' key={item.name}>
                    <Row xs={1} sm={2} md={2} lg={2} className="mt-2 mb-2">
                        <Col>
                            <h3>{item.name}</h3>
                            <h4>${item.price}</h4>
                        </Col>
                        <Col className="text-center">
                            <h2 className={picks[item.name] > 0 ? 'text-success' : 'text-dark'}>
                                <Button variant='' onClick={() => addToPicks(item.name, false, price)}><BsDashCircle size={23}/></Button>
                                {' '} {picks[item.name]} {' '}
                                <Button variant='' onClick={() => addToPicks(item.name, true, price)}><BsPlusCircle size={23}/></Button>
                            </h2>
                        </Col>
                    </Row>
                </ListGroup.Item>
            );
        }

        return (
            <div className="mt-4">
                <div className="text-primary"><h4>Available Gift Cards</h4></div>
                <ListGroup>
                    {buffer}
                </ListGroup>
            </div>
        );
    }

    function validate()
    {
        let valid = true;
        console.log('currentUsed: ' + moneyUsed + ', allowed: ' + props.money);
        valid &= (props.money === moneyUsed);

        console.log('valid: ' + valid);
        return valid;
    }

    const onSubmit = () => {
        setSubmitClicked(true);

        if (validate())
        {
            let body = {
                picks: picks,
                menuName: props.menu.name
            };

            postUserGiftCardMenuPick(body).then(data => {
                setSubmitClicked(false);
                setNotifShow(true);
                setNotifBody(data.error || 'Success!');
            });

            setTimeout(() => {
               setNotifShow(false);
               props.updateChangeTracker();
               props.closeModal();
            }, 3000);
        }
        else
        {
            setSubmitClicked(false);
            setNotifShow(true);
            setNotifBody("Make sure to use all of your points!");
        }
    };

    function notification()
    {
        if (notifShow)
        {
            if (notifBody !== 'Success!'){
                return (
                    <Alert variant={'danger'} className="mt-2">
                        ERROR: {notifBody}
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'success'} className="mt-2">
                        {notifBody} : Your picks have been added.
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }

    function submitButton()
    {
        if (submitClicked)
        {
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else
        {
            return (
                <Button onClick={onSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Submit My Picks
                </Button>
            );
        }
    }


    return (
        <Modal.Body>
            {notification()}
            Please use all of your meal plan cash now. Unused meal plan cash is not carried over to the next quarter.
            {wallet()}
            {giftCardList(props.menu.items)}
            {submitButton()}
        </Modal.Body>
    );
}