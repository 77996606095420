import React, {useEffect, useState} from 'react';
import './App.css';
import {Alert, Nav, Navbar} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes"

import bk_logo from './images/bk_logo@0.5x.png';

function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        setLoggedIn(!!localStorage.getItem('hashed_login_key'));
        setAdmin((localStorage.getItem('rhash') === '21232f297a57a5a743894a0e4a801fc3'));
    }, []);

    function logIn(){
        setLoggedIn(true);
        setAdmin((localStorage.getItem('rhash') === '21232f297a57a5a743894a0e4a801fc3'));
        setShowAlert(true);
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    function logOut(){
        setLoggedIn(false);
        setShowAlert(true);
        localStorage.clear();
        setTimeout(() => {
            setShowAlert(false);
        }, 3000);
    }

    function adminNav(){
        return (
            <Nav className={"ml-auto"}>
                <LinkContainer to="/admin">
                    <Nav.Link>Admin Tools</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/logout'>
                    <Nav.Link>Logout</Nav.Link>
                </LinkContainer>
            </Nav>
        );
    }

    function loggedInNav(){
        return (
            <Nav className={"ml-auto"}>
                <LinkContainer to="/account">
                    <Nav.Link>My Account</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/logout'>
                    <Nav.Link>Logout</Nav.Link>
                </LinkContainer>
            </Nav>
        );
    }

    function notLoggedInNav(){
        return (
            <Nav className={"ml-auto"}>
                <LinkContainer to="/registration">
                    <Nav.Link>Signup</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/login">
                    <Nav.Link>Login</Nav.Link>
                </LinkContainer>
            </Nav>
        );
    }

    function showAlertComp(){
        if (showAlert){
            if (loggedIn){
                return (
                    <Alert variant='success'>
                        You have been logged in!
                    </Alert>
                );
            }
            else {
                return(
                    <Alert variant='success'>
                        You have been logged out!
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }

    return (
        <div className="App">
            {/*{localStorage.getItem('hashed_login_key') ? setLoggedIn(true) : setLoggedIn(false)}*/}
            <Navbar sticky="top" bg="light" expand="lg">
                <Navbar.Brand>
                    <LinkContainer to="/">
                        <img alt=""
                             src={bk_logo}
                             width="50"
                             height="40"
                             className="d-inline-block align-top"
                        />
                    </LinkContainer>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    {loggedIn ? (admin ? adminNav() : loggedInNav()) : notLoggedInNav()}
                </Navbar.Collapse>
            </Navbar>
            {showAlertComp()}
            <Routes logIn={logIn} logOut={logOut}/>
        </div>
    );
}

export default App;
