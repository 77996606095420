import React, {Component} from "react";
import {Navbar, Nav} from "react-bootstrap";
import UserPageAccount from "./UserPageAccount";
import UserPageLegal from "./UserPageLegal";
import UserPageMenu from "./UserPageMenu";
import UserPageMenuV2 from "./UserPageMenuV2";

export default class UserPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 1,
            userInfoJSON: null
        };

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(tab) {
        // console.log(tab);
        this.setState({
            currentTab: tab
        });
    }

    render(){
        return (
            <div className={"bk_text"}>
                <Navbar bg={"light"} expand={"lg"}>
                    <Nav className={"mr-auto"}>
                        <Nav.Link onClick={() => this.handleClick(1)}
                                  className={this.state.currentTab === 1 ? 'font-weight-bold' : ''}>
                            Food Menu
                        </Nav.Link>
                        <Nav.Link onClick={() => this.handleClick(2)}
                                  className={this.state.currentTab === 2 ? 'font-weight-bold' : ''}>
                            Account
                        </Nav.Link>
                        <Nav.Link onClick={() => this.handleClick(3)}
                                  className={this.state.currentTab === 3 ? 'font-weight-bold' : ''}>
                            Legal & Payment
                        </Nav.Link>
                    </Nav>
                </Navbar>
                <UserPageAccount currentTab={this.state.currentTab} />
                <UserPageLegal currentTab={this.state.currentTab} />
                <UserPageMenuV2 currentTab={this.state.currentTab} />
            </div>
        );
    }

}