import React, {useEffect, useState} from "react";
import {Button, ButtonGroup, Card, Col, Jumbotron, Modal, Row, Spinner} from "react-bootstrap";
import {
    getPoints, getUserGiftCardMenuPicksPast,
    getUserGiftCardMenusAvailable, getUserMealPlan,
    getUserMenuPickGetPast,
    getUserMenusAvailable
} from "../../utils/ApiHandler";
import UserPageMenuPickingModal from "./Modals/UserPageMenuPickingModal";
import UserPageMenuPickingV2 from "./UserPageMenuPickingV2";
import {BsCheck, IoReceiptSharp, MdOutlineRestaurantMenu} from "react-icons/all";
import UserOrderCard from "./UserOrderCard";
import UserPageGiftCardMenuPicking from "./UserPageGiftCardMenuPicking";

export default function UserPageMenuV2(props)
{
    const [activeTab, setActiveTab] = useState(0);

    const [menuData, setMenuData] = useState(null);
    const [pointData, setPointData] = useState(null);
    const [ordersData, setOrdersData] = useState(null);
    const [selectedMenu, setSelectedMenu] = useState(null);

    const [giftCardsMenuData, setGiftCardsMenuData] = useState(null);
    const [giftCardOrderData, setGiftCardOrderData] = useState(null);
    const [selectedGiftCardMenu, setSelectedGiftCardMenu] = useState(null);

    const [showPickingModal, setShowPickingModal] = useState(false);
    const [showGiftCardPickingModal, setShowGiftCardPickingModal] = useState(false);

    const [userMealPlan, setUserMealPlan] = useState('150');

    const [changeTracker, setChangeTracker] = useState(0);

    useEffect(() => {
        getUserMenusAvailable().then((response) => {
            if (response.success) {
                setMenuData(response);
            }
        });
        getPoints().then((response) => {
            if (response.success)
            {
                setPointData(response.curr_points);
            }
        });
        getUserMenuPickGetPast().then((response) => {
            if (response.success) {
                setOrdersData(response);
            }
        });
        getUserGiftCardMenusAvailable().then((response) => {
            if (response.success) {
                setGiftCardsMenuData(response);
            }
        });
        getUserGiftCardMenuPicksPast().then((response) => {
            if (response.success) {
                setGiftCardOrderData(response);
            }
        });
        getUserMealPlan().then((response) => {
           if (response.success) {
               setUserMealPlan(response.mealPlan);
           }
        });
    }, [changeTracker]);

    if (props.currentTab !== 1)
    {
        return null;
    }

    function updateChangeTracker()
    {
        let old = changeTracker;
        setChangeTracker(++old);
    }

    function onMenuSelect(name)
    {
        let selected = menuData.menus.find((element) => element.name === name);
        setSelectedMenu(selected);

        console.log(selected);
        if (selected != null)
        {
            setShowPickingModal(true);
        }
    }

    function onGiftCardMenuSelect(name)
    {
        let selected = giftCardsMenuData.menus.find((element) => element.name === name);
        setSelectedGiftCardMenu(selected);

        console.log(selected);
        if (selected != null)
        {
            setShowGiftCardPickingModal(true);
        }
    }

    function availableMenus()
    {
        if (menuData == null) {
            return (
                <div className="text-center">
                    <Spinner variant="dark" size="lg" animation={true}/>
                </div>
            );
        }

        let buffer = [];

        for (const element of menuData.menus) {
            let date = new Date(element.firstDelivery);
            console.log(date);
            let firstDelivery = ((date.getUTCMonth() > 8) ? (date.getUTCMonth() + 1) : ('0' + (date.getUTCMonth() + 1))) + '/' + ((date.getUTCDate() > 9) ? date.getUTCDate() : ('0' + date.getUTCDate())) + '/' + date.getUTCFullYear();

            date = new Date(element.secondDelivery);
            let secondDelivery = ((date.getUTCMonth() > 8) ? (date.getUTCMonth() + 1) : ('0' + (date.getUTCMonth() + 1))) + '/' + ((date.getUTCDate() > 9) ? date.getUTCDate() : ('0' + date.getUTCDate())) + '/' + date.getUTCFullYear();

            let index = 0;

            let picked = element.picked ? (<h3><BsCheck className="text-success"/> Meals have been picked!</h3>) : null;

            buffer.push(
                <Card bg="secondary" className="text-white mt-2" key={index}>
                    <Card.Body>
                        {picked}
                        menu for delivery on:
                        <Row>
                            <Col>
                                <h2>monday</h2>
                                <h1>{firstDelivery}</h1>
                            </Col>
                            <Col>
                                <h2>thursday</h2>
                                <h1>{secondDelivery}</h1>
                            </Col>
                        </Row>
                        <Button
                            block
                            onClick={() => onMenuSelect(element.name)}
                            className="mt-2"
                            disabled={element.picked}
                            variant="light">
                            Pick meals for delivery
                        </Button>
                    </Card.Body>
                </Card>
            );
            index++;
        }

        if (buffer.length === 0)
        {
            return (
                <div>
                    <h3>available menus</h3>
                    <Row className="text-dark mt-2">
                        <Col>
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Title>There aren't any menus that are currently available. Please check back later!</Card.Title>
                                    <br/><br/>
                                    <div className="text-center">
                                        <MdOutlineRestaurantMenu size={80}/>
                                    </div>
                                    <br/><br/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <div>
                <h3>available menus</h3>
                {buffer}
            </div>
        );
    }

    function availableGiftCardMenus() {
        if (giftCardsMenuData == null) {
            return null;
        }

        let buffer = [];

        for (const element of giftCardsMenuData.menus) {
            let index = 0;

            let picked = element.picked ? (<h3><BsCheck className="text-success"/> Gift cards have been picked!</h3>) : null;

            buffer.push(
                <Card bg="primary" className="text-white mt-2" key={index}>
                    <Card.Body>
                        {picked}
                        gift cards for delivery:
                        <h1>next week</h1>
                        <Button
                            block
                            onClick={() => onGiftCardMenuSelect(element.name)}
                            className="mt-2"
                            disabled={element.picked}
                            variant="light">
                            Pick gift cards
                        </Button>
                    </Card.Body>
                </Card>
            );
            index++;
        }

        if (buffer.length === 0)
        {
            return null;
        }

        return (
            <div>
                <h3 className="mt-5">available gift cards</h3>
                {buffer}
            </div>
        );
    }

    function pastOrders()
    {
        if (ordersData == null || giftCardOrderData == null)
        {
            return (
                <div className="text-center">
                    <Spinner variant="dark" size="lg" animation={true}/>
                </div>
            );
        }

        let numPicks = ordersData.numPicks;

        if (numPicks === 0)
        {
            return (
                <div>
                    <h3>past orders</h3>
                    <Row className="text-dark mt-2">
                        <Col>
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Title>Looks like you haven't made any picks in the last 2 months!</Card.Title>
                                    <br/><br/>
                                    <div className="text-center">
                                        <IoReceiptSharp size={80}/>
                                    </div>
                                    <br/><br/>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            );
        }

        let buffer = [];

        for (const element of ordersData.picks)
        {
            buffer.push(
                <UserOrderCard pick={element} giftCards={false}/>
            );
        }

        for (const element of giftCardOrderData.picks)
        {
            buffer.push(
                <UserOrderCard pick={element} giftCards={true}/>
            );
        }

        return (
            <div>
                <h3>past orders (last 2 months)</h3>
                <Row xs={1} sm={3} md={3} lg={3}>
                    {buffer}
                </Row>
            </div>
        );

    }


    function foodModal()
    {
        if (selectedMenu == null) {
            return null;
        }
        let numPointsAllowed = userMealPlan === '150' ? pointData.points150 : pointData.points175;

        return (
            <>
                <Modal size={'xl'} show={showPickingModal} onHide={() => setShowPickingModal(false)}>
                    <Modal.Header closeButton>
                        <strong>Pick your Food</strong>
                    </Modal.Header>
                    <UserPageMenuPickingV2
                        menu={selectedMenu}
                        numPointsAllowed={numPointsAllowed}
                        updateChangeTracker={updateChangeTracker}
                        closeModal={() => setShowPickingModal(false)}
                    />
                </Modal>
            </>
        );
    }

    function giftCardModal()
    {
        if (selectedGiftCardMenu == null) {
            return null;
        }

        return (
            <Modal size={'xl'} show={showGiftCardPickingModal} onHide={() => setShowGiftCardPickingModal(false)}>
                <Modal.Header closeButton>
                    <strong>Pick your Gift Cards</strong>
                </Modal.Header>
                <UserPageGiftCardMenuPicking
                    menu={selectedGiftCardMenu}
                    money={150}
                    updateChangeTracker={updateChangeTracker}
                    closeModal={() => setShowGiftCardPickingModal(false)}
                />
            </Modal>
        );
    }

    function currTab()
    {
        if (activeTab === 0)
        {
            return (
                <div>
                    {availableMenus()}
                    {availableGiftCardMenus()}
                </div>
            );
        }
        else if (activeTab === 1)
        {
            return (
                <div>
                    {pastOrders()}
                </div>
            );
        }
    }

    return (
        <>
            <div>
                <Jumbotron className={'mt-3'}>
                    <h1>Beta Kitchen Menu</h1>
                    <p>
                        Welcome to the new way to pick your food. Click the buttons below to see menus you can order off
                        of or any of your past orders. You can pick whatever food you want as long as you stay below the allotted
                        points!
                    </p>
                    <ButtonGroup className={'btn-block'}>
                        <Button active={activeTab === 0}
                                onClick={() => setActiveTab(0)}
                                className={'btn-span'}>
                            Available Menus
                        </Button>
                        <Button active={activeTab === 1}
                                onClick={() => setActiveTab(1)}
                                className={'btn-span'}>
                            Past Orders
                        </Button>
                    </ButtonGroup>
                </Jumbotron>
                {foodModal()}
                {giftCardModal()}
                {currTab()}
            </div>
        </>
    );
}