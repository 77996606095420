import React, {Component} from "react";
import {Alert, Card, Col, Form, ListGroup, Row, Spinner} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

export default class RegistrationStep5 extends Component{

    constructor(props) {
        super(props);

        this.state = {
            numPrefsPicked: 0,
            currentPricesJSON: null
        };

        this.handleChangePref = this.handleChangePref.bind(this);
        this.handleChangeMealPlan = this.handleChangeMealPlan.bind(this);
        this.onCaptchaChange = this.onCaptchaChange.bind(this)
    }

    updateCurrentPricesJSON(){
        let url = encodeURI('/api/getMealPlanCosts');

        fetch(url).then(res => res.json()).then(data => {this.setState({
            currentPricesJSON: data
        })});
    }
    componentDidMount() {
        this.updateCurrentPricesJSON();
    }

    get unvalidatedAlert(){
        if (this.props.form4Validated != null){
            if (this.props.form4Validated === false){
                return (
                    <Alert variant='danger'>
                        Please completely fill out the below form correctly. Incorrect portions are marked.
                    </Alert>
                );
            }
        }
        return null;
    }

    handleChangePref(event){
        const {checked} = event.target;
        let numPrefsPicked = this.state.numPrefsPicked;
        if (checked === true){
            ++numPrefsPicked;
            if (numPrefsPicked > 0){
                this.props.handlePropsChange('prefsValid', true);
            }
        }
        else {
            --numPrefsPicked;
            if (numPrefsPicked === 0){
                this.props.handlePropsChange('prefsValid', false);
            }
        }
        this.setState({
            numPrefsPicked: numPrefsPicked
        });
        this.props.handleChange(event);
    }

    handleChangeMealPlan(event){
        const {value} = event.target;
        if (value > 0){
            this.props.handlePropsChange('mealPlanValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('mealPlanValid', false);
        }
    }

    handleIsValid(fieldName){
        return this.props[fieldName] != null ? (this.props[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }

    onCaptchaChange(value){
        console.log(value);
        this.props.handlePropsChange('captcha_key', value);
    }

    get mealPlanCards(){
        if (this.state.currentPricesJSON == null){
            return (
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }

        if (!this.state.currentPricesJSON.success){
            return null;
        }

        let mealPlan150Cost = this.state.currentPricesJSON.meal_plan_150_cost;
        let mealPlan175Cost = this.state.currentPricesJSON.meal_plan_175_cost;

        return (
            <Row className={"mb-2"}>
                <Col sm className={"mt-2 mb-2 bk_text"}>
                    <Card>
                        <Card.Body>
                            <Card.Title className={"bk_header"}>150 Block Plan</Card.Title>
                            <ListGroup className={"list-group-flush"}>
                                <ListGroup.Item className={"list-group-item-primary font-weight-bold"}>
                                    ${(mealPlan150Cost).toFixed(2)} per quarter
                                </ListGroup.Item>
                                <ListGroup.Item>~15 meals per week (3 breakfasts)</ListGroup.Item>
                                <ListGroup.Item>~150 Total Meals</ListGroup.Item>
                                <ListGroup.Item>$150 Fun Cash per quarter</ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                <br/>
                                The 150 Block Plan offers approximately 15 meals per week and is designed for students who
                                like flexibility on when they wish to eat and will be on campus most weekends.
                                The $150 in Fun Cash per quarter offers more additional cash to spend at restaurants, convenience stores, and coffee shops surrounding the DU community.
                                <br/><br/>
                                This price includes sales tax.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm className={"mt-2 mb-2 bk_text"}>
                    <Card>
                        <Card.Body>
                            <Card.Title className={"bk_header"}>175 Block Plan</Card.Title>
                            <ListGroup className={"list-group-flush"}>
                                <ListGroup.Item className={"list-group-item-primary font-weight-bold"}>
                                    ${(mealPlan175Cost).toFixed(2)} per quarter
                                </ListGroup.Item>
                                <ListGroup.Item>~17 meals per week (5 breakfasts)</ListGroup.Item>
                                <ListGroup.Item>~175 Total Meals</ListGroup.Item>
                                <ListGroup.Item>$150 Fun Cash per quarter</ListGroup.Item>
                            </ListGroup>
                            <Card.Text>
                                <br/>
                                The 175 Block Plan offers approximately 17 meals per week and is designed for students who
                                like flexibility on when they wish to eat and will be on campus most, if not all, weekends.
                                The $150 in Fun Cash per quarter offers more additional cash to spend at restaurants, convenience stores, and coffee shops surrounding the DU community.
                                <br/><br/>
                                This price includes sales tax.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        );
    }

    render() {
        if (this.props.currentStep !== 5){
            return null;
        }

        return (
            <div>
                {this.unvalidatedAlert}
                <Card className="bk_text">
                    <Card.Header className="bk_header">
                        <strong>Account Registration (5/5)</strong>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title className="bk_header">
                            Meal Plan, Preferences, and Allergies
                        </Card.Title>
                        {this.mealPlanCards}
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>*Meal Plan</Form.Label>
                                <Form.Control
                                    required
                                    as="select"
                                    name="mealPlan"
                                    onChange={this.handleChangeMealPlan}
                                    className={this.handleIsValid('mealPlanValid')}
                                    custom>
                                    <option value={-100} selected>Choose...</option>
                                    <option value={150}>150 Block Meal Plan</option>
                                    <option value={175}>175 Block Meal Plan</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">Please select a valid meal plan.</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Card.Text>
                            Please fill out your food preferences and allergies below.
                        </Card.Text>
                        <Form className="m-0 align">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Allergies</Form.Label>
                                    <Form.Check type='checkbox' name='glutenAllergy' label='Gluten' onChange={this.props.handleChange}/>
                                    <Form.Check type='checkbox' name='peanutAllergy' label='Peanuts' onChange={this.props.handleChange}/>
                                    <Form.Check type='checkbox' name='dairyAllergy' label='Dairy' onChange={this.props.handleChange}/>
                                    <Form.Check type='checkbox' name='treenutAllergy' label='Tree Nuts' onChange={this.props.handleChange}/>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Other Allergy?</Form.Label>
                                    <Form.Control type="text" placeholder="Enter other allergy if needed" name="otherAllergy" onChange={this.props.handleChange}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label className={this.handleIsValid('prefsValid')}>Protein Preferences</Form.Label>
                                    <Form.Check type='checkbox' name='likesChicken' label='Chicken' onChange={this.handleChangePref}/>
                                    <Form.Check type='checkbox' name='likesTurkey' label='Ground Turkey' onChange={this.handleChangePref}/>
                                    <Form.Check type='checkbox' name='likesBeef' label='Ground Beef' onChange={this.handleChangePref}/>
                                    <Form.Check type='checkbox' name='likesPork' label='Pork' onChange={this.handleChangePref}/>
                                    <Form.Check type='checkbox' name='likesSteak' label='Steak' onChange={this.handleChangePref}/>
                                    <Form.Check type='checkbox' name='likesShrimp' label='Shrimp' onChange={this.handleChangePref}/>
                                    <Form.Check type='checkbox' name='likesSalmon' label='Salmon' onChange={this.handleChangePref}/>
                                    <Form.Check type='checkbox' name='likesTilapia' label='Tilapia' onChange={this.handleChangePref}/>
                                    <Form.Text id="prefHelpBlock" muted>
                                        Pick as many as you like, however you must pick at least 1 option. Picking an option does not mean you will always get it, it just means each week if that option is available, you will be offered it.
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good! Pick as many as you'd like.</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">You must pick at least 1 protein type.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <Card.Text>
                            All carb and veggie options will be offered to everybody unless either contain one of your chosen allergies.
                            <br />
                            Each week you will be able to pick how many ounces of protein and carbs you recieve from a list of available options. Not all options are available each week. The menu for the next week will be available a week beforehand for you to pick from.
                        </Card.Text>
                        <ReCAPTCHA
                            sitekey={"6LfbqLAZAAAAAGrtXdfES8lboyGxxvPP0engFctR"}
                            onChange={this.onCaptchaChange}
                        />
                        <Card.Text>
                            Fields marked with an * are required.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        We commit and promise to not share any of your personal information with any other third parties.
                    </Card.Footer>
                </Card>
            </div>
        );
    }

}