import React, { Component } from "react";
import {Alert, Card, Col, Form} from "react-bootstrap";

export default class RegistrationStep3 extends Component {

    constructor(props) {
        super(props);

        this.handleIsValid = this.handleIsValid.bind(this);

        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeAddress1 = this.handleChangeAddress1.bind(this);
        this.handleChangeAddress2 = this.handleChangeAddress2.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleChangeZIP = this.handleChangeZIP.bind(this);

    }

    handleChangeLastName(event){
        const {value} = event.target;
        if (value.length === 0){
            this.props.handlePropsChange('parentLastNameValid', false);
        }
        else if (value.match("^[a-zA-z]*$") != null){
            this.props.handlePropsChange('parentLastNameValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('parentLastNameValid', false);
        }
    }

    handleChangeFirstName(event){
        const {value} = event.target;
        if (value.length === 0){
            this.props.handlePropsChange('parentFirstNameValid', false);
        }
        else if (value.match("^[a-zA-z]*$") != null){
            this.props.handlePropsChange('parentFirstNameValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('parentFirstNameValid', false);
        }
    }

    handleChangeEmail(event){
        const {value} = event.target;
        let emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (value.length === 0){
            this.props.handlePropsChange('parentEmailValid', false);
        }
        else if (emailPattern.test(value)){
            this.props.handlePropsChange('parentEmailValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('parentEmailValid', false);
        }
    }

    handleChangeAddress1(event){
        const {value} = event.target;
        let address1Pattern = /^[a-zA-Z0-9\s,.#'-]{3,}$/;
        if (value.length === 0){
            this.props.handlePropsChange('parentAddress1Valid', false);
        }
        else if (address1Pattern.test(value)){
            this.props.handlePropsChange('parentAddress1Valid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('parentAddress1Valid', false);
        }
    }

    handleChangeAddress2(event){
        const {value} = event.target;
        let address2Pattern = /^[a-zA-Z0-9\s,.#'-]+$/;
        if (value.length === 0){
            this.props.handlePropsChange('parentAddress2Valid', null);
            this.props.handleChange(event);
        }
        else if (address2Pattern.test(value)){
            this.props.handlePropsChange('parentAddress2Valid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('parentAddress2Valid', false);
        }
    }

    handleChangeCity(event){
        const {value} = event.target;
        let city_pattern = /^[a-zA-z\s,.]{2,}$/;
        if (value.length === 0){
            this.props.handlePropsChange('parentCityValid', false);
        }
        else if (value.match(city_pattern) != null){
            this.props.handlePropsChange('parentCityValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('parentCityValid', false);
        }
    }

    handleChangeState(event){
        const {value} = event.target;
        if (value != null){
            this.props.handlePropsChange('parentStateValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('parentStateValid', false);
        }
    }

    handleChangeZIP(event){
        const {value} = event.target;
        let zipPattern = /^\d{5}$|^\d{5}-\d{4}$/;
        if (value.length === 0){
            this.props.handlePropsChange('parentZIPValid', false);
        }
        else if (zipPattern.test(value)){
            this.props.handlePropsChange('parentZIPValid', true);
            this.props.handleChange(event);
        }
        else{
            this.props.handlePropsChange('parentZIPValid', false);
        }
    }

    handleIsValid(fieldName){
        return this.props[fieldName] != null ? (this.props[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }

    get unvalidatedAlert(){
        if (this.props.form2Validated != null){
            if (this.props.form2Validated === false){
                return (
                    <Alert variant='danger'>
                        Please completely fill out the below form correctly. Incorrect portions are marked.
                    </Alert>
                );
            }
        }
        return null;
    }

    render(){
        if (this.props.currentStep !== 3){
            return null;
        }

        return(
            <div>
                {this.unvalidatedAlert}
                <Card className="bk_text">
                    <Card.Header className="bk_header">
                        <strong>Account Registration (3/5)</strong>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title className="bk_header">
                            Parent/Guardian Information
                        </Card.Title>
                        <Form className="m-0 align">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>*Parent/Guardian First Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter first name"
                                                  name="parentFirstName"
                                                  className = {this.handleIsValid('parentFirstNameValid')}
                                                  onChange={this.handleChangeFirstName}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid first name.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>*Parent/Guardian Last Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter last name"
                                                  name="parentLastName"
                                                  className={this.handleIsValid('parentLastNameValid')}
                                                  onChange={this.handleChangeLastName}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid last name.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>*Parent/Guardian Email Address</Form.Label>
                                    <Form.Control required type="email" placeholder="Enter email address"
                                                  name="parentEmail"
                                                  className={this.handleIsValid('parentEmailValid')}
                                                  onChange={this.handleChangeEmail}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid email.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>*Parent/Guardian Address</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter address"
                                                  name="parentAddress1"
                                                  className={this.handleIsValid('parentAddress1Valid')}
                                                  onChange={this.handleChangeAddress1}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid address.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Parent/Guardian Address 2</Form.Label>
                                    <Form.Control type="text" placeholder="Enter address 2 (not required)"
                                                  name="parentAddress2"
                                                  className={this.handleIsValid('parentAddress2Valid')}
                                                  onChange={this.handleChangeAddress2}/>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>*City</Form.Label>
                                    <Form.Control type="text" placeholder="Enter city"
                                                  name="parentCity"
                                                  className={this.handleIsValid('parentCityValid')}
                                                  onChange={this.handleChangeCity}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid city.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>*State</Form.Label>
                                    <Form.Control
                                        required
                                        as="select"
                                        className={this.handleIsValid('parentStateValid')}
                                        name="parentState"
                                        onChange={this.handleChangeState}
                                        custom>
                                        <option value={null} selected>Choose...</option>
                                        <option>AL</option>
                                        <option>AK</option>
                                        <option>AS</option>
                                        <option>AZ</option>
                                        <option>AR</option>
                                        <option>CA</option>
                                        <option>CO</option>
                                        <option>CT</option>
                                        <option>DE</option>
                                        <option>DC</option>
                                        <option>FM</option>
                                        <option>FL</option>
                                        <option>GA</option>
                                        <option>GU</option>
                                        <option>HI</option>
                                        <option>ID</option>
                                        <option>IL</option>
                                        <option>IN</option>
                                        <option>IA</option>
                                        <option>KS</option>
                                        <option>KY</option>
                                        <option>LA</option>
                                        <option>ME</option>
                                        <option>MH</option>
                                        <option>MD</option>
                                        <option>MA</option>
                                        <option>MI</option>
                                        <option>MN</option>
                                        <option>MS</option>
                                        <option>MO</option>
                                        <option>MT</option>
                                        <option>NE</option>
                                        <option>NV</option>
                                        <option>NH</option>
                                        <option>NJ</option>
                                        <option>NM</option>
                                        <option>NY</option>
                                        <option>NC</option>
                                        <option>ND</option>
                                        <option>MP</option>
                                        <option>OH</option>
                                        <option>OK</option>
                                        <option>OR</option>
                                        <option>PW</option>
                                        <option>PA</option>
                                        <option>PR</option>
                                        <option>RI</option>
                                        <option>SC</option>
                                        <option>SD</option>
                                        <option>TN</option>
                                        <option>TX</option>
                                        <option>UT</option>
                                        <option>VT</option>
                                        <option>VI</option>
                                        <option>VA</option>
                                        <option>WA</option>
                                        <option>WV</option>
                                        <option>WI</option>
                                        <option>WY</option>
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">Please select a valid state.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>*ZIP Code</Form.Label>
                                    <Form.Control type="num" placeholder="Enter ZIP"
                                                  name="parentZIP"
                                                  className={this.handleIsValid('parentZIPValid')}
                                                  onChange={this.handleChangeZIP}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid ZIP code.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                        <Card.Text>
                            Fields marked with an * are required.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        We commit and promise to not share any of your parent/guardian information with any other third parties.
                    </Card.Footer>
                </Card>
            </div>
        );
    }

}