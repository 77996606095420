import React, {Component} from "react";
import RegistrationStep0 from "./RegistrationStep0";
import RegistrationStep2 from "./RegistrationStep2";
import RegistrationStep3 from "./RegistrationStep3";
import RegistrationStep4 from "./RegistrationStep4";
import RegistrationStep5 from "./RegistrationStep5";
import RegistrationStep6 from "./RegistrationStep6";
import {Button, Card, Spinner} from "react-bootstrap";
import RegistrationStep1 from "./RegistrationStep1";

let passwordHash = require('password-hash');

export default class MasterForm extends Component {

    constructor(props){
        super(props);
        //initial values
        // eslint-disable-next-line
        this.state = {
            //reg_open json response
            regOpenJSON: null,
            currentStep: 0,
            year: '',
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            ZIP: '',
            parentFirstName: '',
            parentLastName: '',
            parentEmail: '',
            parentAddress1: '',
            parentAddress2: '',
            parentCity: '',
            parentState: '',
            parentZIP: '',
            unitNum: '',
            signature: '',
            parentSignature: '',
            password: '',
            passwordVerify: '',
            mealPlan: null,
            glutenAllergy: false,
            peanutAllergy: false,
            dairyAllergy: false,
            treenutAllergy: false,
            otherAllergy: '',
            likesChicken: false,
            likesTurkey: false,
            likesBeef: false,
            likesPork: false,
            likesSteak: false,
            likesShrimp: false,
            likesSalmon: false,
            likesTilapia: false,
            form1Validated: null,
            form2Validated: null,
            form3Validated: null,
            form4Validated: null,
            form5Validated: null,
            firstNameValid: null,
            lastNameValid: null,
            emailValid: null,
            phoneNumberValid: null,
            unitNumValid: null,
            parentFirstNameValid: null,
            parentLastNameValid: null,
            parentEmailValid: null,
            parentAddress1Valid: null,
            parentAddress2Valid: null,
            parentCityValid: null,
            parentStateValid: null,
            parentZIPValid: null,
            signatureValid: null,
            parentSignatureValid: null,
            passwordValid: null,
            passwordVerifyValid: null,
            prefsValid: null,
            mealPlanValid: null,
            success: false,
            captcha_key: null,
            error: '',
            submitClicked: false,
            nextClicked: false,
        };

        this.handlePropsChange = this.handlePropsChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this._next = this._next.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.switchPasswordToHash = this.switchPasswordToHash.bind(this);

        this.validateForm1 = this.validateForm1.bind(this);
        this.validateForm2 = this.validateForm2.bind(this);

        this.setDeliveryAddressInfo = this.setDeliveryAddressInfo.bind(this);
    }

    componentDidMount() {
        fetch('/api/registration/checkAllowed').then(res => res.json()).then(data => {this.setState({
            regOpenJSON: data
        })});
    }

    validateForm1() {
        let yearValid = this.state.year !== '';

        this.setState({
            form1Validated: yearValid
        });
    }

    setDeliveryAddressInfo(addr1, addr2, city, state, zip){
        this.setState({
           address1: addr1,
           address2: addr2,
           city: city,
           state: state,
           ZIP: zip
        });
    }

    validateForm2() {
        console.log('validateForm2 called');
        let firstNameValid = this.state.firstNameValid == null ? false : this.state.firstNameValid;
        let lastNameValid = this.state.lastNameValid == null ? false : this.state.lastNameValid;
        let emailValid = this.state.emailValid == null ? false : this.state.emailValid;
        let unitNumValid = this.state.unitNumValid == null ? false : this.state.unitNumValid;
        let phoneNumberValid = this.state.phoneNumberValid == null ? false : this.state.phoneNumberValid;
        let passValid = this.state.passwordValid == null ? false : this.state.passwordValid;
        let passVerifyValid = this.state.passwordVerifyValid == null ? false : this.state.passwordVerifyValid;

        let form2Valid = firstNameValid && lastNameValid && emailValid && unitNumValid
            && phoneNumberValid && passValid && passVerifyValid;

        let form2Validated = form2Valid == null ? false : form2Valid;

        this.setState({
            firstNameValid: firstNameValid,
            lastNameValid: lastNameValid,
            emailValid: emailValid,
            unitNumValid: unitNumValid,
            phoneNumberValid: phoneNumberValid,
            passwordValid: passValid,
            passwordVerifyValid: passVerifyValid,
            form2Validated: form2Validated
        });
    }

    validateForm3() {
        let firstNameValid = this.state.parentFirstNameValid == null ? false : this.state.parentFirstNameValid;
        let lastNameValid = this.state.parentLastNameValid == null ? false : this.state.parentLastNameValid;
        let emailValid = this.state.parentEmailValid == null ? false : this.state.parentEmailValid;
        let addressValid = this.state.parentAddress1Valid == null ? false : this.state.parentAddress1Valid;
        let cityValid = this.state.parentCityValid == null ? false : this.state.parentCityValid;
        let stateValid = this.state.parentStateValid == null ? false : this.state.parentStateValid;
        let zipValid = this.state.parentZIPValid == null ? false : this.state.parentZIPValid;

        let form3Valid = firstNameValid && lastNameValid && emailValid && addressValid && cityValid
            && stateValid && zipValid;

        let form3Validated = form3Valid == null ? false : form3Valid;

        this.setState({
            parentFirstNameValid: firstNameValid,
            parentLastNameValid: lastNameValid,
            parentEmailValid: emailValid,
            parentAddress1Valid: addressValid,
            parentCityValid: cityValid,
            parentStateValid: stateValid,
            parentZIPValid: zipValid,
            form3Validated: form3Validated
        });
    }

    validateForm4() {
        let signatureValid = this.state.signatureValid == null ? false : this.state.signatureValid;

        let form4Validated = signatureValid == null ? false : signatureValid;

        this.setState({
            signatureValid: signatureValid,
            form4Validated: form4Validated
        })
    }

    validateForm5() {
        let prefsValid = this.state.prefsValid == null ? false : this.state.prefsValid;
        let mealPlanValid = this.state.mealPlanValid == null ? false : this.state.mealPlanValid;
        let captchaValid = this.state.captcha_key !== null;

        let form5Valid = prefsValid && mealPlanValid && captchaValid;

        let form5Validated = form5Valid == null ? false : form5Valid;

        this.setState({
            prefsValid: prefsValid,
            mealPlanValid: mealPlanValid,
            form5Validated: form5Validated
        });
    }

    _next(){
        let currentStep = this.state.currentStep;
        this.setState({
            nextClicked: true
        });

        switch (currentStep){
            case 1:
                this.validateForm1();
                break;
            case 2:
                this.validateForm2();
                break;
            case 3:
                this.validateForm3();
                break;
            case 4:
                this.validateForm4();
                break;
            default:
                break;
        }

        setTimeout(() => {
            let form1Valid = this.state.form1Validated;
            let form2Valid = this.state.form2Validated;
            let form3Valid = this.state.form3Validated;
            let form4Valid = this.state.form4Validated;

            this.setState({
                nextClicked: false
            });

            // console.log('form1Valid: ' + form1Valid);
            switch (currentStep){
                case 0:
                    ++currentStep;
                    this.setState({
                        currentStep: currentStep
                    });
                    break;
                case 1:
                    if (form1Valid){
                        ++currentStep;
                        this.setState({
                            currentStep: currentStep
                        });
                    }
                    break;
                case 2:
                    if (form2Valid){
                        ++currentStep;
                        this.setState({
                            currentStep: currentStep
                        });
                    }
                    break;
                case 3:
                    if (form3Valid){
                        ++currentStep;
                        this.setState({
                            currentStep: currentStep
                        });
                    }
                    break;
                case 4:
                    if (form4Valid){
                        ++currentStep;
                        this.setState({
                            currentStep: currentStep
                        });
                    }
                    break;
                default:
                    break;
            }}, 1000);
    }

    get submitButton(){
        let currentStep = this.state.currentStep;
        if (currentStep === 5){
            if (this.state.submitClicked){
                return (
                    <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </Button>
                );
            }
            else {
                return (
                    <Button className="btn btn-primary float-right mt-2 mb-2" type="submit">
                        Submit
                    </Button>
                );
            }
        }
        else {
            return null;
        }
    }

    get nextButton(){
        if (!this.state.regOpenJSON.value){
            return null;
        }
        let currentStep = this.state.currentStep;
        if (currentStep < 5){
            if (this.state.nextClicked){
                return (
                    <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </Button>
                );
            }
            else {
                return (
                    <button className="btn btn-primary float-right mt-2 mb-2" type="button" onClick={this._next}>
                        Next
                    </button>
                );
            }
        }
        else {
            return null;
        }
    }

    handlePropsChange(name, value){
        this.setState({
            [name]: value
        });
    }

    handleChange(event){
        const {name, value, checked} = event.target;
        if (value === 'on'){
            // console.log('changing ' + name + ' to ' + checked);
            this.setState({
                [name]: checked
            });
        }
        else {
            this.setState({
                [name]: value
            });
        }
    }

    switchPasswordToHash(){
        let plaintext = this.state.password;
        let hashedPassword = passwordHash.generate(plaintext, {'algorithm': 'sha256'});
        console.log('hashedPassword: ' + hashedPassword);

        this.setState({
            password: hashedPassword,
            passwordVerify: hashedPassword
        });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.validateForm5();
        // this.switchPasswordToHash();
        this.setState({
            submitClicked: true
        });

        setTimeout(() => {
            let form5Valid = this.state.form5Validated;
            if (form5Valid){
                // console.log('handle submit!!!!');
                this.postData('/api/registration', this.state).then(data => this.setState({
                    success: data.success,
                    currentStep: 6,
                    error: data.error !== null ? data.error : ''
                }));
            }
            else {
                // console.log('submit blocked');
                this.setState({
                    submitClicked: false
                });
            }
        }, 1000);

    };

    async postData(url, data){
        const response = await fetch(url, {
            method: 'POST',
            mode: 'same-origin',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow',
            body: JSON.stringify(data)
        });
        return response.json();
    }

    //render
    render(){
        if (this.state.regOpenJSON == null){
            return(
                <Card className={"bk_text"}>
                    <Card.Header className="bk_header">
                        <strong>Account Registration</strong>
                    </Card.Header>
                    <Card.Body>
                        <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                            <Spinner
                                as="span"
                                animation="border"
                                size="lg"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">Loading...</span>
                        </div>
                    </Card.Body>
                </Card>
            );
        }

        let nextYear = '2024-2025';

        return (
            <div>
                <form onSubmit={this.handleSubmit}>
                    <RegistrationStep0
                        currentStep = {this.state.currentStep}
                        regOpen = {this.state.regOpenJSON.value}
                    />
                    <RegistrationStep1
                        currentStep={this.state.currentStep}
                        currentYear={nextYear}
                        nextYear={nextYear}
                        year={this.state.year}
                        handlePropsChange={this.handlePropsChange}
                        form1Validated={this.state.form1Validated}
                    />
                    <RegistrationStep2
                        currentStep = {this.state.currentStep}
                        handleChange = {this.handleChange}
                        handlePropsChange = {this.handlePropsChange}
                        setDeliveryAddressInfo={this.setDeliveryAddressInfo}
                        currentYear={this.state.year === nextYear}
                        firstName = {this.state.firstName}
                        lastName = {this.state.lastName}
                        email = {this.state.email}
                        phoneNumber = {this.state.phoneNumber}
                        unitNum = {this.state.unitNum}
                        password = {this.state.password}
                        passwordVerify = {this.state.passwordVerify}
                        firstNameValid = {this.state.firstNameValid}
                        lastNameValid = {this.state.lastNameValid}
                        emailValid = {this.state.emailValid}
                        phoneNumberValid = {this.state.phoneNumberValid}
                        unitNumValid = {this.state.unitNumValid}
                        passwordValid = {this.state.passwordValid}
                        passwordVerifyValid = {this.state.passwordVerifyValid}
                        form1Validated = {this.state.form2Validated}
                    />
                    <RegistrationStep3
                        currentStep = {this.state.currentStep}
                        handleChange = {this.handleChange}
                        handlePropsChange = {this.handlePropsChange}
                        parentFirstName = {this.state.parentFirstName}
                        parentLastName = {this.state.parentLastName}
                        parentEmail = {this.state.parentEmail}
                        parentAddress1 = {this.state.parentAddress1}
                        parentAddress2 = {this.state.parentAddress2}
                        parentCity = {this.state.parentCity}
                        parentState = {this.state.parentState}
                        parentZIP = {this.state.parentZIP}
                        parentFirstNameValid = {this.state.parentFirstNameValid}
                        parentLastNameValid = {this.state.parentLastNameValid}
                        parentEmailValid = {this.state.parentEmailValid}
                        parentAddress1Valid = {this.state.parentAddress1Valid}
                        parentAddress2Valid = {this.state.parentAddress2Valid}
                        parentCityValid = {this.state.parentCityValid}
                        parentStateValid = {this.state.parentStateValid}
                        parentZIPValid = {this.state.parentZIPValid}
                        form2Validated = {this.state.form3Validated}
                    />
                    <RegistrationStep4
                        currentStep = {this.state.currentStep}
                        handleChange = {this.handleChange}
                        handlePropsChange = {this.handlePropsChange}
                        signature = {this.state.signature}
                        signatureValid = {this.state.signatureValid}
                        parentSignature = {this.state.signature}
                        parentSignatureValid = {this.state.parentSignatureValid}
                        currentYear={this.state.year === nextYear}
                        form3Validated = {this.state.form4Validated}
                    />
                    <RegistrationStep5
                        currentStep = {this.state.currentStep}
                        handleChange = {this.handleChange}
                        handlePropsChange = {this.handlePropsChange}
                        prefsValid = {this.state.prefsValid}
                        mealPlan = {this.state.mealPlan}
                        mealPlanValid = {this.state.mealPlanValid}
                        captcha_key = {this.state.captcha_key}
                        currentYear={this.state.year === nextYear}
                        form4Validated = {this.state.form5Validated}
                    />
                    <RegistrationStep6
                        currentStep = {this.state.currentStep}
                        success = {this.state.success}
                        error = {this.state.error}
                    />

                    {this.nextButton}
                    {this.submitButton}
                </form>
            </div>
        );

    }
}