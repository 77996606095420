import React from "react";
import {ReactComponent as Logo} from "../images/bk_logo_full_final.svg";
import "./Account.css";
import LoginPage from "../components/LoginPage";


export default function Login(props) {
    return (
        <div className="Account">
            <Logo className="logo"/>
            <div className="container2">
                <LoginPage logIn={props.logIn}/>
            </div>
        </div>
    );
}