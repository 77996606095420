import React, {Component} from "react";
import "./Account.css";
import AdminRegistrationStep1 from "../components/AdminRegistration/AdminRegistrationStep1";
import AdminRegistrationStep2 from "../components/AdminRegistration/AdminRegistrationStep2";
import AdminRegistrationStep3 from "../components/AdminRegistration/AdminRegistrationStep3";

export default class AdminRegistration extends Component{

    constructor(props) {
        super(props);

        this.state = {
            auth_code: props.match.params.hash,
            email: '',
            password: '',
            passwordVerify: '',
            passwordValid: null,
            passwordVerifyValid: null,
            formValid: null,
            currentTab: 1,
            validationResponse: null,
            submitClicked: false,
            submitResponse: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleValidChange = this.handleValidChange.bind(this);
        this.handleIsValid = this.handleIsValid.bind(this);

        this.validateForm = this.validateForm.bind(this);
    }

    componentDidMount() {
        let auth_code = this.state.auth_code;
        let url = encodeURI("/api/admin/invitation/validate?code=" + auth_code);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            validationResponse: data,
            currentTab: 2
        })});
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.validateForm();

        this.setState({
            submitClicked: true
        });

        let data = {
            email: this.state.validationResponse.email,
            password: this.state.password,
            auth_code: this.state.auth_code
        };

        setTimeout(() => {
            let formValid = this.state.formValid;

            //get data ready to send

            console.log(data);

            if (formValid){
                fetch('/api/admin/invitation/register', {
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'POST'
                }).then(res => res.json()).then(data => {this.setState({
                    submitResponse: data,
                    currentTab: 3,
                    error: data.error != null ? data.error : null
                })});
            }
            else {
                this.setState({
                    submitClicked: false
                });
            }
        }, 1000);
    };

    // Pass this to the 2 steps.
    handleChange(event){
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }

    handleValidChange(name, value){
        this.setState({
            [name]: value
        });
    }

    handleIsValid(fieldName){
        return this.state[fieldName] != null ? (this.state[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }

    validateForm() {
        let passwordValid = this.state.passwordValid == null ? false : this.state.passwordValid;
        let passwordVerifyValid = this.state.passwordVerifyValid == null ? false : this.state.passwordVerifyValid;

        let formValid = passwordValid && passwordVerifyValid;

        this.setState({
            formValid: formValid,
            passwordValid: passwordValid,
            passwordVerifyValid: passwordVerifyValid
        });
    }

    render(){
        return(
            <div className={"Account"}>
                <div className={"container"}>
                    <h1 className={"bk_header"}> Admin Tools</h1>
                    <AdminRegistrationStep1 currentTab={this.state.currentTab} />
                    <AdminRegistrationStep2 currentTab={this.state.currentTab}
                                            handleChange={this.handleChange}
                                            handleIsValid={this.handleIsValid}
                                            handleValidChange={this.handleValidChange}
                                            handleSubmit={this.handleSubmit}
                                            password={this.state.password}
                                            formValid={this.state.formValid}
                                            submitClicked={this.state.submitClicked}
                                            success={this.state.validationResponse != null ? this.state.validationResponse.success : null}
                                            data={this.state.validationResponse != null ? this.state.validationResponse : null}/>
                    <AdminRegistrationStep3 currentTab={this.state.currentTab}
                                            data={this.state.submitResponse != null ? this.state.submitResponse : null}
                                            success={this.state.submitResponse != null ? this.state.submitResponse.success : null}/>
                </div>
            </div>
        );
    }
}