import React, {useState} from "react";
import {Alert, Button, Col, Form, Modal, Spinner} from "react-bootstrap";
import {BsArrowRight} from "react-icons/all";
import {BsExclamationTriangle, BsCheck} from "react-icons/all";
import {postItemAddAdmin} from "../../../../utils/ApiHandler";

export default function AdminAddItemModal(props)
{
    const [name, setName] = useState("");
    const [category, setCategory] = useState("Protein");
    const [points, setPoints] = useState(0.0);
    const [ingredients, setIngredients] = useState("");

    const [nameValid, setNameValid] = useState(null);
    const [ingredientsValid, setIngredientsValid] = useState(null);
    const [pointsValid, setPointsValid] = useState(null);

    const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

    const [errorNotifBody, setErrorNotifBody] = useState(null);

    function notification()
    {
        if (errorNotifBody != null)
        {
            return (
                <Alert variant="danger">
                    <BsExclamationTriangle/> {errorNotifBody}
                </Alert>
            );
        }
    }

    const onChange = (event) => {
        const {name, value} = event.target;
        if (name === "name")
        {
            setName(value);
            setNameValid(value.length > 0);
        }
        else if (name === "ingredients")
        {
            setIngredients(value);
            setIngredientsValid(value.length > 0);
        }
        else if (name === "points")
        {
            setPoints(value);
            setPointsValid(value > 0);
        }
    };

    function isValidState(fieldValid)
    {
        return fieldValid != null ? (fieldValid ? 'is-valid' : 'is-invalid') : '';
    }

    const submitClicked = () => {
        setSubmitButtonClicked(true);
        setErrorNotifBody(null);
        if (nameValid == null || ingredientsValid == null || pointsValid == null)
        {
            setNameValid(false);
            setIngredientsValid(false);
            setPointsValid(false);
            setSubmitButtonClicked(false);
        }
        else
        {
            if (nameValid && ingredientsValid)
            {
                let data = {
                    'name': name,
                    'category': category,
                    'points': points,
                    'ingredients': ingredients
                };

                postItemAddAdmin(data).then((response) => {
                    if (response.success)
                    {
                        setSubmitButtonClicked(false);
                        props.updateViewCounter();
                    }
                    else
                    {
                        setErrorNotifBody(response.error);
                        setSubmitButtonClicked(false);
                    }
                });
            }
            else
            {
                setSubmitButtonClicked(false);
            }
        }
    };

    function submitButton()
    {
        if (submitButtonClicked)
        {
            return (
                <Button
                    block
                    variant="dark"
                >
                    <Spinner animation="border" role="status" variant="light"/>
                </Button>
            );
        }
        else
        {
            return (
                <Button block
                        variant="dark"
                        className="mt-3"
                        onClick={submitClicked}
                >
                    Add Item
                </Button>
            );
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={() => props.setShow(false)}
            backdrop="static"
            keyboard={false}
            size="lg"
            className="kitchen_modal_body"
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Menu Item</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="ml-3 mr-3">
                    {notification()}
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>*Item Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter item name"
                                name="name"
                                value={name}
                                onChange={onChange}
                                className={isValidState(nameValid)}
                            />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>*Item Category</Form.Label>
                            <Form.Control
                                required
                                as="select"
                                custom
                                name="category"
                                value={category}
                                onChange={(event) => setCategory(event.target.value)}
                                className={'is-valid'}
                            >
                                <option>Protein</option>
                                <option>Carbs</option>
                                <option>Veggie</option>
                                <option>Breakfast</option>
                                <option>Assembled Meal</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>*Item Points (#)</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                placeholder="Enter item points"
                                name="points"
                                value={points}
                                onChange={onChange}
                                className={isValidState(pointsValid)}
                            />
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>*Ingredients</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                value={ingredients}
                                name={"ingredients"}
                                onChange={onChange}
                                className={isValidState(ingredientsValid)}
                            />
                        </Form.Group>
                    </Form.Row>
                    {submitButton()}
                </Form>
            </Modal.Body>
        </Modal>
    );
}