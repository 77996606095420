async function getData(url)
{
    const response = await fetch(url, {
        method: 'GET',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow'
    });
    return response.json();
}

async function getAuthenticatedData(url)
{
    const response = await fetch(url, {
        method: 'GET',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'account-hash': localStorage.getItem('hashed_login_key'),
            'user': localStorage.getItem('user')
        },
        redirect: 'follow'
    });
    return response.json();
}

async function postData(url, data)
{
    const response = await fetch(url, {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        body: JSON.stringify(data)
    });
    return response.json();
}

async function postAuthenticatedData(url, data)
{
    const response = await fetch(url, {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'account-hash': localStorage.getItem('hashed_login_key'),
            'user': localStorage.getItem('user')
        },
        redirect: 'follow',
        body: JSON.stringify(data)
    });
    return response.json();
}

export function postItemAddAdmin(data)
{
    let url = '/api/v2/admin/items/add';
    return postAuthenticatedData(url, data);
}

export function postItemDeleteAdmin(data)
{
    let url = '/api/v2/admin/items/delete';
    return postAuthenticatedData(url, data);
}

export function getAllItemsAdmin()
{
    let url = '/api/v2/admin/items/getAll';
    return getAuthenticatedData(url);
}

export function getAllMenusAdmin()
{
    let url = '/api/v2/admin/menus/getAll';
    return getAuthenticatedData(url);
}

export function postMenuAddAdmin(data)
{
    let url = '/api/v2/admin/menus/add';
    return postAuthenticatedData(url, data);
}

export function postMenusDeleteAdmin(data)
{
    let url = '/api/v2/admin/menus/delete';
    return postAuthenticatedData(url, data);
}

export function postSetPointsAdmin(data)
{
    let url = '/api/v2/admin/points/set';
    return postAuthenticatedData(url, data);
}

export function getPoints()
{
    let url = '/api/v2/points/get';
    return getAuthenticatedData(url);
}

export function getUserMenusAvailable()
{
    let url = '/api/v2/user/menus/get';
    return getAuthenticatedData(url);
}

export function postUserMenuPick(data)
{
    let url = '/api/v2/user/menu/pick';
    return postAuthenticatedData(url, data);
}

export function getMenuNamesAdmin()
{
    let url ='/api/v2/admin/menuNames/get';
    return getAuthenticatedData(url);
}

export function postAdminGetPicks(data)
{
    let url = '/api/v2/admin/picks';
    return postAuthenticatedData(url, data);
}

export function getUserMenuPickGetPast()
{
    let url = '/api/v2/user/menu/pick/getPast';
    return getAuthenticatedData(url);
}

export function postAdminAddGiftCard(data)
{
    let url = '/api/v2/admin/giftCards/add';
    return postAuthenticatedData(url, data);
}

export function postAdminDeleteGiftCard(data)
{
    let url = '/api/v2/admin/giftCards/delete';
    return postAuthenticatedData(url, data);
}

export function getAdminGiftCards()
{
    let url = '/api/v2/admin/giftCards/getAll';
    return getAuthenticatedData(url);
}

export function postAdminAddGiftCardMenu(data)
{
    let url = '/api/v2/admin/giftCardMenus/add';
    return postAuthenticatedData(url, data);
}

export function postAdminDeleteGiftCardMenu(data)
{
    let url = '/api/v2/admin/giftCardMenus/delete';
    return postAuthenticatedData(url, data);
}

export function getAdminGiftCardMenus()
{
    let url = '/api/v2/admin/giftCardMenus/getAll';
    return getAuthenticatedData(url);
}

export function getUserGiftCardMenusAvailable()
{
    let url = '/api/v2/user/giftCardMenus/get';
    return getAuthenticatedData(url);
}

export function postUserGiftCardMenuPick(data)
{
    let url = '/api/v2/user/giftCardMenus/pick';
    return postAuthenticatedData(url, data);
}

export function getUserGiftCardMenuPicksPast()
{
    let url = '/api/v2/user/giftCardMenus/pick/getPast';
    return getAuthenticatedData(url);
}

export function getAdminGiftCardMenuNames()
{
    let url = '/api/v2/admin/giftCardMenuNames/get';
    return getAuthenticatedData(url);
}

export function postAdminGiftCardPicks(data)
{
    let url = '/api/v2/admin/giftCardPicks';
    return postAuthenticatedData(url, data);
}

export function getUserMealPlan() {
    let url = '/api/v2/user/account/getMealPlan';
    return getAuthenticatedData(url);
}
