import React from "react";
import "./Registration.css";
import { ReactComponent as Logo } from "../images/bk_logo_full_final.svg";
import RegistrationMasterForm from "../components/UserRegistration/RegistrationMasterForm";

export default function Home() {
    return (
        <div className="Registration">
            <Logo className="logo"/>
            <div className="card_container">
                <RegistrationMasterForm />
            </div>
        </div>
    );
}