import React from 'react';
import {Alert, Button, Spinner, Table} from "react-bootstrap";
import {BsTrash} from "react-icons/all";
import {postMenusDeleteAdmin} from "../../../utils/ApiHandler";

export default function AdminMenusList(props)
{
    function deleteItem(itemName)
    {
        let data = {
            name: itemName
        };

        postMenusDeleteAdmin(data).then((response) => {
            if (response.success)
            {
                props.updateViewCounter();
            }
        });
    }

    if (props.menusData == null)
    {
        if (props.errorNotifBody != null)
        {
            return (
                <Alert variant="danger">Something went wrong... {props.errorNotifBody}</Alert>
            );
        }
        return (
            <div>
                Loading menus...
                <Spinner animation="border" role="status" variant="dark"/>
            </div>
        );
    }
    else
    {
        let buffer = [];

        for (const element of props.menusData)
        {
            let itemsString = "";
            for (const dish of element.menu_items)
            {
                itemsString += dish + ", ";
            }

            buffer.push(
                <tr key={element.name}>
                    <td>{element.name}</td>
                    <td>{element.firstDelivery}</td>
                    <td>{element.secondDelivery}</td>
                    <td>{element.startPicking}</td>
                    <td>{element.endPicking}</td>
                    <td>{itemsString}</td>
                    <td>
                        <Button variant="outline-danger float-right" onClick={() => deleteItem(element.name)}>
                            <BsTrash/>
                        </Button>
                    </td>
                </tr>
            );
        }

        return (
            <div>
                <Table striped responsive>
                    <thead>
                    <tr>
                        <th>Menu Name</th>
                        <th>First Delivery</th>
                        <th>Second Delivery</th>
                        <th>Start Picking</th>
                        <th>End Picking</th>
                        <th>Menu Items</th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    {buffer}
                    </tbody>
                </Table>
            </div>
        );
    }
}