import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./containers/Home";
import Registration from "./containers/Registration";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import Account from "./containers/Account";
import Logout from "./containers/Logout";
import Admin from "./containers/Admin";
import AdminRegistration from "./containers/AdminRegistration";
import TermsAndConditions from "./containers/TermsAndConditions";

export default function Routes(props) {
    return(
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route exact path="/registration">
                <Registration />
            </Route>
            <Route exact path='/login'>
                <Login logIn={props.logIn} />
            </Route>
            <AuthenticatedRoute exact path='/account' component={Account}/>
            <Route exact path='/logout'>
                <Logout logOut={props.logOut} />
            </Route>
            <AuthenticatedRoute exact path='/admin' component={Admin}/>
            <Route exact path='/adminregistration/:hash' component={(more_props) => <AdminRegistration {...more_props} logOut={props.logOut}/> }/>
            <Route exact path="/terms-and-conditions">
                <TermsAndConditions/>
            </Route>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}