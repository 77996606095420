import React, {useEffect, useState} from 'react';
import {Alert, Button, Spinner, Table} from "react-bootstrap";
import {postItemDeleteAdmin} from "../../../utils/ApiHandler"
import {BsFillStarFill, BsStar, BsTrash} from "react-icons/all";

export default function AdminItemsList(props)
{
    function deleteItem(itemName)
    {
        let data = {
            name: itemName
        };

        postItemDeleteAdmin(data).then((response) => {
            if (response.success)
            {
                props.updateViewCounter();
            }
        });
    }

    if (props.itemData == null)
    {
        if (props.errorNotifBody != null)
        {
            return (
                <Alert variant="danger">Something went wrong... {props.errorNotifBody}</Alert>
            );
        }
        return (
            <div>
                Loading menu items...
                <Spinner animation="border" role="status" variant="dark"/>
            </div>
        );
    }
    else
    {
        let buffer = [];

        for (const element of props.itemData)
        {
            buffer.push(
                <tr key={element.name}>
                    <td>{parseFloat(element.points)}</td>
                    <td>{element.name}</td>
                    <td>{element.category}</td>
                    <td>{element.ingredients}</td>
                    <td>
                        <Button variant="outline-danger float-right" onClick={() => deleteItem(element.name)}>
                            <BsTrash/>
                        </Button>
                    </td>
                </tr>
            );
        }

        return (
            <div className="mt-2 mb-2">
                <Table striped responsive>
                    <thead>
                    <tr>
                        <th>Points</th>
                        <th>Item Name</th>
                        <th>Item Category</th>
                        <th>Ingredients</th>
                        <th> </th>
                    </tr>
                    </thead>
                    <tbody>
                    {buffer}
                    </tbody>
                </Table>
            </div>
        );
    }
}