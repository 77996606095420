import React, {Component} from "react";
import {Alert, Button, Card, Col, Form, Row} from "react-bootstrap";
import {BsCheck} from "react-icons/all";

export default class RegistrationStep2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            inHouse: true,
            address1: '',
            address2: '',
            city: '',
            state: null,
            ZIP: '',
            address1Valid: null,
            address2Valid: null,
            cityValid: null,
            stateValid: null,
            ZIPValid: null
        };

        this.handleChangeFirstName = this.handleChangeFirstName.bind(this);
        this.handleChangeLastName = this.handleChangeLastName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeUnitNum = this.handleChangeUnitNum.bind(this);
        this.handleChangePhoneNum = this.handleChangePhoneNum.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordVerify = this.handleChangePasswordVerify.bind(this);

        this.handleIsValidState = this.handleIsValidState.bind(this);
        this.handleIsValidProps = this.handleIsValidProps.bind(this);

        this.unitNumValidClass = this.unitNumValidClass.bind(this);
    }

    handleIsValidState(fieldName){
        return this.state[fieldName] != null ? (this.state[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }

    handleIsValidProps(fieldName){
        return this.props[fieldName] != null ? (this.props[fieldName] ? 'is-valid' : 'is-invalid') : '';
    }

    setAddressAsHouse(unitNum){

        if (unitNum.length === 3){
            this.props.handlePropsChange('unitNumValid', true);
        }
        else {
            this.props.handlePropsChange('unitNumValid', false);
        }

        this.setState({
            address1: 'Beta House',
            address2: ('#' + unitNum),
            city: '',
            state: '',
            ZIP: ''
        });

        setTimeout(() => {
            this.props.setDeliveryAddressInfo(this.state.address1, this.state.address2,
                this.state.city, this.state.state, this.state.ZIP);
        }, 500);
    }

    unitNumValidClass(){
        return this.state.address2.length === 0 ? '' : (this.state.address2.length === 4 ? 'is-valid' : 'is-invalid');
    }

    handleChangeAddress = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });

        let addr1Valid = this.state.address1Valid == null ? false : this.state.address1Valid;
        let cityValid = this.state.cityValid == null ? false : this.state.cityValid;
        let stateValid= this.state.stateValid == null ? false : this.state.stateValid;
        let ZIPValid = this.state.ZIPValid == null ? false : this.state.ZIPValid;

        switch(name){
            case 'address1':
                let address1Pattern = /^[a-zA-Z0-9\s,.#'-]{3,}$/;
                if (value.length === 0){
                    this.setState({address1Valid: false});
                }
                else if (address1Pattern.test(value)){
                    this.setState({address1Valid: true});
                    addr1Valid = true;
                }
                else {
                    this.setState({address1Valid: false});
                }
                break;
            case 'address2':
                let address2Pattern = /^[a-zA-Z0-9\s,.#'-]+$/;
                if (value.length === 0){
                    this.setState({address2Valid: null});
                }
                else if (address2Pattern.test(value)){
                    this.setState({address2Valid: true});
                }
                else {
                    this.setState({address2Valid: false});
                }
                break;
            case 'city':
                let city_pattern = /^[a-zA-z\s,.]{2,}$/;
                if (value.length === 0){
                    this.setState({cityValid: false});
                }
                else if (value.match(city_pattern) != null){
                    this.setState({cityValid: true});
                    cityValid = true;
                }
                else {
                    this.setState({cityValid: false});
                }
                break;
            case 'state':
                if (value != null){
                    this.setState({stateValid: true});
                    stateValid = true;
                }
                else {
                    this.setState({stateValid: false});
                }
                break;
            case 'ZIP':
                let zipPattern = /^\d{5}$|^\d{5}-\d{4}$/;
                if (value.length === 0){
                    this.setState({ZIPValid: false});
                }
                else if (zipPattern.test(value)){
                    this.setState({ZIPValid: true});
                    ZIPValid = true;
                }
                else{
                    this.setState({ZIPValid: false});
                }
                break;
            default:
                break;
        }

        let addrValid = addr1Valid && cityValid && stateValid && ZIPValid;

        this.props.handlePropsChange('unitNumValid', addrValid);

        setTimeout(() => {
            if (addrValid){
                this.props.setDeliveryAddressInfo(this.state.address1, this.state.address2,
                    this.state.city, this.state.state, this.state.ZIP);
            }
        }, 500);
    };

    get unvalidatedAlert(){
        if (this.props.form1Validated != null){
            if (this.props.form1Validated === false){
                return (
                    <Alert variant='danger'>
                        Please completely fill out the below form correctly. Incorrect portions are marked.
                    </Alert>
                );
            }
        }
        return null;
    }

    handleChangeLastName(event){
        const {value} = event.target;
        if (value.length === 0){
            this.props.handlePropsChange('lastNameValid', false);
        }
        else if (value.match("^[a-zA-z]*$") != null){
            this.props.handlePropsChange('lastNameValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('lastNameValid', false);
        }
    }

    handleChangeFirstName(event){
        const {value} = event.target;
        if (value.length === 0){
            this.props.handlePropsChange('firstNameValid', false);
        }
        else if (value.match("^[a-zA-z]*$") != null){
            this.props.handlePropsChange('firstNameValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('firstNameValid', false);
        }
    }

    handleChangeEmail(event){
        const {value} = event.target;
        let emailPattern = /^[a-zA-z0-9._-]+@du.edu/;
        if (value.length === 0){
            this.props.handlePropsChange('emailValid', false);
        }
        else if (emailPattern.test(value)){
            this.props.handlePropsChange('emailValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('emailValid', false);
        }
    }

    handleChangeUnitNum(event){
        const {value} = event.target;
        if (value > 0){
            this.props.handlePropsChange('unitNumValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('unitNumValid', false);
        }
    }

    handleChangePhoneNum(event){
        const {value} = event.target;
        let phonePattern = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
        if (value.length === 0){
            this.props.handlePropsChange('phoneNumberValid', false);
        }
        else if (phonePattern.test(value)){
            this.props.handlePropsChange('phoneNumberValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('phoneNumberValid', false);
        }
    }

    handleChangePassword(event){
        const {value} = event.target;
        let passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*\d-])[a-zA-Z0-9!@#$%^&*\d-]{8,30}$/;
        if (value.length === 0){
            this.props.handlePropsChange('passwordValid', false);
        }
        else if (passwordPattern.test(value)){
            this.props.handlePropsChange('passwordValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('passwordValid', false);
        }
    }

    handleChangePasswordVerify(event){
        const {value} = event.target;
        let pass = this.props.password;
        if (value.length === 0){
            this.props.handlePropsChange('passwordVerifyValid', false);
        }
        else if (pass === value){
            this.props.handlePropsChange('passwordVerifyValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handlePropsChange('passwordVerifyValid', false);
        }
    }

    setInHouse(inHouse) {
        this.setState({
            inHouse: inHouse
        });
    }

    get inHouseAddressForm(){
        return (
            <Form>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>*Unit Number</Form.Label>
                        <Form.Control
                            required
                            as="select"
                            className={this.unitNumValidClass()}
                            id="inlineFormCustomSelect"
                            name="unitNum"
                            onChange={(event) => this.setAddressAsHouse(event.target.value)}
                            custom>
                            <option value={-100} selected>Choose...</option>
                            <option value={101}>101</option>
                            <option value="102">102</option>
                            <option value="103">103</option>
                            <option value="104">104</option>
                            <option value="105">105</option>
                            <option value="201">201</option>
                            <option value="202">202</option>
                            <option value="203">203</option>
                            <option value="204">204</option>
                            <option value="205">205</option>
                            <option value="206">206</option>
                            <option value="302">301</option>
                            <option value="302">302</option>
                            <option value="303">303</option>
                            <option value="304">304</option>
                            <option value="305">305</option>
                            <option value="306">306</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select a valid unit number.</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
            </Form>
        );
    }

    get outsideAddressForm(){
        return (
            <Form>
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>*Address</Form.Label>
                        <Form.Control required type="text" placeholder="Enter address"
                                      name="address1"
                                      className={this.handleIsValidState('address1Valid')}
                                      onChange={this.handleChangeAddress}/>
                        <Form.Control.Feedback type="invalid">Please enter a valid address.</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Address 2</Form.Label>
                        <Form.Control type="text" placeholder="Enter address 2 (not required)"
                                      name="address2"
                                      className={this.handleIsValidState('address2Valid')}
                                      onChange={this.handleChangeAddress}/>
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>*City</Form.Label>
                        <Form.Control type="text" placeholder="Enter city"
                                      name="city"
                                      className={this.handleIsValidState('cityValid')}
                                      onChange={this.handleChangeAddress}/>
                        <Form.Control.Feedback type="invalid">Please enter a valid city.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>*State</Form.Label>
                        <Form.Control
                            required
                            as="select"
                            className={this.handleIsValidState('stateValid')}
                            name="state"
                            onChange={this.handleChangeAddress}
                            custom>
                            <option value={null} selected>Choose...</option>
                            <option>AL</option>
                            <option>AK</option>
                            <option>AS</option>
                            <option>AZ</option>
                            <option>AR</option>
                            <option>CA</option>
                            <option>CO</option>
                            <option>CT</option>
                            <option>DE</option>
                            <option>DC</option>
                            <option>FM</option>
                            <option>FL</option>
                            <option>GA</option>
                            <option>GU</option>
                            <option>HI</option>
                            <option>ID</option>
                            <option>IL</option>
                            <option>IN</option>
                            <option>IA</option>
                            <option>KS</option>
                            <option>KY</option>
                            <option>LA</option>
                            <option>ME</option>
                            <option>MH</option>
                            <option>MD</option>
                            <option>MA</option>
                            <option>MI</option>
                            <option>MN</option>
                            <option>MS</option>
                            <option>MO</option>
                            <option>MT</option>
                            <option>NE</option>
                            <option>NV</option>
                            <option>NH</option>
                            <option>NJ</option>
                            <option>NM</option>
                            <option>NY</option>
                            <option>NC</option>
                            <option>ND</option>
                            <option>MP</option>
                            <option>OH</option>
                            <option>OK</option>
                            <option>OR</option>
                            <option>PW</option>
                            <option>PA</option>
                            <option>PR</option>
                            <option>RI</option>
                            <option>SC</option>
                            <option>SD</option>
                            <option>TN</option>
                            <option>TX</option>
                            <option>UT</option>
                            <option>VT</option>
                            <option>VI</option>
                            <option>VA</option>
                            <option>WA</option>
                            <option>WV</option>
                            <option>WI</option>
                            <option>WY</option>
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">Please select a valid state.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col}>
                        <Form.Label>*ZIP Code</Form.Label>
                        <Form.Control type="num" placeholder="Enter ZIP"
                                      name="ZIP"
                                      className={this.handleIsValidState('ZIPValid')}
                                      onChange={this.handleChangeAddress}/>
                        <Form.Control.Feedback type="invalid">Please enter a valid ZIP code.</Form.Control.Feedback>
                    </Form.Group>
                </Form.Row>
            </Form>
        );
    }

    get addressForm(){
        if (this.state.inHouse){
            return (
                <>
                    {this.inHouseAddressForm}
                </>
            );
        }
        else {
            return (
                <>
                    {this.outsideAddressForm}
                </>
            );
        }
    }

    render() {
        if (this.props.currentStep !== 2){
            return null;
        }

        let selectedCheck = (<BsCheck/>);

        let inHouseSelected = this.state.inHouse;

        console.log(this.state);

        return (
            <div>
                {this.unvalidatedAlert}
                <Card className="bk_text">
                    <Card.Header className="bk_header">
                        <strong>Account Registration (2/5)</strong>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title className="bk_header">
                            Personal Information
                        </Card.Title>
                        <Form className="m-0 align">
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>*First Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter first name"
                                                  name="firstName"
                                                  className = {this.handleIsValidProps('firstNameValid')}
                                                  onChange={this.handleChangeFirstName}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid first name.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>*Last Name</Form.Label>
                                    <Form.Control required type="text" placeholder="Enter last name"
                                                  name="lastName"
                                                  className={this.handleIsValidProps('lastNameValid')}
                                                  onChange={this.handleChangeLastName}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid last name.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>


                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>*DU Email Address</Form.Label>
                                    <Form.Control required type="email" placeholder="Enter @du.edu email address"
                                                  name="email"
                                                  className={this.handleIsValidProps('emailValid')}
                                                  onChange={this.handleChangeEmail}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid email. Remember, it must be a @du.edu address.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col} sm>
                                    <Form.Label>*Password</Form.Label>
                                    <Form.Control required type="password" placeholder="Create a password"
                                                  name="password"
                                                  className={this.handleIsValidProps('passwordValid')}
                                                  onChange={this.handleChangePassword}/>
                                    <Form.Text id="passwordHelpBlock" muted>
                                        Your password must be 8-30 characters long, contain letters, special characters, and numbers, and
                                        must not contain spaces or emoji.
                                    </Form.Text>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">Please enter a valid password. Must be between 8 and 30 characters and include a special character and a number.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} sm>
                                    <Form.Label>*Retype Password</Form.Label>
                                    <Form.Control required type="password" placeholder="Retype password"
                                                  name="passwordVerify"
                                                  className={this.handleIsValidProps('passwordVerifyValid')}
                                                  onChange={this.handleChangePasswordVerify}/>
                                    <Form.Control.Feedback>Passwords match!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">Passwords do not match.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>

                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>*Phone Number (Mobile)</Form.Label>
                                    <Form.Control required type="tel" placeholder="Enter phone number"
                                                  name="phoneNumber"
                                                  className={this.handleIsValidProps('phoneNumberValid')}
                                                  onChange={this.handleChangePhoneNum}/>
                                    <Form.Control.Feedback type="invalid">Please enter a valid phone number. Allowed formats: XXXXXXXXXX, (XXX)XXX-XXXX, XXX-XXX-XXXX.</Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                        </Form>

                        <Card.Title className="bk_header mt-3">
                            Delivery Information
                        </Card.Title>
                        <Row md={2} xs={1}>
                            <Col>
                                <Card
                                    bg={inHouseSelected ? 'primary' : ''}
                                    text={inHouseSelected ? 'light' : 'dark'}
                                    className={'mt-2 mb-2' + (inHouseSelected ? ' shadow' : '')}
                                >
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Card.Title>In Beta House</Card.Title>
                                            </Col>
                                            <Col className='text-right h3'>
                                                {inHouseSelected ? selectedCheck : ''}
                                            </Col>
                                        </Row>
                                        <Button className='mt-2 mb-2' block
                                                active={inHouseSelected}
                                                onClick={() => this.setInHouse(true)}>
                                            Select
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card
                                    bg={!inHouseSelected ? 'primary' : ''}
                                    text={!inHouseSelected ? 'light' : 'dark'}
                                    className={'mt-2 mb-2' + (!inHouseSelected ? ' shadow' : '')}
                                >
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Card.Title>Out of Beta House</Card.Title>
                                            </Col>
                                            <Col className='text-right h3'>
                                                {!inHouseSelected ? selectedCheck : ''}
                                            </Col>
                                        </Row>
                                        <Button className='mt-2 mb-2' block
                                                active={!inHouseSelected}
                                                onClick={() => this.setInHouse(false)}>
                                            Select
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        {this.addressForm}

                        <Card.Text>
                            Fields marked with an * are required.
                        </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                        We commit and promise to not share any of your personal information with any other third parties.
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}