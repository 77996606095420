import React, {Component} from 'react';
import {Button, Card, Col, Collapse, Form, InputGroup, ListGroup, Row, Spinner, Table} from "react-bootstrap";
import {BsCaretDownFill, BsCaretUpFill, BsTable, BsList} from "react-icons/bs";

export default class AdminPagePicks extends Component {

    constructor(props){
        super(props);

        this.state = {
            adminPicksJSON: null,
            week: 0,
            quarter: 'q1',
            showPicks: [],
            view: 0
        };
    }

    updateAdminPicksJSON(){
        // Load admin information
        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let week = this.state.week;
        let quarter = this.state.quarter;
        let url = encodeURI("/api/admin/menu/picks?account_hash=" + account_hash + "&user=" +
            account_name + "&week=" + week + "&quarter=" + quarter);

        fetch(url).then(res => res.json()).then(data => {
            let buffer = [];
            if (data.picks != null){
                for (let i = 0; i < data.picks.length; ++i){
                    buffer.push(false);
                }
                this.setState({
                    adminPicksJSON: data,
                    showPicks: buffer
                });
            }
        });
    }
    componentDidMount() {
        this.updateAdminPicksJSON();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.week !== prevState.week){
            this.updateAdminPicksJSON();
        }
        if (this.state.quarter !== prevState.quarter){
            this.updateAdminPicksJSON();
        }
    }

    totals(){
        let totals = [];

        let menu_categories = ['protein', 'carbs', 'veggies', 'breakfasts', 'assembled_meals'];
        for (let i = 0; i < 5; ++i){
            let categories_buffer = [];
            for (let j = 0; j < this.state.adminPicksJSON.totals[i].length; ++j){
                categories_buffer.push(
                    <ListGroup.Item key={i + j}>
                        <Row>
                            <Col sm={2}>{this.state.adminPicksJSON.totals[i][j]}</Col>
                            <Col>{this.state.adminPicksJSON.menu[menu_categories[i]][j].name}</Col>
                        </Row>
                    </ListGroup.Item>
                );
            }
            totals.push(categories_buffer);
        }

        let snacks_buffer = [];
        for (let i = 0; i < this.state.adminPicksJSON['total_snacks'].length; ++i){
            snacks_buffer.push(
                <ListGroup.Item key={i}>
                    <Row>
                        <Col sm={2}>{this.state.adminPicksJSON['total_snacks'][i]}</Col>
                        <Col>{this.state.adminPicksJSON.snacks[i].name}</Col>
                    </Row>
                </ListGroup.Item>
            );
        }
        totals.push(snacks_buffer);

        return totals;
    }

    get totalPicks(){
        let totals = this.totals();

        return (
            <div>
                <Card.Title>Total Picks</Card.Title>
                <Row>
                    <Col>
                        <Card.Title className={'text-primary'}>Protein</Card.Title>
                        <ListGroup>
                            {totals[0]}
                        </ListGroup>
                    </Col>
                    <Col>
                        <Card.Title className={'text-warning'}>Carbs</Card.Title>
                        <ListGroup>
                            {totals[1]}
                        </ListGroup>
                    </Col>
                </Row>
                <Row className={'mt-3 mb-3'}>
                    <Col>
                        <Card.Title className={'text-success'}>Veggies</Card.Title>
                        <ListGroup>
                            {totals[2]}
                        </ListGroup>
                    </Col>
                    <Col>
                        <Card.Title className={'text-danger'}>Breakfasts</Card.Title>
                        <ListGroup>
                            {totals[3]}
                        </ListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card.Title className={'text-secondary'}>Assembled Meals</Card.Title>
                        <ListGroup>
                            {totals[4]}
                        </ListGroup>
                    </Col>
                    <Col>
                        <Card.Title className={'text-primary'}>Snacks</Card.Title>
                        <ListGroup>
                            {totals[5]}
                        </ListGroup>
                    </Col>
                </Row>

            </div>
        );
    }

    onClick(i){
        let buffer = this.state.showPicks;
        buffer[i] = !buffer[i];
        this.setState({
            showPicks: buffer
        });
    };

    individuals(){
        let individuals = [];
        let categories = ['protein_picks', 'carbs_picks', 'veggies_picks', 'breakfast_picks', 'assembled_meals_picks'];
        let menu_categories = ['protein', 'carbs', 'veggies', 'breakfasts', 'assembled_meals'];

        for (let i = 0; i < this.state.adminPicksJSON.picks.length; ++i){
            let individualPicksBuffer = [];
            let foodPicksNull = false;
            let snackPicksNull = false;

            if (this.state.adminPicksJSON.picks[i]['food_picks'] == null){
                foodPicksNull = true;
            }
            else {
                for (let j = 0; j < categories.length; ++j){
                    let categoryBuffer = [];
                    for (let k = 0; k < this.state.adminPicksJSON.picks[i]['food_picks'][categories[j]].length; ++k){
                        categoryBuffer.push(
                            <ListGroup.Item key={i + j + k}>
                                <Row>
                                    <Col sm={2}>{this.state.adminPicksJSON.picks[i]['food_picks'][categories[j]][k]}</Col>
                                    <Col>{this.state.adminPicksJSON.menu[menu_categories[j]][k].name}</Col>
                                </Row>
                            </ListGroup.Item>
                        );
                    }
                    individualPicksBuffer.push(
                        <ListGroup>
                            {categoryBuffer}
                        </ListGroup>
                    );
                }
            }
            if (this.state.adminPicksJSON.picks[i]['snack_picks'] == null){
                snackPicksNull = true;
            }
            else {
                let snackBuffer = [];
                for (let j = 0; j < this.state.adminPicksJSON.picks[i]['snack_picks']['snack_picks'].length; ++j){
                    try
                    {
                        snackBuffer.push(
                            <ListGroup.Item key={i + j}>
                                <Row>
                                    <Col sm={2}>{this.state.adminPicksJSON.picks[i]['snack_picks']['snack_picks'][j]}</Col>
                                    <Col>{this.state.adminPicksJSON.snacks[j].name}</Col>
                                </Row>
                            </ListGroup.Item>
                        );
                    }
                    catch (Exception)
                    {
                        console.log(Exception + ": index " + j + " email: " + this.state.adminPicksJSON.picks[i]['email']);
                    }
                }
                individualPicksBuffer.push(
                    <ListGroup>
                        {snackBuffer}
                    </ListGroup>
                );
            }


            let header = (
                <Row>
                    <Col>{this.state.adminPicksJSON.picks[i]['email']}</Col>
                    <Col className={'text-danger'}>{foodPicksNull ? 'No Food Picks' : ''}</Col>
                    <Col className={'text-danger'}>{snackPicksNull ? 'No Snack Picks' : ''}</Col>
                    <Col><Button
                        size={'sm'}
                        className={'float-right'}
                        onClick={() => this.onClick(i)}
                        aria-controls="collapse-next-weeks-picks"
                        aria-expanded={this.state.showPicks[i]}
                    > {!this.state.showPicks[i] ? (<BsCaretDownFill/>) : (<BsCaretUpFill/>)}</Button></Col>
                </Row>
            );

            individuals.push(
                <ListGroup.Item className={'d-inline-row'} key={this.state.adminPicksJSON.picks[i]['email']}>
                    {header}

                    <Collapse in={this.state.showPicks[i]}>
                        <div className={'mt-3'}>
                            <Row>
                                <Col>
                                    <Card.Title className={'text-primary'}>Protein</Card.Title>
                                    {individualPicksBuffer[0]}

                                </Col>
                                <Col>
                                    <Card.Title className={'text-warning'}>Carbs</Card.Title>
                                    {individualPicksBuffer[1]}

                                </Col>
                            </Row>
                            <Row className={'mt-3 mb-3'}>
                                <Col>
                                    <Card.Title className={'text-success'}>Veggies</Card.Title>
                                    {individualPicksBuffer[2]}

                                </Col>
                                <Col>
                                    <Card.Title className={'text-danger'}>Breakfasts</Card.Title>
                                    {individualPicksBuffer[3]}

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Card.Title className={'text-secondary'}>Assembled Meals</Card.Title>
                                    {individualPicksBuffer[4]}
                                </Col>
                                <Col>
                                    <Card.Title className={'text-primary'}>Snacks</Card.Title>
                                    {individualPicksBuffer[5]}
                                </Col>
                            </Row>
                        </div>
                    </Collapse>
                </ListGroup.Item>
            );
        }



        return individuals;
    }

    get individualPicks(){
        let individuals = this.individuals();

        return (
            <div className={'mt-5'}>
                <Card.Title>Individual Picks</Card.Title>
                <div className={'mt-2 mb-2'}>
                    <strong>{this.state.adminPicksJSON.picks.length}</strong> Submitted
                </div>
                <ListGroup>
                    {individuals}
                </ListGroup>
            </div>
        );
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    };

    tableHeaders(){
        let menuCategories = ['protein', 'carbs', 'veggies', 'breakfasts', 'assembled_meals'];
        //table headers
        let itemsHeaderBuffer = [];
        for (let category of menuCategories){
            for (let item of this.state.adminPicksJSON.menu[category]){
                itemsHeaderBuffer.push(
                    <th>{item.name}</th>
                );
            }
        }
        for (let item of this.state.adminPicksJSON.snacks){
            itemsHeaderBuffer.push(
                <th>{item.name}</th>
            );
        }
        return itemsHeaderBuffer;
    }

    get totalsFirstTable(){
        //table content
        let totalsRow = [];
        for (let i = 0; i < 5; ++i){
            for (let j = 0; j < this.state.adminPicksJSON.totals_first[i].length; ++j){
                totalsRow.push(
                    <td>{this.state.adminPicksJSON.totals_first[i][j]}</td>
                );
            }
        }
        for (let i = 0; i < this.state.adminPicksJSON.total_snacks.length; ++i){
            totalsRow.push(
                <td>{this.state.adminPicksJSON.total_snacks[i]}</td>
            );
        }

        return (
            <Table striped bordered hover responsive size="sm">
                <thead>
                <tr>
                    {this.tableHeaders()}
                </tr>
                </thead>
                <tbody>
                <tr>
                    {totalsRow}
                </tr>
                </tbody>
            </Table>
        );
    }

    get totalsSecondTable(){
        //table content
        let totalsRow = [];
        for (let i = 0; i < 5; ++i){
            for (let j = 0; j < this.state.adminPicksJSON.totals_second[i].length; ++j){
                totalsRow.push(
                    <td>{this.state.adminPicksJSON.totals_second[i][j]}</td>
                );
            }
        }
        for (let i = 0; i < this.state.adminPicksJSON.total_snacks.length; ++i){
            totalsRow.push(
                <td>0</td>
            );
        }

        return (
            <Table striped bordered hover responsive size="sm">
                <thead>
                <tr>
                    {this.tableHeaders()}
                </tr>
                </thead>
                <tbody>
                <tr>
                    {totalsRow}
                </tr>
                </tbody>
            </Table>
        );
    }

    foodRowBuffers(){
        let rowBuffers = [];
        let menuCategories = ['protein', 'carbs', 'veggies', 'breakfasts', 'assembled_meals'];

        for (let category of menuCategories){
            let categoryBuffer = [];
            for (let item of this.state.adminPicksJSON.menu[category]){
                categoryBuffer.push(0);
            }
            rowBuffers.push(categoryBuffer);
        }
        return rowBuffers;
    }

    snackRowBuffer(){
        let rowBuffer = [];
        for (let item of this.state.adminPicksJSON.snacks){
            rowBuffer.push(0);
        }
        return rowBuffer;
    }

    individualsTable(type){
        let categories = ['protein_picks', 'carbs_picks', 'veggies_picks', 'breakfast_picks', 'assembled_meals_picks'];
        let rows = [];

        for (let i = 0; i < this.state.adminPicksJSON.picks.length; ++i) {
            let individualRow = [];
            let currentPick = this.state.adminPicksJSON.picks[i];
            individualRow.push(
                <td>{currentPick.unitNum}</td>
            );
            individualRow.push(
                <td>{currentPick.email}</td>
            );

            if (this.state.adminPicksJSON.picks[i][type] == null) {
                //pad with 0s
                let foodRowBuffers = this.foodRowBuffers();

                for (let j = 0; j < foodRowBuffers.length; ++j){
                    for (let k = 0; k < foodRowBuffers[j].length; ++k){
                        individualRow.push(
                            <td>N/A</td>
                        );
                    }
                }

            } else {
                for (let j = 0; j < categories.length; ++j) {
                    for (let k = 0; k < currentPick[type][categories[j]].length; ++k) {
                        individualRow.push(
                            <td>{currentPick[type][categories[j]][k]}</td>
                        );
                    }
                }
            }
            if (this.state.adminPicksJSON.picks[i]['snack_picks'] == null) {
                //pad with 0s
                let snackRowBuffer = this.snackRowBuffer();
                for (let j = 0; j < snackRowBuffer.length; ++j){
                    individualRow.push(
                        <td>N/A</td>
                    );
                }

            } else {
                if (type === 'food_picks_first'){
                    for (let j = 0; j < currentPick['snack_picks']['snack_picks'].length; ++j) {
                        individualRow.push(
                            <td>{currentPick['snack_picks']['snack_picks'][j]}</td>
                        );
                    }
                }
                else {
                    for (let j = 0; j < currentPick['snack_picks']['snack_picks'].length; ++j) {
                        individualRow.push(
                            <td>0</td>
                        );
                    }
                }

            }

            rows.push(
                <tr>
                    {individualRow}
                </tr>
            );
        }

        return (
            <Table striped bordered hover responsive size="sm">
                <thead>
                <tr>
                    <th>Unit #</th>
                    <th>Email</th>
                    {this.tableHeaders()}
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
        );
    }

    get viewType(){
        if (this.state.view === 0){
            //view type is list
            return (
                <div className={"m-3"}>
                    {this.totalPicks}
                    {this.individualPicks}
                </div>
            );
        }
        else {
            //view type is table
            return (
                <div>
                    <Card.Title className={'my-3 ml-3'}>
                        Totals First Delivery
                    </Card.Title>
                    {this.totalsFirstTable}
                    <Card.Title className={'my-3 ml-3'}>
                        Totals Second Delivery
                    </Card.Title>
                    {this.totalsSecondTable}
                    <Card.Title className={'my-3 ml-3'}>
                        Individuals First Delivery
                    </Card.Title>
                    {this.individualsTable('food_picks_first')}
                    <Card.Title className={'my-3 ml-3'}>
                        Individuals Second Delivery
                    </Card.Title>
                    {this.individualsTable('food_picks_second')}
                </div>
            );
        }
    }

    render() {
        if (this.props.currentTab !== 5){
            return null;
        }

        if (this.state.adminPicksJSON == null){
            return(
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }
        else {
            if (this.state.adminPicksJSON.success){
                return (
                    <div className={'bk_text'}>
                        <h1 className={"bk_header mt-3"}> Food Picks </h1>
                        <Card className={'mt-3 mb-3'}>
                            <Card.Header className={'d-inline-row'}>
                                Quarterly Picks
                                <Row className={'float-right'}><Col sm={25}>
                                    <InputGroup>
                                        <Form.Control
                                            required
                                            as={'select'}
                                            name={'quarter'}
                                            onChange={this.handleChange}
                                            defaultValue={'q1'}
                                            custom>
                                            <option value={'q1'}>Fall Quarter</option>
                                            <option value={'q2'}>Winter Quarter</option>
                                            <option value={'q3'}>Spring Quarter</option>
                                        </Form.Control>
                                        <Form.Control
                                            required
                                            as={'select'}
                                            name={'week'}
                                            onChange={this.handleChange}
                                            defaultValue={0}
                                            custom>
                                            <option value={0}>Week 0</option>
                                            <option value={1}>Week 1</option>
                                            <option value={2}>Week 2</option>
                                            <option value={3}>Week 3</option>
                                            <option value={4}>Week 4</option>
                                            <option value={5}>Week 5</option>
                                            <option value={6}>Week 6</option>
                                            <option value={7}>Week 7</option>
                                            <option value={8}>Week 8</option>
                                            <option value={9}>Week 9</option>
                                            <option value={10}>Week 10</option>
                                        </Form.Control>
                                        <InputGroup.Append>
                                            <Button
                                                onClick={() => {this.setState({view: 1})}}
                                                active={this.state.view === 1}
                                                variant={this.state.view === 1 ? "primary" : "secondary"}>
                                                <BsTable/></Button>
                                        </InputGroup.Append>
                                        <InputGroup.Append>
                                            <Button
                                                onClick={() => {this.setState({view: 0})}}
                                                variant={this.state.view === 0 ? "primary" : "secondary"}
                                                active={this.state.view === 0}>
                                                <BsList/></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col></Row>
                            </Card.Header>

                                {this.viewType}

                        </Card>
                    </div>
                );
            }
            else {
                return (
                    <div className={'bk_text'}>
                        <h1 className={"bk_header mt-3"}> Food Picks </h1>
                        <Card className={'mt-3 mb-3'}>
                            <Card.Header className={'d-inline-row'}>
                                Quarterly Picks
                                <Row className={'float-right'}><Col sm={25}>
                                    <InputGroup>
                                        <Form.Control
                                            required
                                            as={'select'}
                                            name={'quarter'}
                                            onChange={this.handleChange}
                                            defaultValue={'q1'}
                                            custom>
                                            <option value={'q1'}>Fall Quarter</option>
                                            <option value={'q2'}>Winter Quarter</option>
                                            <option value={'q3'}>Spring Quarter</option>
                                        </Form.Control>
                                        <Form.Control
                                            required
                                            as={'select'}
                                            name={'week'}
                                            onChange={this.handleChange}
                                            defaultValue={0}
                                            custom>
                                            <option value={0}>Week 0</option>
                                            <option value={1}>Week 1</option>
                                            <option value={2}>Week 2</option>
                                            <option value={3}>Week 3</option>
                                            <option value={4}>Week 4</option>
                                            <option value={5}>Week 5</option>
                                            <option value={6}>Week 6</option>
                                            <option value={7}>Week 7</option>
                                            <option value={8}>Week 8</option>
                                            <option value={9}>Week 9</option>
                                            <option value={10}>Week 10</option>
                                        </Form.Control>
                                        <InputGroup.Append>
                                            <Button variant="secondary" disabled><BsTable/></Button>
                                        </InputGroup.Append>
                                        <InputGroup.Append>
                                            <Button variant="secondary" disabled><BsList/></Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Col></Row>
                            </Card.Header>
                            <Card.Body>
                                No information to show here.
                            </Card.Body>
                        </Card>
                    </div>
                );
            }


        }
    }


}