import React, {Component} from "react";
import {Card, Col, Spinner, Row, Button} from 'react-bootstrap';
import {BsBoxArrowInUpRight, BsFillUnlockFill, BsFillLockFill} from "react-icons/all";
import Chart from "react-google-charts";

export default class AdminPageDashboard extends Component{
    constructor(props){
        super(props);

        this.state = {
            adminMetricsJSON: null,
            registrationToggleClicked: false,
            submitTracker: 0
        };
    }

    //ButtonStyle
    buttonStyle(){
        return {cursor: 'pointer'};
    }

    //*** API Calls
    updateAdminMetricsJSON(){
        // Load admin information
        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI('api/admin/metrics?account_hash=' + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            adminMetricsJSON: data
        })});
    }
    componentDidMount() {
        this.updateAdminMetricsJSON();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentTab !== 1 && this.props.currentTab === 1){
            this.updateAdminMetricsJSON();
        }
        if (prevState.submitTracker !== this.state.submitTracker){
            this.updateAdminMetricsJSON();
        }
    }

    //*** metricsPage Components
    //  - get userMetricsCard()
    //  - get revenueMetricsCard()
    //  - get mealPlanMetricsCard()
    //
    handleRegistrationToggle = (event) => {
        event.preventDefault();
        this.setState({
            registrationToggleClicked: true
        });

        let submitTracker = this.state.submitTracker;
        ++submitTracker;

        let post_body = {
            value: !this.state.adminMetricsJSON.reg_open.reg_open
        };
        let url  = '/api/admin/users/registration/set';

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'account-hash': localStorage.getItem('hashed_login_key'),
                'user': localStorage.getItem('user')
            },
            body: JSON.stringify(post_body)
        }).then(res => res.json()).then(data => {this.setState({
            submitTracker: submitTracker
        })});

        setTimeout(() => {
            this.setState({
                registrationToggleClicked: false,
            })
        }, 2000);
    };

    get regChangeButtonAndLabel(){
        if (this.state.registrationToggleClicked){
            return (
                <Button variant="outline-secondary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }

        if (this.state.adminMetricsJSON.reg_open.reg_open){
            return (
                <div>
                    <Button onClick={this.handleRegistrationToggle} variant={'outline-success'}><BsFillUnlockFill/></Button>   Registration is currently open.
                </div>
            );
        }
        else {
            return (
                <div>
                    <Button onClick={this.handleRegistrationToggle} variant={'outline-danger'}><BsFillLockFill/></Button>   Registration is currently closed.
                </div>
            );
        }
    }
    get userMetricsCard(){
        return (
            <Card className={"mt-3 mb-3"}>
                <Card.Header>Users
                    <div className={"float-right"}>
                        <BsBoxArrowInUpRight style={this.buttonStyle()} onClick={() => this.props.handleClick(2)}/>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Title><strong>{this.state.adminMetricsJSON.total_users_count}</strong> Total Accounts</Card.Title>
                    <Card.Text>
                        <strong>{this.state.adminMetricsJSON.users_count}</strong> Users<br/>
                        <strong>{this.state.adminMetricsJSON.admin_count}</strong> Admin<br/><br/>
                    </Card.Text>
                    {this.regChangeButtonAndLabel}
                </Card.Body>
            </Card>
        );
    }
    get revenueMetricsCard(){
        return(
            <Card className={"mt-3 mb-3"}>
                <Card.Header>Accounting | Revenue
                    <div className={"float-right"}>
                        <BsBoxArrowInUpRight style={this.buttonStyle()} onClick={() => this.props.handleClick(3)}/>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Title><strong>${((this.state.adminMetricsJSON.meal_150_count * 1660.0) + (this.state.adminMetricsJSON.meal_175_count * 1750.0)).toFixed(2).toLocaleString()}</strong> Gross Revenue</Card.Title>
                    <Card.Text>
                        <strong>${(this.state.adminMetricsJSON.meal_150_count * 1660).toFixed(2).toLocaleString()}</strong> from 150 Block Meal Plans<br/>
                        <strong>${(this.state.adminMetricsJSON.meal_175_count * 1750).toFixed(2).toLocaleString()}</strong> from 175 Block Meal Plans<br/>
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    }
    get mealPlanMetricsCard(){
        return(
            <Card className={"mt-3 mb-3"}>
                <Card.Header>Accounting | Meal Plans
                    <div className={"float-right"}>
                        <BsBoxArrowInUpRight style={this.buttonStyle()} onClick={() => this.props.handleClick(3)}/>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col sm>
                            <Card.Title><strong>{this.state.adminMetricsJSON.total_meal_plan_count}</strong> Meal Plans</Card.Title>
                            <div className="d-flex justify-content-center align-items-center">
                                <Chart
                                    width={'300px'}
                                    height={'300px'}
                                    chartType="PieChart"
                                    loader={<div className="d-flex justify-content-center align-items-center mt-5">
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="lg"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="sr-only">Loading...</span>
                                    </div>}
                                    data={[['Meal Plans', 'Number'],
                                        ['150', this.state.adminMetricsJSON.meal_150_count],
                                        ['175', this.state.adminMetricsJSON.meal_175_count]]}
                                    options={{
                                        legend: 'none',
                                        pieSliceText: 'none',
                                        pieStartAngle: 0,
                                        pieHole: 0.7,
                                        slices: {
                                            0: { color: '#8dba69' },
                                            1: { color: '#4d7e29' },
                                        },
                                        chartArea: {
                                            width: '100%',
                                            height: '95%'
                                        },
                                    }}
                                    rootProps={{ 'data-testid': '6' }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm>
                            <Card.Text className={'mb-3'}>
                                <strong>{this.state.adminMetricsJSON.meal_150_count}</strong> - 150 Block Count<br/>
                                <strong>{this.state.adminMetricsJSON.meal_175_count}</strong> - 175 Block Count<br/>
                            </Card.Text>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        );
    }

    //*** render() function
    render(){
        if (this.props.currentTab !== 1){
            return null;
        }

        if (this.state.adminMetricsJSON == null){
            return(
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }
        else{
            return(
                <div className={"bk_text ml-0 mr-0"}>
                    <h1 className={"bk_header mt-3"}> Dashboard </h1>
                    <Row>
                        <Col sm>
                            <Row><Col sm>
                                {this.userMetricsCard}
                            </Col></Row>
                            <Row><Col sm>
                                {this.revenueMetricsCard}
                            </Col></Row>
                        </Col>
                        <Col sm>
                            {this.mealPlanMetricsCard}
                        </Col>
                    </Row>
                </div>
            );
        }
    }

}