import React, {Component} from "react";
import {Alert, Button, Col, Form, ListGroup, Modal, Row, Spinner} from "react-bootstrap";

export default class UserPageMenuPicking extends Component {

    constructor(props){
        super(props);

        this.state = {
            proteinPicks: [],
            carbsPicks: [],
            veggiesPicks: [],
            breakfastPicks: [],
            assembledMealsPicks: [],

            assembledMealsPicked: 0,
            submitClicked: false,
            notifShow: false,
            notifBody: '',
        };
    };

    componentDidMount() {
        let proteinBuffer = [];
        let carbsBuffer = [];
        let veggiesBuffer = [];
        let breakfastBuffer = [];
        let assembledMealsBuffer = [];
        for (let i = 0; i < this.props.proteins.length; ++i){
            proteinBuffer.push(0);
        }
        for (let i = 0; i < this.props.carbs.length; ++i){
            carbsBuffer.push(0);
        }
        for (let i = 0; i < this.props.veggies.length; ++i){
            veggiesBuffer.push(0);
        }
        for (let i = 0; i < this.props.breakfasts.length; ++i){
            breakfastBuffer.push(0);
        }
        for (let i = 0; i < this.props.assembledMeals.length; ++i){
            assembledMealsBuffer.push(0);
        }
        this.setState({
            proteinPicks: proteinBuffer,
            carbsPicks: carbsBuffer,
            veggiesPicks: veggiesBuffer,
            breakfastPicks: breakfastBuffer,
            assembledMealsPicks: assembledMealsBuffer
        });
    }


    onChange(category, index, value){
        let categoryPicks = this.state[category];
        categoryPicks[index] = parseInt(value);
        console.log(categoryPicks);

        this.setState({
            [category]: categoryPicks
        });

        if (category === 'assembledMealsPicks'){
            this.setState({
                assembledMealsPicked: parseInt(value)
            });
        }
    }

    get proteinPicking(){
        let formBuffer = [];

        let numAllowed = 6 - this.state.assembledMealsPicked;

        for (let i = 0; i < this.props.proteins.length; ++i){

            let ingredientsString = '';
            for (const ingredient of this.props.proteins[i].ingredients){
                ingredientsString += ingredient + ', ';
            }

            ingredientsString = ingredientsString.slice(0, ingredientsString.length - 2);

            formBuffer.push(
                <Form.Row className={'mt-3'} key={'protein' + i}>
                    <Col xs={2}>
                        <Form.Control required min={0} max={numAllowed} type="number" placeholder="0"
                                      name={'protein' + i}
                                      onChange={(event) => {
                                          this.onChange('proteinPicks', i, event.target.value);
                                      }}
                        />
                    </Col>
                    <Col>
                        <ListGroup.Item key={this.props.proteins[i].name}>
                            <Row>
                                <Col md={3}>{this.props.proteins[i].name}: </Col>
                                <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                            </Row>
                        </ListGroup.Item>
                    </Col>
                </Form.Row>
            );
        }

        let total = this.state.proteinPicks.reduce((acc, curr) => acc + curr, 0);
        if (isNaN(total)){
            total = 0;
        }

        let alert = null;
        if (total === numAllowed){
            alert = (
                <Alert variant={'success'}>
                    Looks good!
                </Alert>
            );
        }
        else if (total > numAllowed){
            alert = (
                <Alert variant={'danger'}>
                    Remember, your options need to total up to {numAllowed}.
                </Alert>
            );
        }

        return (
            <div>
                <div className={'text-primary'}><h5>{total}/{numAllowed} Protein Items</h5></div>
                {alert}
                Please pick {numAllowed} options, you can spread them out between the below options however you would like.<br/>
                {formBuffer}
            </div>
        );
    }

    get carbsPicking(){
        let formBuffer = [];

        let numAllowed = 6 - this.state.assembledMealsPicked;

        for (let i = 0; i < this.props.carbs.length; ++i){

            let ingredientsString = '';
            for (const ingredient of this.props.carbs[i].ingredients){
                ingredientsString += ingredient + ', ';
            }

            ingredientsString = ingredientsString.slice(0, ingredientsString.length - 2);

            formBuffer.push(
                <Form.Row className={'mt-3'} key={'carbs' + i}>
                    <Col xs={2}>
                        <Form.Control required min={0} max={numAllowed} type="number" placeholder="0"
                                      name={'carbs' + i}
                                      onChange={(event) => {
                                          this.onChange('carbsPicks', i, event.target.value);
                                      }}
                        />
                    </Col>
                    <Col>
                        <ListGroup.Item key={this.props.carbs[i].name}>
                            <Row>
                                <Col md={3}>{this.props.carbs[i].name}: </Col>
                                <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                            </Row>
                        </ListGroup.Item>
                    </Col>
                </Form.Row>
            );
        }

        let total = this.state.carbsPicks.reduce((acc, curr) => acc + curr, 0);
        if (isNaN(total)){
            total = 0;
        }

        let alert = null;
        if (total === numAllowed){
            alert = (
                <Alert variant={'success'}>
                    Looks good!
                </Alert>
            );
        }
        else if (total > numAllowed){
            alert = (
                <Alert variant={'danger'}>
                    Remember, your options need to total up to {numAllowed}.
                </Alert>
            );
        }

        return (
            <div className={'mt-3'}>
                <div className={'text-warning'}><h5>{total}/{numAllowed} Carbs Items</h5></div>
                {alert}
                Please pick {numAllowed} options, you can spread them out between the below options however you would like.<br/>
                {formBuffer}
            </div>
        );
    }

    get veggiesPicks(){
        let formBuffer = [];

        for (let i = 0; i < this.props.veggies.length; ++i){

            let ingredientsString = '';
            for (const ingredient of this.props.veggies[i].ingredients){
                ingredientsString += ingredient + ', ';
            }

            ingredientsString = ingredientsString.slice(0, ingredientsString.length - 2);

            formBuffer.push(
                <Form.Row className={'mt-3'} key={'veggies' + i}>
                    <Col xs={2}>
                        <Form.Control required min={0} max={1} type="number" placeholder="0"
                                      name={'veggies' + i}
                                      onChange={(event) => {
                                          this.onChange('veggiesPicks', i, event.target.value);
                                      }}
                        />
                    </Col>
                    <Col>
                        <ListGroup.Item key={this.props.veggies[i].name}>
                            <Row>
                                <Col md={3}>{this.props.veggies[i].name}: </Col>
                                <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                            </Row>
                        </ListGroup.Item>
                    </Col>
                </Form.Row>
            );
        }

        let total = this.state.veggiesPicks.reduce((acc, curr) => acc + curr, 0);
        if (isNaN(total)){
            total = 0;
        }

        let alert = null;
        if (total === 1){
            alert = (
                <Alert variant={'success'}>
                    Looks good!
                </Alert>
            );
        }
        else if (total > 1){
            alert = (
                <Alert variant={'danger'}>
                    Remember, your options need to total up to 1 or 0.
                </Alert>
            );
        }

        return (
            <div className={'mt-3'}>
                <div className={'text-success'}><h5>{total}/1 Veggies Items</h5></div>
                {alert}
                Please pick up to 1 option. You are also allowed to pick no vegetables.<br/>
                {formBuffer}
            </div>
        );
    }

    get breakfastPicks(){
        let formBuffer = [];

        let numAllowed = this.props.mealPlan === '150' ? 3 : 5;

        for (let i = 0; i < this.props.breakfasts.length; ++i){

            let ingredientsString = '';
            for (const ingredient of this.props.breakfasts[i].ingredients){
                ingredientsString += ingredient + ', ';
            }

            ingredientsString = ingredientsString.slice(0, ingredientsString.length - 2);

            formBuffer.push(
                <Form.Row className={'mt-3'} key={'breakfasts' + i}>
                    <Col xs={2}>
                        <Form.Control required min={0} max={numAllowed} type="number" placeholder="0"
                                      name={'breakfasts' + i}
                                      onChange={(event) => {
                                          this.onChange('breakfastPicks', i, event.target.value);
                                      }}
                        />
                    </Col>
                    <Col>
                        <ListGroup.Item key={this.props.breakfasts[i].name}>
                            <Row>
                                <Col md={3}>{this.props.breakfasts[i].name}: </Col>
                                <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                            </Row>
                        </ListGroup.Item>
                    </Col>
                </Form.Row>
            );
        }

        let total = this.state.breakfastPicks.reduce((acc, curr) => acc + curr, 0);
        if (isNaN(total)){
            total = 0;
        }

        let alert = null;
        if (total === numAllowed){
            alert = (
                <Alert variant={'success'}>
                    Looks good!
                </Alert>
            );
        }
        else if (total > numAllowed){
            alert = (
                <Alert variant={'danger'}>
                    Remember, your options need to total up to {numAllowed}.
                </Alert>
            );
        }

        return (
            <div className={'mt-3'}>
                <div className={'text-danger'}><h5>{total}/{numAllowed} Breakfast Items</h5></div>
                {alert}
                Please pick {numAllowed} options. You can distribute the allowed amount however you would like between the options.
                {formBuffer}
            </div>
        );
    }

    get assembledMealsPicks(){
        let formBuffer = [];

        let larger = Math.max(this.state.proteinPicks.reduce((acc, curr) => acc + curr, 0),
            this.state.carbsPicks.reduce((acc, curr) => acc + curr, 0));
        let numAllowed = 6 - larger;

        for (let i = 0; i < this.props.assembledMeals.length; ++i){

            let ingredientsString = '';
            for (const ingredient of this.props.assembledMeals[i].ingredients){
                ingredientsString += ingredient + ', ';
            }

            ingredientsString = ingredientsString.slice(0, ingredientsString.length - 2);

            formBuffer.push(
                <Form.Row className={'mt-3'} key={'assembledMeals' + i}>
                    <Col xs={2}>
                        <Form.Control required min={0} max={numAllowed} type="number" placeholder="0"
                                      name={'assembledMeals' + i}
                                      onChange={(event) => {
                                          this.onChange('assembledMealsPicks', i, event.target.value);
                                      }}
                        />
                    </Col>
                    <Col>
                        <ListGroup.Item key={this.props.assembledMeals[i].name}>
                            <Row>
                                <Col md={3}>{this.props.assembledMeals[i].name}: </Col>
                                <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                            </Row>
                        </ListGroup.Item>
                    </Col>
                </Form.Row>
            );
        }

        let total = this.state.assembledMealsPicks.reduce((acc, curr) => acc + curr, 0);
        if (isNaN(total)){
            total = 0;
        }

        let alert = null;
        if (total === numAllowed){
            alert = (
                <Alert variant={'success'}>
                    Looks good!
                </Alert>
            );
        }
        else if (total > numAllowed){
            alert = (
                <Alert variant={'danger'}>
                    Remember, your options need to total up to {numAllowed}.
                </Alert>
            );
        }

        return (
            <div className={'mt-3'}>
                <div className={'text-secondary'}><h5>{total}/{numAllowed} Assembled Meals</h5></div>
                {alert}
                Please pick up to {numAllowed} options.
                Remember, if you pick an assembled meal, you will be allowed 1 less protein and carb option per assembled meal selected.<br/>
                You do not have to pick any assembled meals.
                {formBuffer}
            </div>
        );
    }

    get submitButton(){
        if (this.state.submitClicked){
            return (
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.onSubmit} className="btn btn-primary float-right mt-2 mb-2" type="button">
                    Submit My Picks
                </Button>
            );
        }
    }

    validatePicks(){
        let proteinNum = this.state.proteinPicks.reduce((acc, curr) => acc + curr, 0);
        let carbsNum = this.state.carbsPicks.reduce((acc, curr) => acc + curr, 0);
        let veggiesNum = this.state.veggiesPicks.reduce((acc, curr) => acc + curr, 0);
        let breakfastsNum = this.state.breakfastPicks.reduce((acc, curr) => acc + curr, 0);
        let assembledMealsNum = this.state.assembledMealsPicks.reduce((acc, curr) => acc + curr, 0);

        let numProteinsAndCarbsAllowed = 6 - assembledMealsNum;
        let proteinGood = proteinNum === numProteinsAndCarbsAllowed;
        let carbsGood = carbsNum === numProteinsAndCarbsAllowed;
        let veggiesGood = veggiesNum === 0 || veggiesNum === 1;
        let breakfastsGood = breakfastsNum === (this.props.mealPlan === '150' ? 3 : 5);
        let assembledMealsGood = assembledMealsNum >= 0 && assembledMealsNum <= 6;

        return proteinGood && carbsGood && veggiesGood && breakfastsGood && assembledMealsGood;
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState({
            submitClicked: true
        });
        if (this.validatePicks()){
            let post_body = {
                user: localStorage.getItem('user'),
                quarter: this.props.quarter,
                week: this.props.week,
                proteinPicks: this.state.proteinPicks,
                carbsPicks: this.state.carbsPicks,
                veggiesPicks: this.state.veggiesPicks,
                breakfastPicks: this.state.breakfastPicks,
                assembledMealsPicks: this.state.assembledMealsPicks,
            };
            let url = '/api/user/menu/pick';

            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'account-hash': localStorage.getItem('hashed_login_key'),
                    'user': localStorage.getItem('user')
                },
                body: JSON.stringify(post_body)
            }).then(res => res.json()).then((data) => {this.setState({
                submitClicked: false,
                notifShow: true,
                notifBody: data.error || 'Success!'
            })});

            setTimeout(() => {
                this.setState({
                    notifShow: false
                });
                this.props.updateChangeTracker();
            }, 3000);
        }
        else {
            this.setState({
                submitClicked: false,
                notifShow: true,
                notifBody: 'Form not validated. Check all of your inputs and try again.'
            });
        }

    };

    get notification(){
        if (this.state.notifShow){
            if (this.state.notifBody !== 'Success!'){
                return (
                    <Alert variant={'danger'}>
                        ERROR: {this.state.notifBody}
                    </Alert>
                );
            }
            else {
                return (
                    <Alert variant={'success'}>
                        {this.state.notifBody} : Your picks have been added.
                    </Alert>
                );
            }
        }
        else {
            return null;
        }
    }

    render() {
        return (
            <Modal.Body>
                {this.notification}
                All measurements are in pounds except for breakfasts and assembled meals. Breakfasts are in servings, 1 per day, and assembled meals are 2 servings per meal, one protein and one carb.
                <Form className={'mt-4'}>
                    {this.assembledMealsPicks}
                    {this.proteinPicking}
                    {this.carbsPicking}
                    {this.veggiesPicks}
                    {this.breakfastPicks}
                    {this.submitButton}
                </Form>
            </Modal.Body>
        );
    }
}