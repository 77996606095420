import React, {Component} from "react";
import {Card, Spinner, Alert, Form, Col, Button} from "react-bootstrap";
import {BsFillPersonPlusFill, BsFillExclamationCircleFill} from "react-icons/all";

export default class AdminRegistrationStep1 extends Component{

    constructor(){
        super();

        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordVerify = this.handleChangePasswordVerify.bind(this);
    }

    handleChangePassword(event){
        const {value} = event.target;
        let passwordPattern = /^(?=.*[0-9])(?=.*[!@#$%^&*\d-])[a-zA-Z0-9!@#$%^&*\d-]{8,30}$/;
        if (value.length === 0){
            this.props.handleValidChange('passwordValid', false);
        }
        else if (passwordPattern.test(value)){
            this.props.handleValidChange('passwordValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handleValidChange('passwordValid', false);
        }
    }

    handleChangePasswordVerify(event){
        const {value} = event.target;
        let pass = this.props.password;
        if (value.length === 0){
            this.props.handleValidChange('passwordVerifyValid', false);
        }
        else if (pass === value){
            this.props.handleValidChange('passwordVerifyValid', true);
            this.props.handleChange(event);
        }
        else {
            this.props.handleValidChange('passwordVerifyValid', false);
        }
    }

    get unvalidatedAlert(){
        if (this.props.formValid != null){
            if (this.props.formValid === false){
                return (
                    <Alert variant='danger'>
                        Please completely fill out the below form correctly. Incorrect portions are marked.
                    </Alert>
                );
            }
        }
        return null;
    }

    get body(){
        if (this.props.success == null){
            return (
                <Card.Body>
                    <Card.Title>
                        Validating admin registration code...
                    </Card.Title>
                    <Card.Text>
                        Stand by while we validate your admin registration code!
                    </Card.Text>
                    <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                        <Spinner
                            as="span"
                            animation="border"
                            size="lg"
                            role="status"
                            aria-hidden="true"
                        />
                        <span className="sr-only">Loading...</span>
                    </div>
                </Card.Body>
            );
        }
        else {
            let title = this.props.success ? 'Create Your Account' : ' Something went wrong...';
            let text = this.props.success ? 'Your invite code was successfully validated! Please create your password below.' :
                'Your invite code could not be successfully validated.';
            let alert = this.props.success ? '' : (<Alert variant={'danger'}>
                <BsFillExclamationCircleFill/> ERROR: {this.props.data.error}
            </Alert>);
            let form = '';
            if (this.props.success){
                form = (
                    <Form>
                        {this.unvalidatedAlert}
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Email</Form.Label>
                                <Form.Control plaintext readOnly defaultValue={this.props.data.email} />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} sm>
                                <Form.Label>*Password</Form.Label>
                                <Form.Control required type="password" placeholder="Create a password"
                                              name="password"
                                              className={this.props.handleIsValid('passwordValid')}
                                              onChange={this.handleChangePassword}/>
                                <Form.Text id="passwordHelpBlock" muted>
                                    Your password must be 8-30 characters long, contain letters, special characters, and numbers, and
                                    must not contain spaces or emoji.
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Please enter a valid password. Must be between 8 and 30 characters and include a special character and a number.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} sm>
                                <Form.Label>*Retype Password</Form.Label>
                                <Form.Control required type="password" placeholder="Retype password"
                                              name="passwordVerify"
                                              className={this.props.handleIsValid('passwordVerifyValid')}
                                              onChange={this.handleChangePasswordVerify}/>
                                <Form.Control.Feedback>Passwords match!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">Passwords do not match.</Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        {this.submitButton}
                    </Form>
                );
            }
            return (
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>{text}</Card.Text>
                    {alert}
                    {form}
                </Card.Body>
            );
        }

    }

    get submitButton(){
        if(this.props.submitClicked){
            return(
                <Button className="btn btn-primary float-right mt-2 mb-2" variant="primary" disabled>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </Button>
            );
        }
        else {
            return (
                <Button onClick={this.props.handleSubmit} className="btn btn-primary float-right mt-2 mb-2" type="submit">
                    Submit
                </Button>
            );
        }
    }

    render(){
        if (this.props.currentTab !== 2){
            return null;
        }

        return(
            <Card className={"bk_text"}>
                <Card.Header>
                    <BsFillPersonPlusFill/> Admin Registration
                </Card.Header>
                {this.body}
            </Card>
        );
    }

}