import React, {Component} from "react";
import {
    Alert, Badge,
    Button,
    ButtonGroup,
    Card,
    Col,
    Collapse,
    Form,
    Jumbotron,
    ListGroup,
    Modal,
    Row,
    Spinner
} from "react-bootstrap";
import {
    BsFillQuestionCircleFill,
    BsPencilSquare,
    BsCaretUpFill,
    BsCaretDownFill,
    BsFillCalendarFill, BsCheck
} from "react-icons/all";
import UserPageMenuPicking from "./UserPageMenuPicking";
import UserPageMenuSnackPicking from "./UserPageMenuSnackPicking";
import UserPageMenuPickingV2 from "./UserPageMenuPickingV2";

export default class UserPageMenu extends Component {

    constructor(props){
        super(props);

        this.state = {
            userMenuJSON: null,
            showPickingModal: false,
            showSnackPickingModal: false,
            changeTracker: 0,
            showNextWeeksPicks: false,
            showNextWeeksSnackPicks: false,
            selectedWeek: 'this'
        };

        this.updateChangeTrackerPick = this.updateChangeTrackerPick.bind(this);
        this.hideSnackPickingModal = this.hideSnackPickingModal.bind(this);
    }


    updateUserMenuJSON(){
        // Load admin information
        let account_hash = encodeURIComponent(localStorage.getItem('hashed_login_key'));
        let account_name = encodeURIComponent(localStorage.getItem('user'));
        let url = encodeURI("/api/user/menu?account_hash=" + account_hash + "&user=" + account_name);

        fetch(url).then(res => res.json()).then(data => {this.setState({
            userMenuJSON: data
        })});
    }
    componentDidMount(){
        this.updateUserMenuJSON();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentTab !== 1 && this.props.currentTab === 1){
            this.updateUserMenuJSON();
        }
        if (this.state.changeTracker !== prevState.changeTracker){
            this.updateUserMenuJSON();
        }
    }

    getCategoryListGroups(week_info){
        let listGroups = [];

        let categories = ['protein', 'carbs', 'veggies', 'breakfasts', 'assembled_meals'];

        for (const category of categories){
            let categoryBuffer = [];
            for (const item of week_info[category]){

                let ingredientsString = '';
                for (const ingredient of item.ingredients){
                    ingredientsString += ingredient + ', ';
                }

                ingredientsString = ingredientsString.slice(0, ingredientsString.length - 2);

                categoryBuffer.push(
                    <ListGroup.Item key={item.name}>
                        <Row>
                            <Col md={3}>{item.name}: </Col>
                            <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                        </Row>
                    </ListGroup.Item>
                );
            }
            let buffer = null;
            if (categoryBuffer.length > 0){
                buffer = (<ListGroup>{categoryBuffer}</ListGroup>);
            }
            else {
                buffer = (
                    <ListGroup>
                        <ListGroup.Item>No items available.</ListGroup.Item>
                    </ListGroup>);
            }
            listGroups.push(buffer);
        }

        return listGroups;
    }

    getPickedMenu2(picked_data, picked_menu)
    {
        let buffer = [];

        let assembledMeals = [];
        let proteins = [];
        let carbs = [];
        let veggies = [];
        let breakfasts = [];

        for (const item of picked_menu.assembled_meals)
        {
            assembledMeals.push(item.name);
        }
        for (const item of picked_menu.protein)
        {
            proteins.push(item.name);
        }
        for (const item of picked_menu.carbs)
        {
            carbs.push(item.name);
        }
        for (const item of picked_menu.veggies)
        {
            veggies.push(item.name);
        }
        for (const item of picked_menu.breakfasts)
        {
            breakfasts.push(item.name);
        }

        let assembledMealsPicks = [];
        let proteinPicks = [];
        let carbsPicks = [];
        let veggiesPicks = [];
        let breakfastsPicks = [];

        for (const key in picked_data.picksFirst)
        {
            let itemHtml = (
                <ListGroup.Item>
                    <Row>
                        <Col>
                            First Delivery (Monday)
                            <h5>{key}: {picked_data.picksFirst[key]}</h5>
                        </Col>
                        <Col>
                            Second Delivery (Thursday)
                            <h5>{key}: {picked_data.picksSecond[key]}</h5>
                        </Col>

                    </Row>
                </ListGroup.Item>
            );

            if (assembledMeals.includes(key))
            {
                assembledMealsPicks.push(itemHtml);
            }
            else if (proteins.includes(key))
            {
                proteinPicks.push(itemHtml);
            }
            else if (carbs.includes(key))
            {
                carbsPicks.push(itemHtml);
            }
            else if (veggies.includes(key))
            {
                veggiesPicks.push(itemHtml);
            }
            else if (breakfasts.includes(key))
            {
                breakfastsPicks.push(itemHtml);
            }
        }

        return [assembledMealsPicks, proteinPicks, carbsPicks, veggiesPicks, breakfastsPicks];
    }

    getPickedMenu(picked_data, picked_menu){
        let week_info = picked_menu;
        let listGroups = [];

        let categories = ['protein', 'carbs', 'veggies', 'breakfasts', 'assembled_meals'];
        let categoriesData = ['protein_picks', 'carbs_picks', 'veggies_picks', 'breakfast_picks', 'assembled_meals_picks'];
        let i = 0;

        for (const category of categories){
            let categoryBuffer = [];
            let j = 0;
            for (const item of week_info[category]){

                let ingredientsString = '';
                for (const ingredient of item.ingredients){
                    ingredientsString += ingredient + ', ';
                }

                ingredientsString = ingredientsString.slice(0, ingredientsString.length - 2);

                categoryBuffer.push(
                    <Form.Row className={'mt-3'} key={item.name}>
                        <Col xs={1}>
                            <Form.Control plaintext readOnly type="text" value={picked_data[categoriesData[i]][j]}
                                          name={item.name}
                            />
                        </Col>
                        <Col>
                            <ListGroup.Item key={item.name}>
                                <Row>
                                    <Col md={3}>{item.name}: </Col>
                                    <Col><strong>Ingredients:</strong> {ingredientsString}</Col>
                                </Row>
                            </ListGroup.Item>
                        </Col>
                    </Form.Row>
                );
                ++j;
            }
            let buffer = null;
            if (categoryBuffer.length > 0){
                buffer = (<ListGroup>{categoryBuffer}</ListGroup>);
            }
            else {
                buffer = (
                    <ListGroup>
                        <ListGroup.Item>No items available.</ListGroup.Item>
                    </ListGroup>);
            }
            listGroups.push(buffer);
            ++i;
        }

        return listGroups;
    }

    get currentWeeksPicks() {
        if (this.state.userMenuJSON.week_data_current.menu != null && this.state.userMenuJSON.week_data_current.picks != null) {
            let week = this.state.userMenuJSON.week_data_current.menu;
            let start_date = week.start_date.substring(0, week.start_date.indexOf(':') - 2);
            let end_date = week.end_date.substring(0, week.end_date.indexOf(':') - 2);

            if (this.state.userMenuJSON.week_data_current.picked) {
                let listGroupsPicked = this.getPickedMenu2(this.state.userMenuJSON.week_data_current.picks, week);

                let buttonExpand = (
                    <>
                        <BsCaretDownFill/> {' '} View Picks
                    </>
                );
                let buttonExpanded = (
                    <>
                        <BsCaretUpFill/> {' '} Collapse Picks
                    </>
                );

                return (
                    <div>
                        Week {week.week + 1}: {start_date} - {end_date}
                        <div className={'mt-3 mb-3'}>
                            <Row>
                                <Col className={'text-center text-success align-items-center'}><h1>
                                    <BsCheck/>
                                </h1></Col>
                                <Col>Your picks are confirmed for the current week! The first delivery of the week will go out this coming Monday.</Col>
                            </Row>
                        </div>
                        <Button
                            block
                            onClick={() => this.setState({showNextWeeksPicks: !this.state.showNextWeeksPicks})}
                            aria-controls="collapse-next-weeks-picks"
                            aria-expanded={this.state.showNextWeeksPicks}
                        > {!this.state.showNextWeeksPicks ? buttonExpand : buttonExpanded}
                        </Button>
                        <Collapse in={this.state.showNextWeeksPicks}>
                            <div>
                                <div className={'mt-3'}>
                                    <div className={'text-secondary'}><h5>Assembled Meals
                                        Picked</h5></div>
                                    {listGroupsPicked[0]}
                                </div>
                                <div className={'mt-3'}>
                                    <div className={'text-primary'}><h5>Protein Items Picked</h5>
                                    </div>
                                    {listGroupsPicked[1]}
                                </div>
                                <div className={'mt-3'}>
                                    <div className={'text-warning'}><h5> Carb Items Picked</h5></div>
                                    {listGroupsPicked[2]}
                                </div>
                                <div className={'mt-3'}>
                                    <div className={'text-success'}><h5>Veggie Items Picked</h5>
                                    </div>
                                    {listGroupsPicked[3]}
                                </div>
                                <div className={'mt-3 mb-3'}>
                                    <div className={'text-danger'}><h5>Breakfast Items Picked</h5>
                                    </div>
                                    {listGroupsPicked[4]}
                                </div>
                            </div>
                        </Collapse>
                    </div>
                );
            }
            else {
                return (
                    <div>
                        There is an issue going on here...
                    </div>
                );
            }
        }
        else {
            return (
                <div>
                    Your current picks are confirmed and will be delivered starting on Monday.
                </div>
            );
        }
    }

    get nextWeeksMenu(){
        if (this.state.userMenuJSON.week_data_next.menu != null && this.state.userMenuJSON.week_data_next.menu.published){
            let week = this.state.userMenuJSON.week_data_next.menu;
            let start_date = week.start_date.substring(0, week.start_date.indexOf(':') - 2);
            let end_date = week.end_date.substring(0, week.end_date.indexOf(':') - 2);
            let listGroups = this.getCategoryListGroups(week);

            if(this.state.userMenuJSON.week_data_next.picked){
                let listGroupsPicked = this.getPickedMenu2(this.state.userMenuJSON.week_data_next.picks, week);

                let editDate = this.state.userMenuJSON.week_data_next.picking_allowed_until.substring(0, week.start_date.indexOf(':') - 2);

                let editLine = this.state.userMenuJSON.week_data_next.picking_allowed ?
                    (<h6 className={'mt-3'}>Edits allowed until: <strong>{editDate}</strong></h6>) :
                    (<h6 className={'mt-3'}>Edits are no longer allowed for next week.</h6>);

                let buttonExpand = (
                    <>
                        <BsCaretDownFill/> {' '} View Picks
                    </>
                );
                let buttonExpanded = (
                    <>
                        <BsCaretUpFill/> {' '} Collapse Picks
                    </>
                );

                return (
                    <div>
                        <h5><strong>Next Week's Meal Picks</strong></h5>
                        Week {week.week + 1}: {start_date} - {end_date}
                        <div className={'mt-3 mb-3'}>
                            <Row>
                                <Col className={'text-center text-success align-items-center'}>
                                    <h1>
                                        <BsCheck/>
                                    </h1>

                                </Col>
                                <Col>
                                    You have already picked for next week! The first week's delivery will go out this coming Monday.
                                </Col>
                            </Row>
                        </div>
                        <Button
                            block
                            onClick={() => this.setState({showNextWeeksPicks: !this.state.showNextWeeksPicks})}
                            aria-controls="collapse-next-weeks-picks"
                            aria-expanded={this.state.showNextWeeksPicks}
                        > {!this.state.showNextWeeksPicks ? buttonExpand: buttonExpanded}
                        </Button>
                        <Collapse in={this.state.showNextWeeksPicks}>
                            <div>
                                <Button className={'mt-3 float-right'}
                                        variant={'secondary'}
                                        disabled={!this.state.userMenuJSON.week_data_next.picking_allowed}
                                        onClick={() => this.setState({showPickingModal: true})}><BsPencilSquare/> Change my Picks</Button>
                                {editLine}
                                <div>
                                    <div className={'mt-3'}>
                                        <div className={'text-secondary'}><h5>Assembled Meals
                                            Picked</h5></div>
                                        {listGroupsPicked[0]}
                                    </div>
                                    <div className={'mt-3'}>
                                        <div className={'text-primary'}><h5>Protein Items Picked</h5>
                                        </div>
                                        {listGroupsPicked[1]}
                                    </div>
                                    <div className={'mt-3'}>
                                        <div className={'text-warning'}><h5> Carb Items Picked</h5></div>
                                        {listGroupsPicked[2]}
                                    </div>
                                    <div className={'mt-3'}>
                                        <div className={'text-success'}><h5>Veggie Items Picked</h5>
                                        </div>
                                        {listGroupsPicked[3]}
                                    </div>
                                    <div className={'mt-3 mb-3'}>
                                        <div className={'text-danger'}><h5>Breakfast Items Picked</h5>
                                        </div>
                                        {listGroupsPicked[4]}
                                    </div>
                                </div>
                            </div>
                        </Collapse>
                    </div>
                );
            }

            return (
                <div>
                    <h5><strong>Next Week's Menu</strong></h5>
                    Week {week.week + 1}: {start_date} - {end_date}
                    <Button onClick={() => this.setState({showPickingModal: true})}
                            className={'float-right'}>Pick my Food</Button>
                    <div className={'mt-3 mb-3'}>
                        <BsFillQuestionCircleFill/> How does food picking work?<br/><br/>
                        With your current meal plan you are allowed to pick 12 proteins and veggies options, 6 of each.
                        You can distribute those 6 per category however you would like, meaning you can get 6 of a single menu item in either category.
                        You are also allowed to pick {this.state.userMenuJSON.user_meal_plan === '150' ? '3' : '5'} breakfast items.
                        <br/> Picking an assembled meal will count towards 1 protein and 1 carb option. You can pick 1 veggie or none.

                    </div>
                    <div className={'mt-3'}>
                        <div className={'text-primary'}><h5>{week.protein.length} Protein Items</h5></div>
                        {listGroups[0]}
                    </div>
                    <div className={'mt-3'}>
                        <div className={'text-warning'}><h5>{week.carbs.length} Carbs Items</h5></div>
                        {listGroups[1]}
                    </div>
                    <div className={'mt-3'}>
                        <div className={'text-success'}><h5>{week.veggies.length} Veggie Items</h5></div>
                        {listGroups[2]}
                    </div>
                    <div className={'mt-3'}>
                        <div className={'text-danger'}><h5>{week.breakfasts.length} Breakfast Items</h5></div>
                        {listGroups[3]}
                    </div>
                    <div className={'mt-3 mb-5'}>
                        <div className={'text-secondary'}><h5>{week.assembled_meals.length} Assembled Meals</h5></div>
                        {listGroups[4]}
                    </div>
                </div>
            );
        }
        else {
            return (
                <div>No menu available.</div>
            );
        }
    }

    get pickingModal(){
        if (!("menu" in this.state.userMenuJSON.week_data_next)){
            return null;
        }

        return (
            <Modal size={'xl'} show={this.state.showPickingModal} onHide={() => this.setState({showPickingModal: false})}>
                <Modal.Header closeButton>
                    <strong>Pick your Food</strong>
                </Modal.Header>
                <UserPageMenuPickingV2
                    menu={this.state.userMenuJSON.week_data_next.menu}
                    mealPlan={this.state.userMenuJSON.user_meal_plan}
                    quarter={this.state.userMenuJSON.quarter}
                    week={this.state.userMenuJSON.week_next}
                    year={this.state.userMenuJSON.year}
                    updateChangeTracker={this.updateChangeTrackerPick}
                />
                {/*<UserPageMenuPicking proteins={this.state.userMenuJSON.week_data_next.menu.protein}*/}
                {/*                     carbs={this.state.userMenuJSON.week_data_next.menu.carbs}*/}
                {/*                     veggies={this.state.userMenuJSON.week_data_next.menu.veggies}*/}
                {/*                     breakfasts={this.state.userMenuJSON.week_data_next.menu.breakfasts}*/}
                {/*                     assembledMeals={this.state.userMenuJSON.week_data_next.menu.assembled_meals}*/}
                {/*                     mealPlan={this.state.userMenuJSON.user_meal_plan}*/}
                {/*                     week={this.state.userMenuJSON.week_next}*/}
                {/*                     quarter={this.state.userMenuJSON.quarter}*/}
                {/*                     updateChangeTracker={this.updateChangeTrackerPick}*/}
                {/*/>*/}
            </Modal>
        );
    }

    hideSnackPickingModal(){
        this.setState({
            showSnackPickingModal: false
        })
    }

    get snackPickingModal(){
        if (this.state.userMenuJSON.snacks == null){
            return null;
        }

        return (
            <Modal size={'lg'} show={this.state.showSnackPickingModal} onHide={() => {this.setState({showSnackPickingModal: false})}}>
                <Modal.Header closeButton>
                    <strong>Pick your Snacks</strong>
                </Modal.Header>
                <UserPageMenuSnackPicking snacks={this.state.userMenuJSON.snacks}
                                          week={this.state.userMenuJSON.week_next}
                                          mealPlanCash={this.state.userMenuJSON.meal_plan_cash}
                                          quarter={this.state.userMenuJSON.quarter}
                                          updateChangeTracker={this.updateChangeTrackerPick}
                                          hideSnackPickingModal={this.hideSnackPickingModal}
                />


            </Modal>
        );
    }

    updateChangeTrackerPick(){
        let changeTracker = this.state.changeTracker;
        ++changeTracker;
        this.setState({
            changeTracker: changeTracker,
            showPickingModal: false
        });
    }

    getPickedSnacks(picked_data){
        let buffer = [];
        //
        // for (let i = 0; i < picked_data.length; ++i){
        //     let item = this.state.userMenuJSON.snacks[i];
        //     buffer.push(
        //         <Form.Row className={'mt-3'} key={item.name}>
        //             <Col xs={1}>
        //                 <Form.Control plaintext readOnly type="text" value={picked_data[i]}
        //                               name={item.name}
        //                 />
        //             </Col>
        //             <Col>
        //                 <ListGroup.Item key={item.name}>
        //                     <Row>
        //                         <Col sm={2}>${parseInt(item.price).toFixed(2)}</Col>
        //                         <Col><strong>{item.name}:</strong> {item.description}</Col>
        //                     </Row>
        //                 </ListGroup.Item>
        //             </Col>
        //         </Form.Row>
        //     );
        // }

        return buffer;
    }

    get nextWeeksSnacks(){
        if (this.state.userMenuJSON.week_data_next == null || this.state.userMenuJSON.week_data_next.menu == null){
            return null;
        }

        let week = this.state.userMenuJSON.week_data_next.menu;
        let start_date = week.start_date.substring(0, week.start_date.indexOf(':') - 2);
        let end_date = week.end_date.substring(0, week.end_date.indexOf(':') - 2);

        if(this.state.userMenuJSON.week_data_next.snacks_picked){
            let listGroupsPicked = this.getPickedSnacks(this.state.userMenuJSON.week_data_next.snack_picks);
            let numSnacksPicked = this.state.userMenuJSON.week_data_next.snack_picks.reduce((acc, x) => acc + x, 0);

            // let editDate = this.state.userMenuJSON.week_data_next.picking_allowed_until.substring(0, week.start_date.indexOf(':') - 2);
            //
            // let editLine = this.state.userMenuJSON.week_data_next.picking_allowed ?
            //     (<h6 className={'mt-3'}>Edits allowed until: <strong>{editDate}</strong></h6>) :
            //     (<h6 className={'mt-3'}>Edits are no longer allowed for next week.</h6>);

            let buttonExpand = (
                <>
                    <BsCaretDownFill/> {' '} View Snack Picks
                </>
            );
            let buttonExpanded = (
                <>
                    <BsCaretUpFill/> {' '} Collapse Snack Picks
                </>
            );

            return (
                <div>
                    Week {week.week + 1}: {start_date} - {end_date}
                    <div className={'mt-3 mb-3'}>
                        <Row>
                            <Col className={'text-center text-success align-items-center'}><h1>
                                <BsCheck/>
                            </h1></Col>
                            <Col>You have already picked snacks for next week! The first delivery of the week will go out this coming Tuesday.</Col>
                        </Row>
                    </div>
                    <Button
                        block
                        onClick={() => this.setState({showNextWeeksSnackPicks: !this.state.showNextWeeksSnackPicks})}
                        aria-controls="collapse-next-weeks-picks"
                        aria-expanded={this.state.showNextWeeksPicks}
                    > {!this.state.showNextWeeksSnackPicks ? buttonExpand: buttonExpanded}
                    </Button>
                    <Collapse in={this.state.showNextWeeksSnackPicks}>
                        <div>
                            {/*<Button className={'mt-3 float-right'}*/}
                            {/*        variant={'secondary'}*/}
                            {/*        disabled={!this.state.userMenuJSON.week_data_next.picking_allowed}*/}
                            {/*        onClick={() => this.setState({showSnackPickingModal: true})}><BsPencilSquare/> Change my Picks</Button>*/}
                            {/*{editLine}*/}
                            Editing is not currently allowed.
                            <div className={'mt-3'}>
                                <div className={'text-primary'}><h5>{numSnacksPicked} Snacks Picked</h5></div>
                                {listGroupsPicked}
                            </div>
                        </div>
                    </Collapse>
                </div>
            );
        }

        let buffer = [];

        for (const item of this.state.userMenuJSON.snacks){
            buffer.push(
                <ListGroup.Item key={item.name}>
                    <Row>
                        <Col sm={2}>${parseInt(item.price).toFixed(2)}</Col>
                        <Col><strong>{item.name}:</strong> {item.description}</Col>
                    </Row>
                </ListGroup.Item>
            );
        }

        return (
            <div>
                Week {week.week + 1}: {start_date} - {end_date}
                <Button onClick={() => this.setState({showSnackPickingModal: true})}
                        className={'float-right'}>Pick my Snacks</Button>
                <div className={'mt-3 mb-3'}>
                    <BsFillQuestionCircleFill/> How does meal plan cash work? <br/><br/>
                    Meal plan cash will need to be chosen within the first week of each quarter, if not they will be randomly assigned.
                    You will receive them by the end of the second week of the quarter.
                    <br/>
                </div>
                <ListGroup>
                    {buffer}
                </ListGroup>
            </div>

        );
    }


    render(){
        if (this.props.currentTab !== 1){
            return null;
        }

        if (this.state.userMenuJSON == null){
            return(
                <div className="d-flex justify-content-center align-items-center mt-5 mb-5">
                    <Spinner
                        as="span"
                        animation="border"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                    />
                    <span className="sr-only">Loading...</span>
                </div>
            );
        }
        else {


            return (
                <div>
                    <Jumbotron className={'mt-3'}>
                        <h1>Hi, {this.state.userMenuJSON.current_user_name}</h1>
                        <p>
                            Welcome to the new food menu! Select a button below to view this or next week's
                            menu and view or choose your meals.
                        </p>
                        <ButtonGroup className={'btn-block'}>
                            <Button active={this.state.selectedWeek === 'this'}
                                    onClick={() => {this.setState({selectedWeek: 'this'})}}
                                    className={'btn-span'}>
                                This Week
                            </Button>
                            <Button active={this.state.selectedWeek === 'next'}
                                    onClick={() => {this.setState({selectedWeek: 'next'})}}
                                    className={'btn-span'}>
                                Next Week
                            </Button>
                        </ButtonGroup>
                    </Jumbotron>
                    {/*{this.pickingModal}*/}
                    {this.snackPickingModal}
                    {this.thisOrNextWeek}
                </div>
            );
        }
    }

    get thisOrNextWeek(){
        if (this.state.selectedWeek === 'this'){
            return (
                <div>
                    <h4><BsFillCalendarFill/><strong>{'  '}This Week</strong></h4>
                    <Card className={'mt-3'}>
                        <Card.Header>Meals</Card.Header>
                        <Card.Body>
                            <h5><strong>This Week's Meal Picks</strong></h5>
                            {/*{this.currentWeeksPicks}*/}
                        </Card.Body>
                    </Card>
                    <Card className={'mt-3 mb-3'}>
                        <Card.Header>Meal Plan Cash</Card.Header>
                        <Card.Body>
                            <Card className={'mt-1 mb-3'}>
                                <Card.Body>
                                    <Row>
                                        <Col className={'text-center'}>
                                            {/*<h4 className={'text-success'}><strong>${this.state.userMenuJSON.meal_plan_cash.toFixed(2)}</strong></h4>*/}
                                        </Col>
                                        <Col>
                                            Meal Plan Cash Available
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Card>
                            <h5><strong>This Week's Snack Picks</strong></h5>
                            N/A
                        </Card.Body>
                    </Card>
                </div>

            );
        }
        else {
            let alert = null;
            if (!this.state.userMenuJSON.week_data_next.picked){
                alert = (
                    <Alert className={'mt-3'} variant={'dark'}>
                        <Badge pill variant="danger">1</Badge> {'  '} You can pick your meals for next week!
                    </Alert>
                );
            }

            return (
                <div className={'mt-0'}>
                    <h4><BsFillCalendarFill/><strong>{'  '}Next Week</strong></h4>
                    {alert}
                    <Card className={"mt-3"}>
                        <Card.Header>Meals</Card.Header>
                        <Card.Body>
                            {this.nextWeeksMenu}
                        </Card.Body>
                    </Card>
                    <Card className={'mt-3 mb-3'}>
                        <Card.Header>Meal Plan Cash</Card.Header>
                        <Card.Body>
                            <Card className={'mt-1 mb-3'}>
                                <Card.Body>
                                    <Row>
                                        <Col className={'text-center'}>
                                            {/*<h4 className={'text-success'}><strong>${this.state.userMenuJSON.meal_plan_cash.toFixed(2)}</strong></h4>*/}
                                        </Col>
                                        <Col>
                                            Meal Plan Cash Available
                                        </Col>

                                    </Row>
                                </Card.Body>
                            </Card>
                            <h5><strong>Next Week's Snacks</strong></h5>
                            {this.nextWeeksSnacks}
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    }

}