import React, {Component} from "react";
import {Alert, Button, Card, Col, Row} from "react-bootstrap";
import {BsCheck} from "react-icons/all";

export default class RegistrationStep1 extends Component {
    constructor(props) {
        super(props);

        this.setSelectedYear = this.setSelectedYear.bind(this);
    }

    setSelectedYear(year){
        this.props.handlePropsChange('year', year);
    }

    get unvalidatedAlert(){
        if (this.props.form1Validated != null){
            if (this.props.form1Validated === false){
                return (
                    <Alert variant='danger'>
                        Please select a year.
                    </Alert>
                );
            }
        }
        return null;
    }

    render() {
        if (this.props.currentStep !== 1)
        {
            return null;
        }

        let currentYearSelected = this.props.year === this.props.currentYear;
        let nextYearSelected = this.props.year === this.props.nextYear;

        let selectedCheck = (<BsCheck/>);

        return (
            <div>
                {this.unvalidatedAlert}
                <Card className="bk_text">
                    <Card.Header>
                        <strong>Account Registration (1/5)</strong>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title className="bk_header">
                            Year Select
                        </Card.Title>
                        <Card.Text>
                            Please select the academic year you are registering for.
                        </Card.Text>
                        <Row md={1} xs={1}>
                            {/*<Col>*/}
                            {/*    <Card*/}
                            {/*        bg={currentYearSelected ? 'primary' : ''}*/}
                            {/*        text={currentYearSelected ? 'light' : 'dark'}*/}
                            {/*        className={'mt-2 mb-2' + (currentYearSelected ? ' shadow' : '')}*/}
                            {/*    >*/}
                            {/*        <Card.Body>*/}
                            {/*            <Row>*/}
                            {/*                <Col>*/}
                            {/*                    <Card.Title>{this.props.currentYear}</Card.Title>*/}
                            {/*                    <Card.Text>Current Year</Card.Text>*/}
                            {/*                </Col>*/}
                            {/*                <Col className='text-right h3'>*/}
                            {/*                    {currentYearSelected ? selectedCheck : ''}*/}
                            {/*                </Col>*/}
                            {/*            </Row>*/}
                            {/*            <Button className='mt-2 mb-2' block*/}
                            {/*                    active={currentYearSelected}*/}
                            {/*                    onClick={() => this.setSelectedYear(this.props.currentYear)}>*/}
                            {/*                Register for current year*/}
                            {/*            </Button>*/}
                            {/*        </Card.Body>*/}
                            {/*    </Card>*/}
                            {/*</Col>*/}
                            <Col>
                                <Card
                                    bg={nextYearSelected ? 'primary' : ''}
                                    text={nextYearSelected ? 'light' : 'dark'}
                                    className={'mt-2 mb-2' + (nextYearSelected ? ' shadow' : '')}
                                >
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Card.Title>{this.props.nextYear}</Card.Title>
                                                <Card.Text>Upcoming Year</Card.Text>
                                            </Col>
                                            <Col className='text-right h3'>
                                                {nextYearSelected ? selectedCheck : ''}
                                            </Col>
                                        </Row>
                                        <Button className='mt-2 mb-2' block
                                                active={nextYearSelected}
                                                onClick={() => this.setSelectedYear(this.props.nextYear)}>
                                            Register for upcoming year
                                        </Button>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        );
    }

}